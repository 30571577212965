import { computed, type Ref } from 'vue'
import { useStore } from '@/ContextTab/useStore'
import { REFERENCES } from '@/lib/references'
import type { NewsColorsStore } from '@/ContextApp/stores/news/colors'


export function useNewsPublishedEntry({
  item,
}: {
  item: Ref<any>
  isSelected: Ref<boolean>
}) {
  const colorsStore = useStore<NewsColorsStore>('colors', {
    shared: true,
  })

  const itemId = computed(() => item.value?.id)
  const version = computed(() => item.value.version)

  const highlightedCodes = computed(() => {
    return colorsStore.data.colorsMap?.codes[itemId.value] || []
  })

  const isRevoked = computed(() => item.value.is_revoked)
  const isUrgent = computed(() => version.value?.type_id < 3)

  const isReleased = computed(
    () =>
      REFERENCES.News.Status[item.value.version.status_id]?.name
      === 'PUBLISHED',
  )

  return {
    highlightedCodes,
    isRevoked,
    isUrgent,
    isReleased,
  }
}
