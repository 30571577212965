export type KeyboardHandlerResult = 'processed' | 'continue'

export type KeyboardEventType = 'keyup' | 'keydown' | 'keypress'

export interface IKeyboardSubscriptionParams {
  componentId: string
  callback: (event: KeyboardEvent) => KeyboardHandlerResult
  eventType: KeyboardEventType
  keys: string[]
}

interface IKeyboardSubscription {
  componentId: string
  callback: (event: KeyboardEvent) => KeyboardHandlerResult
  eventType: KeyboardEventType
  key: string
  id: string
}

// Сервис для систематизации работы с клавиатурой в случае вложенных элементов
export class KeyboardHandler {
  private eventListeners: Record<string, (event: KeyboardEvent) => void> = {}

  private subscriptions: Record<string, IKeyboardSubscription[]> = {}

  private idForSubscription(eventType: string, key: string) {
    return eventType + '/' + key
  }

  private addListener(type: KeyboardEventType) {
    if (!this.eventListeners[type]) {
      this.eventListeners[type] = (event) => {
        this.onKeyboardEvent(type, event)
      }
      window.addEventListener(type, this.eventListeners[type])
    }
  }

  private onKeyboardEvent(type: KeyboardEventType, event: KeyboardEvent) {
    // для подходящего стека (должен быть один: событие + ключ)
    // просматриваем с конца, вызываем коллбэк, если ок останавливаемся
    const stack = this.subscriptions[this.idForSubscription(type, event.key)]
    if (!stack) {
      return
    }

    for (let i = stack.length - 1; i >= 0; i--) {
      const res = stack[i].callback(event)
      if (res === 'processed') {
        break
      }
    }
  }

  // ---------------------

  public subscribe(params: IKeyboardSubscriptionParams) {
    if (!this.eventListeners[params.eventType]) {
      this.addListener(params.eventType)
    }

    // разбиваем на ключи
    const subs: IKeyboardSubscription[] = params.keys.map((key) => ({
      componentId: params.componentId,
      callback: params.callback,
      eventType: params.eventType,
      key,
      id: this.idForSubscription(params.eventType, key),
    }))

    // записываем в нужные стеки
    subs.forEach((subscription) => {
      if (this.subscriptions[subscription.id]) {
        this.subscriptions[subscription.id].push(subscription)
      } else {
        this.subscriptions[subscription.id] = [subscription]
      }
    })
  }

  public unsubscribe(componentId: string) {
    // просмотреть все стеки, удалить все вхождения
    for (const key in this.subscriptions) {
      this.subscriptions[key] = this.subscriptions[key]
        .filter((item) => item.componentId !== componentId)
    }

    // удалить пустые стеки
    for (const key in this.subscriptions) {
      if (!this.subscriptions[key].length) {
        delete this.subscriptions[key]
      }
    }
  }

  // вызывать при уничтожении сервиса
  public clear() {
    for (const event in this.eventListeners) {
      window.removeEventListener(
        event,
        this.eventListeners[event] as EventListenerOrEventListenerObject,
      )
    }
  }
}
