<template>
  <div class="wrapper">
    <div class="tree-wrapper">
      <NewsCodesTree
        :items="items"
        :filterState="filterState"
        mode="check"
        @change="changeItemState"
      />
    </div>
    <div
      v-if="selectedCodes?.length"
      class="codes-shelf"
    >
      <span>Добавленные коды:</span>
      <div class="codes-list">
        <div
          v-for="code of selectedCodes"
          :key="code.item.id"
          :class="code.state"
        >
          <span>{{ code.item.name }}</span>
          <PsIcon
            type="font"
            name="close"
            size="14"
            class="remove-code"
            @click="uncheckItem(code.item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import NewsCodesTree from './codesTree/NewsCodesTree.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { useRepublishCodes } from '../composables/useRepublishCodes'
import { EQuadboxState } from '@/ContextTab/components/UI/types'
import type { CodeFilterState } from '@/ContextTab/modules/news/codes/types'


const props = defineProps<{
  omit?: (number | string)[]
  profileName?: string
  lang?: string
}>()

const emit = defineEmits<{
  (e: 'update', value: CodeFilterState): void
}>()

// TODO: использование этого компонента вызывает большое потребление памяти
const { items } = useRepublishCodes({
  lang: props.lang,
  omit: props.omit,
})

const filterState: CodeFilterState = reactive({})

const includeItem = (item: any) => {
  const record: CodeFilterState[keyof CodeFilterState]
    = filterState[item.id] ?? { id: item.id, state: EQuadboxState.included }
  record.state = EQuadboxState.included
  filterState[item.id] = record
  emit('update', filterState)
}

const uncheckItem = (item: any) => {
  const record: CodeFilterState[keyof CodeFilterState] = filterState[item.id] ?? {
    id: item.id,
    state: EQuadboxState.unchecked,
  }
  record.state = EQuadboxState.unchecked
  filterState[item.id] = record
  emit('update', filterState)
}

const selectedCodes = computed(() =>
  Object.values<CodeFilterState[keyof CodeFilterState]>(filterState)
    .filter(
      ({ id, state }) =>
        items.value
        && items.value[id]
        && (state === EQuadboxState.included),
    )
    .map(({ id, state }) => ({
      item: items.value?.[id],
      state,
    })),
)

const changeItemState = (item: any, state: EQuadboxState) => {
  if (item.nonSelectable || item.disabled) {
    return
  }

  if (state === EQuadboxState.included) {
    includeItem(item)
  }

  if (state === EQuadboxState.unchecked) {
    uncheckItem(item)
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tree-wrapper {
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}
.codes-shelf {
  padding-top: 1rem;
  width: 100%;
  font-size: 0.75rem;

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  .codes-list {
    display: flex;
    gap: 6px;
    width: 100%;
    flex-flow: wrap;

    & > div {
      align-items: center;
      display: flex;
      border-radius: 2px;
      padding: 0 3px 0 6px;
    }
    .included {
      border: 1px solid #9dbccd;
      background: #d9ebfb;
    }
    .excluded {
      border: 1px solid #cd9d9d;
      background: #fbd9d9;
    }

    .remove-code {
      cursor: pointer;
      margin-top: 1px;
    }
  }
}
</style>
