<template>
  <PsModal
    v-model:show="curShow"
    header="История работы над новостью"
    size="lg"
    :bodyPadding="false"
  >
    <PsTable
      :data="tableData"
      :columns="columns"
      :loading="store.data.isFetching"
    />
  </PsModal>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { computed } from 'vue'
import { dateTimeFormat } from '@/utils/dates'
import PsModal from '@/ContextTab/components/UI/PsModal.vue'
import PsTable from '@/ContextTab/components/UI/Table/PsTable.vue'
import { useStore } from '@/ContextTab/useStore'
import { formatDescription } from '@/utils/formatDescription'
import { formatUsername } from '@/utils/formatUsername'
import { REFERENCES } from '@/lib/references'
import type { TableColumn } from '@/ContextTab/components/UI/Table/types'
import type { PublishedHistoryStore } from '@/ContextApp/stores/news/published/history'


interface Props {
  show: boolean
  id: number
}

interface PublishedHistoryData {
  dateTime: string
  userName: string
  action: string
  description: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (e: 'update:show', value: (typeof props)['show']): void
}>()

const curShow = useVModel(props, 'show', emit)

const store = useStore<PublishedHistoryStore>('publishedNewsHistory', {
  onStart: (store) => store.loadHistoryNews(props.id),
})

const history = computed(() => store.data.newsHistory)

const columns: TableColumn<PublishedHistoryData>[] = [
  {
    title: 'Дата и время',
    key: 'dateTime',
    titleAlign: 'center',
    align: 'center',
    render(row) {
      return dateTimeFormat(new Date(row.dateTime), { month: 'short' }).replace(/, /, '\r\n')
    },
    className: '_white-space:preline',
  },
  {
    title: 'Пользователь',
    key: 'userName',
    align: 'center',
    titleAlign: 'center',
    render(row) {
      return formatUsername(row.userName)
    },
  },
  {
    title: 'Действие',
    key: 'action',
    align: 'center',
    titleAlign: 'center',
    render(row) {
      return REFERENCES.NewsEvent.Type[row.action]?.description || ''
    },
  },
  {
    title: 'Описание',
    key: 'description',
    align: 'left',
    titleAlign: 'center',
    render(row) {
      return formatDescription(row.description || '')
    },
    className: '_white-space:preline',
  },
]

const tableData = computed<PublishedHistoryData[]>(() => {
  if (!history.value?.length) {
    return []
  }

  return history.value?.map((row: any) => ({
    dateTime: row.created_at,
    userName: row.user?.name,
    action: row.type_id,
    description: row.description,
  }))
})
</script>
