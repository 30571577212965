<template>
  <CodesButton
    :count="codesCount"
    @click="onButtonClick('codes', $event as PointerEvent)"
    @clear="emit('clear', 'codes')"
  />
  <FeedsButton
    :count="feedsCount"
    @click="onButtonClick('feeds', $event as PointerEvent)"
    @clear="emit('clear', 'feeds')"
  />
  <UsersButton
    :count="usersCount"
    @click="onButtonClick('users', $event as PointerEvent)"
    @clear="emit('clear', 'users')"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CodesButton from './buttons/Codes.vue'
import FeedsButton from './buttons/Feeds.vue'
import UsersButton from './buttons/Users.vue'
import { useFilterCodes } from '@/ContextTab/modules/news/codes/composables/useFilterCodes'
import type { NewsSetFilters } from '@/lib/types'
import type { PublishedFilterConfiguratorType } from '@/ContextTab/modules/news/published/components/types'


const props = defineProps<{
  filters: NewsSetFilters
}>()

const emit = defineEmits<{
  (e: 'toggle', value: PublishedFilterConfiguratorType): void
  (e: 'clear', value: PublishedFilterConfiguratorType): void
}>()

const { isCodeIdIncluded: isCodeIdPub } = useFilterCodes('feeds')

const codesCount = computed(() =>
  [
    Object.values(props.filters?.code_ids ?? []),
    Object.values(props.filters?.code_excluded_ids ?? []),
  ]
    .map((ids) => ids.filter((id) => !isCodeIdPub(id)))
    .reduce((acc: number, val: any) => (acc += val.length), 0),
)

const feedsCount = computed(() =>
  [
    Object.values(props.filters?.feed_code_ids ?? []),
    Object.values(props.filters?.feed_code_excluded_ids ?? []),
  ]
    .filter((list) => Boolean(list))
    .reduce((acc: number, val: any) => (acc += val.length), 0),
)

const usersCount = computed(() =>
  [
    Object.values(props.filters?.user_ids ?? []),
    Object.values(props.filters?.user_excluded_ids ?? []),
    Object.values(props.filters?.user_group_ids ?? []),
    Object.values(props.filters?.user_group_excluded_ids ?? []),
  ]
    .filter((list) => Boolean(list))
    .reduce((acc: number, val: any) => (acc += val.length), 0),
)

const showButtonsPopover = ref(false)

const onButtonClick = (
  type: PublishedFilterConfiguratorType,
  event?: PointerEvent,
) => {
  if (showButtonsPopover.value) {
    showButtonsPopover.value = false
  }
  const target = event?.target
  if ((target as HTMLElement)?.closest('i')?.role === 'clear') {
    return emit('clear', type)
  }
  return emit('toggle', type)
}
</script>
