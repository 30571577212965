<template>
  <div
    class="ps-vertical-grid"
    :class="{ sm: isScreenSmall }"
  >
    <div
      class="list"
      :style="{ flexBasis: colWidths[0] + 'px' }"
    >
      <slot name="left" />
    </div>
    <Resizer
      v-if="isFluidLayout"
      @resize="onResize(1, $event)"
    />
    <div
      class="main"
      :style="{ flexBasis: colWidths[1] + 'px' }"
    >
      <section
        id="popups-panel-vertical"
        class="popups"
      />
      <slot name="main" />
    </div>
    <Resizer
      v-if="isFluidLayout"
      @resize="onResize(2, $event)"
    />
    <div
      class="preview"
      :style="{ flexBasis: colWidths[2] + 'px' }"
    >
      <slot name="preview" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import { reactive, watchEffect } from 'vue'
import Resizer from '../Resizer.vue'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'


defineProps<{
  isFluidLayout: boolean
}>()

const { isScreenSmall } = useScreenBreakpoints()

const minSizes = [100, 310, 100]

const storedColWidths = useStorage('ps-layout-published-vertical', { grid: [200, 450, 100] })
const colWidths = reactive(storedColWidths.value.grid)

watchEffect(() => {
  storedColWidths.value = { grid: colWidths }
})

const onResize = (index: number, diffs: { x: number, y: number }) => {
  const newSize = colWidths[index - 1] + diffs.x
  if (newSize > minSizes[index - 1]) {
    colWidths[index - 1] += diffs.x
  }
}
</script>

<style scoped>
.ps-vertical-grid {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: row;

  .list,
  .main,
  .preview {
    position: relative;
    overflow: hidden;
    min-width: 100px;
    flex-grow: 0;
  }

  .preview {
    flex-grow: 1;
  }

  &.sm {
    display: grid;
    grid-template-columns: 0 auto 0;
    grid-template-rows: 100%;
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
}
</style>
