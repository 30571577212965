export type Color =
  | 'primary'
  | 'secondary'
  | 'light'
  | 'dark'
  | 'success'
  | 'warning'
  | 'danger'

export type Tab<T = any> = { [P in keyof T]: T[P] } & {
  id: string | number
  label: string
}

export enum EQuadboxState {
  unchecked = 'unchecked',
  included = 'included',
  excluded = 'excluded',
  partial = 'partial',
  partialExcluded = 'partial-excluded',
}
