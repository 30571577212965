<template>
  <PsFilterButton
    tooltip="Цветовая индикация материалов"
    @click="emit('click')"
  >
    <template #prefix>
      <PbIcon
        name="set-color"
        size="14"
      />
    </template>
    <template #xs />
    <template #lg>
      Цвета
    </template>
  </PsFilterButton>
</template>

<script setup lang="ts">
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'


const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>
