<template>
  <PsFilterButton
    tooltip="Ленты и направления публикации"
    name="Ленты"
    :showClear="count > 0"
    :count="count"
    :plural="['лента', 'ленты', 'лент']"
    size="sm"
    @click="emit('click', $event)"
    @clear="emit('clear')"
  >
    <template #prefix>
      <PbIcon
        name="newswires"
        css="stroke-width: unset;"
        size="14"
      />
    </template>
    <template #xs />
    <template #md>
      Ленты
    </template>
  </PsFilterButton>
</template>

<script setup lang="ts">
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'


defineProps<{
  count: number
}>()

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
  (e: 'clear'): void
}>()
</script>
