<template>
  <div class="comment">
    {{ commentProcessed }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { trimHTML } from '@/utils/trimHTML'


const props = defineProps<{
  comment: string
}>()

const commentProcessed = computed(() => {
  let comment = ''
  if (props.comment) {
    comment = trimHTML(props.comment)
    if (comment.length > 140) {
      comment = comment.slice(0, 140) + '…'
    }
  }
  return comment
})
</script>

<style scoped>
.comment {
  padding: 6px 8px;
  line-height: 1.25;
  font-size: 0.75rem;
  word-break: break-word;
  white-space: pre-wrap;
  border-radius: 1px 7px 7px 7px;
  border: 1px solid var(--color-gray-200);
  background-color: hsla(0, 0%, 100%, 0.5);
}
</style>
