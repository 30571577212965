<template>
  <WithInplaceMessage
    :loading="loading"
    :message="message"
    :class="{ loading }"
    class="published-news-list-item-container"
  >
    <div
      class="published-news-list-item news-published-list-entry"
      :class="{
        selected: isSelected,
        'in-use': actionPopoverOpen,
        'search-hightlight': searchMode,
      }"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <!-- <div> -->
      <header class="__header">
        <PsIcon
          class="__type_icon"
          :name="iconName"
          data-test-selector="news-item-type"
        />

        <div class="statuses">
          <NewsRepublishStatus
            :item="item"
            @open="open"
          />
          <NewsExtensionStatus
            :item="item"
            @open="open"
          />
        </div>

        <span
          v-if="item?.publisher"
          class="authors"
          data-test-selector="authors"
        >
          {{ item?.publisher?.code }}
        </span>

        <DateWidget
          class="__date"
          :date="item.status_modified_at"
          data-test-selector="date"
        />
      </header>

      <main class="__body">
        <p
          class="text"
          :class="{ revoked: isRevoked, highlighted: isUrgent }"
          data-test-selector="text"
          v-html="item.version?.headline || item.version?.body"
        />
      </main>

      <footer class="__footer">
        <ListItemCodes
          :key="item.status_modified_at"
          :codesFilter="getIsFeedCode"
          :codes="item?.version?.codes"
        />

        <CodeColorIndicators
          :newsId="item.id"
          :codeIds="highlightedCodes"
          :allCodeItems="allCodeItems"
          :allCodeItemsByUid="allCodeItemsByUid"
          :codeColors="getColorsByType"
          :codesFilter="getIsFeedAndPubCode"
        />
      </footer>

      <PublishedListItemActions
        v-if="hover || actionPopoverOpen"
        :item="item"
        :isReleased="isReleased"
        @republish="republish(item.id)"
        @extend="extend(item.id)"
        @open="open(item.id)"
        @onTogglePopover="toggleActionPopover"
      />
    </div>
  </WithInplaceMessage>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue'
import NewsExtensionStatus from './NewsExtensionStatus.vue'
import NewsRepublishStatus from './NewsRepublishStatus.vue'
import PublishedListItemActions from './PublishedListItemActions.vue'
import DateWidget from '@/ContextTab/components/Date/DateWidget.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import WithInplaceMessage from '@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue'
import ListItemCodes from '@/ContextTab/modules/news/codes/components/ListItemCodes.vue'
import CodeColorIndicators from '@/ContextTab/modules/news/published/components/colorIndication/CodeColorIndicators.vue'
import { useNewsActions } from '@/ContextTab/modules/news/composables/useNewsActions'
import { useProfile } from '@/ContextTab/modules/user/composables/useProfile'
import { useNewsPublishedEntry } from '../../composables/useNewsPublishedEntry'
import { REFERENCES } from '@/lib/references'
import type { Message } from '@/ContextTab/components/UI/WithInplaceMessage/types'


const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    searchMode?: boolean
    getIsFeedCode?: (code: any) => boolean
    getIsFeedAndPubCode: (code: any) => boolean
    allCodeItems?: object
    allCodeItemsByUid?: object
  }>(),
  {
    searchMode: false,
    allCodeItems: () => ({}),
    allCodeItemsByUid: () => ({}),
    getIsFeedCode: () => false,
  },
)

const emit = defineEmits<{
  (e: 'onOpenActionPopover'): void
}>()

const { getColorsByType } = useProfile({})

const { highlightedCodes, isRevoked, isUrgent, isReleased }
  = useNewsPublishedEntry(toRefs(props))

const message = ref<Message | null>(null)
const loading = ref(false)

const iconName = computed(() => {
  const newsType
    = REFERENCES.News.Type[props.item.version?.type_id ?? 3]?.name
  return 'newsType/newstype-' + (newsType ?? '').toLowerCase()
})

const actionPopoverOpen = ref(false)
const toggleActionPopover = (event) => {
  actionPopoverOpen.value = event
  if (event) {
    emit('onOpenActionPopover')
  }
}

const hover = ref(false)
const { extend, republish, open } = useNewsActions(loading, message)

watch(
  () => props.item.id,
  (next, prev) => {
    if (next !== prev) {
      hover.value = false
    }
  },
)
</script>

<style scoped>

.published-news-list-item-container {
  &.loading {
    opacity: 0.75;
    pointer-events: none;
  }
}

.published-news-list-item {
  border-bottom: 1px solid hsl(0deg 0% 90.2%);
  padding: 0.5em 0.75em;
  box-sizing: border-box;
  position: relative;

  color: #333;

  &:has(.text.highlighted) {
    --text-color: var(--color-danger-shade-150);
  }

  & .text.revoked {
    text-decoration: line-through;
  }

  & .__type_icon,
  & .__body .text {
    color: var(--text-color);
    fill: var(--text-color);
  }

  & .__header {
    color: #777;
    display: flex;
    align-items: center;
    gap: 0.2em;
    font-size: small;

    margin-bottom: 0.3em;

    & .statuses {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-right: 6px;
    }

    & .__date {
      margin-left: auto;
    }
  }

  & .__footer {
    padding-top: 0.45em;
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  & p {
    margin: 0;
  }

  &:hover,
  &.in-use {
    background-color: rgb(232, 238, 253);
    cursor: pointer;
  }
}
.published-news-list-item.selected {
  background-color: rgb(232, 238, 253);
}
</style>

<style>
@import "../../styles/search-result-hightlight.pcss";
</style>
