<template>
  <div
    v-if="item"
    class="root news-published-list-entry entryCompact"
    :class="{ selected: isSelected, 'search-hightlight': searchMode }"
  >
    <DateWidget
      :date="item.status_modified_at"
      data-test-selector="date"
      onlyTime
    />

    <span
      v-if="item?.publisher"
      class="authors"
      data-test-selector="authors"
    >
      {{ item?.publisher?.code }}
    </span>


    <CodeColorIndicators
      :newsId="item.id"
      :codeIds="highlightedCodes"
      :allCodeItems="allCodeItems"
      :allCodeItemsByUid="allCodeItemsByUid"
      :codeColors="getColorsByType"
      :codesFilter="getIsFeedAndPubCode"
      :feedMaxItems="2"
      :nonFeedMaxItems="2"
      :isCompact="true"
    />
    <div class="statuses">
      <NewsRepublishStatus
        :item="item"
        @open="emit('open', $event)"
      />
      <NewsExtensionStatus
        :item="item"
        @open="emit('open', $event)"
      />
    </div>

    <p
      class="text"
      :class="{ highlighted: isUrgent, revoked: isRevoked }"
      data-test-selector="text"
      v-html="item.version?.headline || item.version?.body"
    />
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue'
import NewsExtensionStatus from './NewsExtensionStatus.vue'
import NewsRepublishStatus from './NewsRepublishStatus.vue'
import DateWidget from '@/ContextTab/components/Date/DateWidget.vue'
import CodeColorIndicators from '@/ContextTab/modules/news/published/components/colorIndication/CodeColorIndicators.vue'
import { useProfile } from '@/ContextTab/modules/user/composables/useProfile'
import { useNewsPublishedEntry } from '../../composables/useNewsPublishedEntry'
import type { NewsItemPublished } from '@/ContextTab/modules/news/published/components/types'


const props = withDefaults(
  defineProps<{
    item: NewsItemPublished
    getIsFeedAndPubCode?: (code: any) => boolean
    isSelected?: boolean
    searchMode?: boolean
    allCodeItems?: object
    allCodeItemsByUid?: object
  }>(),
  {
    isSelected: false,
    allCodeItems: () => ({}),
    allCodeItemsByUid: () => ({}),
    getIsFeedAndPubCode: () => false,
    searchMode: false,
  },
)

const emit = defineEmits<{
  (e: 'open', value: number): void
}>()

const { getColorsByType } = useProfile({})

const {
  highlightedCodes,
  isRevoked,
  isUrgent,
} = useNewsPublishedEntry(toRefs(props))
</script>

<style>
/* TODO: do we need this? */
@import "../../styles/prevent-multiline-flash-in-list-entry.pcss";
@import "../../styles/prevent-entity-highlight-in-list-entry.pcss";
@import "../../styles/search-result-hightlight.pcss";
</style>

<style  scoped>
.news-published-list-entry {
  --font-size: 0.98rem;

  cursor: pointer;
  padding: 0 0.5em;
  height: 2em;
  box-sizing: border-box;

  font-size: var(--font-size);

  align-items: center;
  width: 100%;

  display: grid;
  grid-template-columns: 4.6em 6.8em 2.2em 3.2em 3.2em auto;

  color: rgb(61, 61, 61);

  &:hover {
    background-color: rgba(0, 105, 228, 0.1) !important;
  }

  &.selected {
    background-color: rgba(0, 105, 228, 0.1) !important;
  }

  & .date {
    font-size: var(--font-size);
    line-height: 1;
    justify-self: center;
  }
  &:has(.text.highlighted) {
    --text-color: #f22;
  }

  & .text {
    color: var(--text-color);
  }

  & .text.revoked {
    text-decoration: line-through;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding-left: 5px;
}

.statuses {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  padding-right: 0.5em;
}

.authors {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
