<template>
  <PanelGrid
    v-if="profile"
    class="ps-published-workspace"
    :gridType="publishedPanelMode"
    :isFluidLayout="isFluidLayout"
  >
    <template #left>
      <ItemCodesPanel
        v-if="selectedItem"
        :item="selectedItem"
      />
      <div
        v-else
        class="preview-fallback"
      >
        Новость не выбрана
      </div>
    </template>
    <template #main>
      <PublishedPanel
        ref="publishedPanel"
        :countUnreadNews="countUnreadNews"
        :dates="dates"
        :errors="errors"
        :filters="filters"
        :hasMoreItems="store.data.hasMoreItems"
        :isFetching="store.data.isFetching"
        :items="items"
        :mode="publishedPanelMode"
        :selectedItemId="selectedId"
        :preset="preset"
        :searchMode="searchMode"
        :showSearch="showSearch"
        :isReversed="store.data.isReversed"
        @clear="clearFilter"
        @clickUnread="showNew"
        @onOpenActionPopover="() => store.toggleLockHead(true)"
        @reachedStart="onReachedStart"
        @reachedEnd="onReachedEnd"
        @repeat="store.reload()"
        @resetFilters="resetFilters"
        @savePreset="savePreset"
        @select="selectItem"
        @toggleTree="treePanelOpened = !treePanelOpened"
        @updateFilters="updateFilters"
        @applyPreset="applyPreset"
        @clearPreset="clearPreset"
        @search="search"
        @clearSearch="clearSearch"
        @toggleReverse="toggleReverse"
        @update:showSearch="updateShowSearch"
      />
      <IAlert
        v-if="showWarning"
        color="warning"
      >
        <p>🔇 Ваш браузер блокирует звуковые уведомления.</p>
        <p>
          Пожалуйста, разрешите проигрывание звуковых уведомлений о выходе срочных материалов по
          <a
            :href="instructionLink"
            target="_blank"
          >инструкции</a>
        </p>
      </IAlert>
    </template>
    <template #preview>
      <PreviewPanel
        v-if="selectedItem"
        :item="selectedItem"
        :mode="publishedPanelMode"
        :searchMode="searchMode"
      />
      <div
        v-else
        class="preview-fallback"
      >
        Новость не выбрана
      </div>
    </template>
  </PanelGrid>
</template>

<script setup lang="ts">
import { refThrottled, useThrottleFn } from '@vueuse/core'
import { ref, computed, watch, onMounted, useTemplateRef } from 'vue'
import { IAlert } from '@inkline/inkline'
import ItemCodesPanel from './ItemCodesPanel.vue'
import PreviewPanel from './preview/PreviewPanel.vue'
import PublishedPanel from './PublishedPanel.vue'
import PanelGrid from '@/ContextTab/layout/grid/PanelGrid.vue'
import { endOfToday, startOfToday, toISOStringWithTimezone } from '@/utils/dates'
import { useProfile } from '@/ContextTab/modules/user/composables/useProfile'
import { useStoreAsync } from '@/ContextTab/useStore'
import { base64ToUtf, utfToBase64 } from '@/utils/base64'
import { filterPrivate } from '@/utils/filters'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'
import { DOCS_PAGE } from '@/config'
import type { NewsItemPublished, PublishedFilterConfiguratorType } from './types'
import type { NewsSetFilters, NotificationSound } from '@/lib/types'
import type { PublishedNewsStore } from '@/ContextApp/stores/news/published/set'
import type { PublishedFiltersStore } from '@/ContextApp/stores/news/published/filters'
import type { PublishedPresetsStore } from '@/ContextApp/stores/news/published/presets'
import type { PublishedLocalFiltersStore } from '@/ContextApp/stores/news/published/localFilters'


const presetStore = await useStoreAsync<PublishedPresetsStore>('publishedPresets')
const filterStore = await useStoreAsync<PublishedFiltersStore>('publishedFilters')


if (window.location.hash) {
  try {
    const base64 = window.location.hash.slice(1, window.location.hash.length)
    const decoded = base64ToUtf(base64)
    const data = JSON.parse(decoded)
    if (data) {
      await presetStore.fetchPresets()
      await applyPreset(data.id)
    }
  } catch (err) {
    console.warn('Не удалось раскодировать пресет из адресной строки', err)
  }
}

// Нужно инициалировать стор фильтров
await useStoreAsync<PublishedLocalFiltersStore>('publishedLocalFilters')
const store = await useStoreAsync<PublishedNewsStore>('publishedNews')
store.loadNext()

const { isScreenSmall } = useScreenBreakpoints()
const isFluidLayout = computed(() => !isScreenSmall.value)

const publishedPanel = useTemplateRef<InstanceType<typeof PublishedPanel>>('publishedPanel')

const items = computed(() => store.data.newsList)
const countUnreadNews = computed(() => store.data.lockedHeadSet?.length)

const { publishedPanelMode, profile, soundNotifications } = useProfile({})

const filters = computed(() => filterPrivate(filterStore.data.filters))

const selectedId = ref(1)
const selectedIdThrottled = refThrottled(selectedId, 200)

const rightSupplyShowed = ref(false)
const treePanelOpened = ref(true)

const selectedItem = computed(() =>
  items.value?.find((item: any) => item?.id === selectedIdThrottled.value),
)

const selectItem = (item: NewsItemPublished) => {
  if (item.id != null) {
    selectedId.value = item.id
  }
  rightSupplyShowed.value = true

  if (item.id === items.value?.[0]?.id && !store.data.isReversed) {
    if (countUnreadNews.value === 0) {
      store.toggleLockHead(false)
    }
  } else {
    store.toggleLockHead(true)
  }
}

const errors = computed(() => {
  const errors = []
  if (store.data.error) {
    errors.push(store.data.error)
  }
  return errors.length
    ? errors
    : null
})

const defaultDates = ref({
  start: toISOStringWithTimezone(startOfToday()),
  end: toISOStringWithTimezone(endOfToday()),
})

const dates = ref({
  start: defaultDates.value.start,
  end: defaultDates.value.end,
})

watch(items, (next, prev) => {
  if (next[0] && next[0].id !== prev?.[0]?.id) {
    if (store.data.isLockedHead === false) {
      selectItem(next[0])
    }
  }
})

const onReachedStart = (isVisible: boolean) => {
  store.toggleLockHead(!isVisible)
  if (isVisible) {
    store.slice()
  }
}

const onReachedEnd = useThrottleFn(() => {
  store.loadNext()
}, 500, false, true)

function showNew() {
  if (!store.data.isReversed) {
    if (publishedPanel.value?.getScrollTopSize()) {
      publishedPanel.value?.scrollToTop()
    } else {
      store.toggleLockHead(false)
    }
  } else {
    toggleReverse(false)
  }
}

const toggleReverse = (value: boolean) => {
  store.toggleLockHead(value)
  store.setReversed(value)
}

const preset = computed(() =>
  presetStore.data.presets?.find(
    (preset) => preset?.id === presetStore.data.currentPresetId,
  ),
)

const savePreset = async (
  filters: Partial<NewsSetFilters>,
  name: string | null = null,
) => {
  if (preset.value) {
    await presetStore.updatePreset(preset.value.id, {
      ...filters,
      name: name || undefined,
    })
  }
}

async function updateFilters(filters: Partial<NewsSetFilters>) {
  filterStore.updateFilters(filters)
}

async function resetFilters() {
  filterStore.resetFilters()
  window.location.hash = ''
}

async function clearFilter(name: PublishedFilterConfiguratorType) {
  filterStore.clearFilter(name)
}

async function applyPreset(id: number | string) {
  const applyResult = await presetStore.applyPreset(id)
  window.location.hash = utfToBase64(
    JSON.stringify({
      id: applyResult.payload.id,
      name: applyResult.payload.name,
    }),
  )
  await updateFilters(applyResult.payload)
}

async function clearPreset() {
  await presetStore.clearPreset()
  window.location.hash = ''
  resetFilters()
}

const searchMode = computed(() => store.data.searchMode ?? false)
const showSearch = computed(() => store.data.showSearch ?? false)
const updateShowSearch = (show: boolean) => store.updateShowSearch(show)

async function search() {
  if (!searchMode.value) {
    store.switchMode()
  } else {
    store.search()
  }
}

function clearSearch() {
  if (searchMode.value) {
    store.switchMode()
    publishedPanel.value?.scrollToTop()
  }
}


/* --------- Звуковые уведомления --------- */

const SOUNDS_PATH = '/sounds'
const SOUND_FILE_FORMAT = 'mp3'
const WARNING_TIMEOUT = 3000

const instructionLink = DOCS_PAGE + `/c/%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D1%8C-
%D0%B2%D1%8B%D0%BF%D1%83%D1%89%D0%B5%D0%BD%D0%BE/%D0%B7%D0%B2%D1%83%D0%BA%D0%BE%D0%B2%D1%8B%D0%B5-
%D1%83%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F/`

const showWarning = ref(false)

const audio = computed(() => createAudio(soundNotifications.value.sound))

onMounted(() => {
  if (soundNotifications.value.enabled) {
    setTimeout(() => {
      playNotification(true)
    }, WARNING_TIMEOUT)
  }
})

watch(() => store.data.gwSubUrgentNewsCount, () => {
  if (soundNotifications.value.enabled) {
    playNotification()
  }
})

function createAudio(sound: NotificationSound) {
  const audioUrl = new URL(`${SOUNDS_PATH}/${sound}.${SOUND_FILE_FORMAT}`, import.meta.url).href
  return new Audio(audioUrl)
}

async function playNotification(mute = false) {
  if (audio.value.muted !== mute) {
    audio.value.muted = mute
  }

  try {
    await audio.value.play()
  } catch (error) {
    if (error instanceof Error && error.name === 'NotAllowedError') {
      showWarning.value = true

      const abortController = new AbortController()

      document.addEventListener('click', () => {
        showWarning.value = false
        abortController.abort()
      }, { signal: abortController.signal })
    }
  }
}
</script>

<style scoped>
.preview-fallback {
  background-color: #f2f2f2;
  color: #777;
  opacity: 0.8;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}
</style>
