import {
  defineStore,
  createPinia,
  type Pinia,
  type Store,
  type StateTree,
} from 'pinia'
import type { GatewaySubType } from '@/ContextApp/services/gateway/constants'
import type { SubscriptionParams } from '@/ContextApp/services/gateway/types'
import type { GatewaySubscriptionHubMessage } from '@/ContextApp/middleware/gateway'


export type StoreGatewaySubscriptionIdentification =
  Omit<StoreGatewaySubscription, 'params'>
  & {
    params?: Omit<SubscriptionParams, 'subscription_type'>
  }

export type StoreGatewaySubscription = {
  type: GatewaySubType
  params?: SubscriptionParams
  // store here is arbitrary object, returned from define()
  onMessage: (store: any, message: GatewaySubscriptionHubMessage) => void
}

export type StoreRecord = {
  store: Store
  unsubscribe: () => void
  gatewaySubs?: () => StoreGatewaySubscriptionIdentification[]
}

export class AppStore {
  private _root: Pinia

  private _storeListeners: {
    [key: string]: {
      onCreated?: (store: StoreRecord) => any
      onDeleted?: (store: StoreRecord) => any
    }
  }

  // TODO: make private
  public stores: Record<string, StoreRecord>

  private contexts: Record<string, Record<string, StoreRecord>>

  constructor() {
    this._root = createPinia()
    this.stores = {}
    this.contexts = {}
    this._storeListeners = {}
  }

  onStoreCreated(callbackId: string, callback: (storeRecord: StoreRecord) => any) {
    if (!this._storeListeners[callbackId]) {
      this._storeListeners[callbackId] = {}
    }
    this._storeListeners[callbackId].onCreated = callback
  }

  onStoreDeleted(callbackId: string, callback: (storeRecord: StoreRecord) => any) {
    if (!this._storeListeners[callbackId]) {
      this._storeListeners[callbackId] = {}
    }
    this._storeListeners[callbackId].onDeleted = callback
  }

  createStore({
    name,
    contextId,
    define,
    gatewaySubs,
    onUpdate,
  }: {
    name: string
    contextId: string | null
    define: (name?: string, contextId?: string | null) => any
    gatewaySubs?: StoreRecord['gatewaySubs']
    onUpdate: (update: any, store: any) => void
  }): Store {
    if (this.stores[name]) {
      return this.stores[name].store
    } else {
      const makeStore = defineStore(name, () => {
        return {
          ...define(name, contextId),
        }
      })

      const store = makeStore(this._root)
      Object.values(store).forEach((prop) => {
        try {
          if (prop && typeof prop === 'object') {
            prop._$storeName = store.$id
          }
        } catch (err) {
          console.error('Error adding props to store:', err)
        }
      })

      const unsubscribe = store.$subscribe(() => {
        // @ts-expect-error обращаемся к внутреннему полю Pinia
        const updatedStore = this._root._s.get(store.$id)

        // PUB-4076: there's no way to do it effective, waiting for PUB-4168
        onUpdate(null, updatedStore)
      })

      // we don't overwrite context-dependent stores here, as it may seem:
      // because name is already like 'news--1231231231231' here - concatenated in middleware
      this.stores[name] = {
        store,
        gatewaySubs,
        unsubscribe,
      }

      if (contextId) {
        if (!this.contexts[contextId]) {
          this.contexts[contextId] = {}
        }
        this.contexts[contextId][name] = { store, gatewaySubs, unsubscribe }
      }

      Object.values(this._storeListeners).forEach(({ onCreated }) => {
        if (onCreated) {
          onCreated(this.stores[name])
        }
      })
    }

    return this.stores[name].store
  }

  deleteStore(storeId: string, contextId?: string) {
    const storeRecord = this.stores[storeId]

    if (!storeRecord) {
      return
    }

    Object.values(this._storeListeners).forEach(({ onDeleted }) => {
      if (onDeleted) {
        onDeleted(this.stores[storeId])
      }
    })

    if (storeRecord.unsubscribe) {
      storeRecord.unsubscribe()
    }

    // looks reckless, but it's a recommended way
    storeRecord.store.$dispose()
    delete this._root.state.value[storeId]
    delete this.stores[storeId]

    if (contextId && this.contexts?.[contextId]) {
      delete this.contexts[contextId][storeId]

      if (!Object.keys(this.contexts[contextId]).length) {
        delete this.contexts[contextId]
      }
    }
  }

  getStoreId(name: string, contextId?: string | null): string {
    return (contextId
      ? `${name}--${contextId}`
      : name
    )
  }

  getStore<name extends string, StoreT extends StateTree>(
    name: string,
    contextId?: string | null,
  ): Store<name, StoreT> {
    const storeId = this.getStoreId(name, contextId)
    const store = this.stores[storeId]?.store as Store<name, StoreT> | undefined

    if (!store) {
      throw new Error(`Store not found at getStore: ${this.getStoreId(name, contextId)}`)
    }

    return store
  }

  hasStore(name: string, contextId?: string | null): boolean {
    return !!this.stores[this.getStoreId(name, contextId)]
  }
}

export const appStore = new AppStore()
