import startOfToday from 'date-fns/startOfToday' // localFilters, DatePicker, PublishedWorkspace
import endOfToday from 'date-fns/endOfToday' // localFilters, DatePicker, PublishedWorkspace
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds' // UseDateDelimiters
import isThisYear from 'date-fns/isThisYear' // DateWidget, DateDelimiter, [EmbargoBadge, PsUsersListEntry]
import startOfYear from 'date-fns/endOfYesterday' // [DatePicker]
import setDateValues from 'date-fns/set' // [DatePicker]
import isAfter from 'date-fns/isAfter' // [DatePicker]
import isSameDay from 'date-fns/isSameDay' // [DatePicker]
import subDays from 'date-fns/subDays' // [DatePicker]
import startOfTomorrow from 'date-fns/startOfTomorrow'


type LocaleOption = { locale?: Intl.LocalesArgument }

export const defaultLocale = 'ru-RU'

// Date -> '2022-12-31T23:59:00+03:00'
export const toISOStringWithTimezone = (date: Date): string => {
  const tzOffset = -date.getTimezoneOffset()
  const tzDirection = tzOffset >= 0 ? '+' : '-'
  const pad = (num: number) => `${num}`.padStart(2, '0')

  return date.getFullYear()
    + '-' + pad(date.getMonth() + 1)
    + '-' + pad(date.getDate())
    + 'T' + pad(date.getHours())
    + ':' + pad(date.getMinutes())
    + ':' + pad(date.getSeconds())
    + tzDirection + pad(Math.floor(Math.abs(tzOffset) / 60))
    + ':' + pad(Math.abs(tzOffset) % 60)
}

export const dateTimeFormat = (
  date: Date,
  { locale, ...options }: Intl.DateTimeFormatOptions & LocaleOption = {},
): string => {
  const formatter = new Intl.DateTimeFormat(locale ?? defaultLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    ...options,
  })

  return formatter.format(date)
}

// Date -> ['2022-12-31', '23:59']
export const dateToDateTimeArray = (source: Date) => {
  const date = toISOStringWithTimezone(source)
  return [
    date.slice(0, 10),
    date.slice(11, 16),
  ]
}

// ['2022-12-31', '23:59'] -> Date
export const dateTimeArrayToDate = (parts: ReturnType<typeof dateToDateTimeArray>) => {
  return new Date(parts.join('T'))
}

export const todayRange = () => {
  const start = new Date()
  const end = new Date()

  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 999)

  return {
    start,
    end,
  }
}

export {
  startOfToday,
  endOfToday,
  startOfYear,
  differenceInMilliseconds,
  isThisYear,
  setDateValues,
  isAfter,
  isSameDay,
  subDays,
  startOfTomorrow,
}
