<template>
  <TreeSearch
    :searchParams="searchParams"
    :items="items"
    :showDepth="coloredOnly ? Infinity : 1"
    :emptyText="emptyText"
    idField="uid"
    :padding="32"
  >
    <template #default="{ item, opened }">
      <ColorTreeItem
        :item="item"
        :type="type"
        :opened="coloredOnly || opened || false"
        :colors="colors"
        @applyColor="$emit('applyColor', $event)"
      />
    </template>
  </TreeSearch>
</template>

<script setup lang="ts">
import ColorTreeItem from './ColorTreeItem.vue'
import TreeSearch from '@/ContextTab/components/TreeSearch/TreeSearch.vue'
import { useTreeSearch } from '@/ContextTab/components/TreeSearch/useTreeSearch'
import type { CodeFilterTypes } from '@/ContextTab/modules/news/codes/types'


withDefaults(
  defineProps<{
    items: any
    type: CodeFilterTypes
    colors?: object
    coloredOnly?: boolean
    emptyText?: string
  }>(),
  {
    colors: () => ({}),
    coloredOnly: false,
    emptyText: 'Ничего не найдено',
  },
)

defineEmits<{
  (e: 'applyColor', value: {
    color: string | null
    code: number
    includeChildren: boolean
  }): void
}>()

const { makeSearchParams } = useTreeSearch()

const searchParams = makeSearchParams()
</script>

<style scoped></style>
