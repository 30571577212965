import { nextTick, onMounted, onBeforeUnmount } from 'vue'
import { findInputElement } from './lib/findInputElement'
import type { Ref } from 'vue'


export function useAutofocus(el: Ref<HTMLElement | any>) {
  let cachedElement: HTMLElement | null = null
  onMounted(async () => {
    await nextTick()
    const element = findInputElement(el)
    if (element) {
      cachedElement = element
      element.focus()
    }
  })

  onBeforeUnmount(() => {
    cachedElement?.blur()
  })
}
