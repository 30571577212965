<template>
  <IInput
    ref="input"
    class="ps-search-field"
    :modelValue="query"
    :clearable="clearable"
    :color="color"
    :placeholder="placeholder"
    @update:modelValue="onUpdate"
    @keyup.enter="emit('submit', query)"
    @clear="clearQuery"
  >
    <template
      v-if="slots.prefix"
      #prefix
    >
      <slot name="prefix" />
    </template>
    <template
      v-if="slots.suffix"
      #suffix
    >
      <slot name="suffix" />
    </template>
  </IInput>
</template>

<script setup lang="ts">
import { IInput } from '@inkline/inkline'
import { ref, useSlots } from 'vue'
import { useAutofocus } from '@/ContextTab/components/UI/Form/composables/useAutofocus'


interface Props {
  clearable?: boolean
  autofocus?: boolean
  disabled?: boolean
  placeholder?: string
  throttle?: number
  initialQuery?: string
  color?: 'light' | 'primary-light'
}

const props = withDefaults(defineProps<Props>(), {
  clearable: true,
  autofocus: false,
  disabled: false,
  placeholder: '',
  throttle: 300,
  initialQuery: '',
  color: 'light',
})

const emit = defineEmits<{
  (e: 'input', value: string): void
  (e: 'submit', value: string): void
  (e: 'clear'): void
}>()

const slots = useSlots()

const input = ref<HTMLInputElement | null>(null)
const query = ref('')

query.value = props.initialQuery

if (props.autofocus) {
  useAutofocus(input)
}

function onUpdate(value: string) {
  query.value = value
  emit('input', value)
}

function clearQuery() {
  query.value = ''
  emit('input', query.value)
  emit('clear')
  inputFocus()
}

function inputFocus() {
  input.value?.input.focus()
}

defineExpose({
  clearQuery,
  inputFocus,
})
</script>

<style scoped>
.ps-search-field {
  --font-size: 1.2rem;
  --input--light--icon--color: var(--color-gray-400);

  &.input-wrapper {
    --input--icon--size: 1.2rem;
  }

  &.-primary-light {
    --input--background: rgba(255, 255, 255, 0.3);
    --input--color: var(--color-white);
    --input--placeholder--color: var(--color-primary-200);
    --input--prefix-suffix--color: var(--color-primary-200);
    --input--hover--border-color: var(--color-primary);
    --input--border-color: rgba(255, 255, 255, 0.3);
    --input--focus--border-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
