<template>
  <div class="panel-list">
    <slot name="filter" />

    <template v-if="errors?.length">
      <Error :errors="errors">
        <template #request-error>
          <slot name="request-error" />
        </template>
      </Error>
    </template>

    <template v-else-if="!items?.length">
      <div
        v-if="isFetching"
        class="loader-wrapper is-empty"
      >
        <CircularLoader />
      </div>
      <div
        v-else
        class="empty-text"
      >
        <PsEmpty title="Ничего не найдено" />
        <slot name="empty-actions" />
      </div>
    </template>
    <template v-else>
      <div
        v-if="isReversed && isFetching"
        class="loader-wrapper"
      >
        <CircularLoader />
      </div>
      <VirtualList
        ref="list"
        :count="items?.length"
        :estimateSize="initialSize"
        :hasMoreItems="hasMoreItems"
        :isReversed="isReversed"
        @reachedStart="emit('reachedStart', $event)"
        @loadMore="emit('reachedEnd')"
      >
        <template #item="{ index }">
          <DateDelimiter
            v-if="hasDateDelimiters && needDateDelimiter(index)"
            :key="currentDate"
            :date="items[index].status_modified_at"
            :color="dateDelimiterColor"
          />
          <slot :index="index" />
        </template>
        <template #after>
          <div class="loader-wrapper">
            <CircularLoader v-if="hasMoreItems && !isReversed" />
            <slot name="after" />
          </div>
        </template>
      </VirtualList>
      <slot name="after-list" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { toRef, useTemplateRef } from 'vue'
import { useDateDelimiters } from './useDateDelimiters'
import DateDelimiter from '../Date/DateDelimiter.vue'
import CircularLoader from '@/ContextTab/components/Busy/Loader/Circular.vue'
import Error from '@/ContextTab/components/Error/Error.vue'
import PsEmpty from '@/ContextTab/components/UI/PsEmpty.vue'
import VirtualList from '@/ContextTab/components/VirtualList/VirtualList.vue'
import type { NewsItemPublished } from '@/ContextTab/modules/news/published/components/types'


const props = withDefaults(
  defineProps<{
    items: NewsItemPublished[]
    hasDateDelimiters?: boolean
    dateDelimiterColor?: string
    initialSize?: number
    selectedItemId: number | string
    errors?: any[] | null
    hasMoreItems?: boolean
    isFetching?: boolean
    isReversed?: boolean
  }>(),
  {
    hasDateDelimiters: true,
    dateDelimiterColor: '#eee',
    initialSize: 100,
    errors: null,
    hasMoreItems: true,
    isFetching: false,
  },
)

const emit = defineEmits<{
  (e: 'reachedStart', isVisible: boolean): void
  (e: 'reachedEnd'): void
}>()

const { currentDate, needDateDelimiter } = useDateDelimiters(
  toRef(props, 'items'),
)

const list = useTemplateRef<InstanceType<typeof VirtualList>>('list')

function scrollToTop() {
  return list.value?.scrollToOffset(0)
}

function scrollToIndex(index: number) {
  return list.value?.scrollToIndex(index, { align: 'center' })
}

function getScrollTopSize(): number | null | undefined {
  return list.value?.scrollOffset
}

defineExpose({
  scrollToTop,
  scrollToIndex,
  getScrollTopSize,
})
</script>

<style scoped>
.panel-list {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  &:empty {
    padding: 0;
  }
  &.is-empty {
    height: 100%;
  }
}

.empty-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
</style>
