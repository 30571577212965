<template>
  <div class="actions-block">
    <template v-if="!item.is_revoked">
      <template v-if="isReleased && getIsUserHasAllPermissions([ACCESS.NEWS.REPUBLISH])">
        <div title="Повтор">
          <PsButton
            circle
            :border="false"
            class="action-button"
            @click.stop="emit('republish')"
          >
            <PsIcon
              name="button-icon-republish"
              :size="24"
              color="#0069e4"
            />
          </PsButton>
        </div>
        <CodesPopover
          :loading="loading"
          :open="showPopover"
          @cancel="showPopover = false"
          @submit="onSubmit"
        >
          <div title="Добавить коды">
            <PsButton
              circle
              :border="false"
              class="action-button"
              @click.stop="showPopover = true"
            >
              <PsIcon
                name="button-icon-add"
                :size="24"
                color="#0069e4"
              />
            </PsButton>
          </div>
          <template #main>
            <NewsCodes
              :omit="newsItemCodeIds"
              profileName="PUBLISH_NEWS"
              :lang="newsLanguage?.name"
              @update="onCheckedUpdate"
            />
          </template>
        </CodesPopover>
      </template>

      <div
        v-if="getIsUserHasAllPermissions([ACCESS.NEWS.EXTENSION])"
        title="Расширить"
      >
        <PsButton
          circle
          :border="false"
          class="action-button"
          @click.stop="emit('extend')"
        >
          <PsIcon
            name="button-icon-extend"
            :size="24"
            color="#0069e4"
          />
        </PsButton>
      </div>
    </template>
    <div title="Открыть в новой вкладке">
      <PsButton
        circle
        :border="false"
        class="action-button"
        @click.stop="emit('open')"
      >
        <PsIcon
          name="button-icon-show-more"
          :size="24"
          color="#0069e4"
        />
      </PsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive, watch } from 'vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import CodesPopover from '@/ContextTab/modules/news/codes/components/CodesPopover.vue'
import NewsCodes from '@/ContextTab/modules/news/codes/components/NewsCodes.vue'
import { useAuthInfo } from '@/ContextTab/modules/auth/composables/useAuthInfo'
import { useStore } from '@/ContextTab/useStore'
import { REFERENCES } from '@/lib/references'
import { ACCESS } from '@/lib/accessCodes'
import type { Message } from '@/ContextTab/components/UI/WithInplaceMessage/types'
import type { NewsStore } from '@/ContextApp/stores/news'


type NewsItemPublished = any
const props = withDefaults(
  defineProps<{
    item: NewsItemPublished
    isReleased: boolean
  }>(),
  {},
)

const emit = defineEmits<{
  (e: 'republish'): void
  (e: 'open'): void
  (e: 'extend'): void
  (e: 'onTogglePopover', value: boolean): void
}>()

const { getIsUserHasAllPermissions } = useAuthInfo()

const newsStore = useStore<NewsStore>('news', {
  shared: true,
})

const showPopover = ref(false)
const loading = ref(false)
const message = ref<Message | null>(null)

const newsItemCodeIds = computed(
  () => props.item?.version?.codes?.map(({ code }) => code.id),
)

const newsLanguage = computed(
  () =>
    REFERENCES.News.Language[
      props.item?.version?.language_id
    ] ?? 'RU',
)

watch(showPopover, (next) => {
  emit('onTogglePopover', next)
})

type CheckedState = { [key: string]: any }
const checkedState = reactive<CheckedState>({})

const onCheckedUpdate = (state: CheckedState) => {
  Object.assign(checkedState, state)
}

const republishUneditedNewsItem = async () => {
  const ids = Object.keys(checkedState).filter(
    (codeId) => checkedState[codeId]?.state === 'included',
  )
  if (newsStore.created) {
    return newsStore.republishUnedited?.(props.item.id, ids)
  }
  return false
}

const onSubmit = async () => {
  loading.value = true
  const result = await republishUneditedNewsItem()
  if (result) {
    message.value = result.payload.message
  }
  showPopover.value = false
  loading.value = false
}
</script>

<style lang="postcss" scoped>
.actions-block {
  --padding: 60px;

  display: flex;
  position: absolute;
  bottom: 1.25em;
  right: 0.75em;
  z-index: 10;
  padding-left: var(--padding);
  gap: 0.125rem;

  .action-button {
    --button--hover--background: hsl(223, 84%, 85%);
  }
}

.published-news-list-item .actions-block {
  background: linear-gradient(
    to right,
    transparent,
    rgba(232, 238, 253, 0.7) calc(var(--padding) - 20px)
  );
}
</style>
