import { computed } from 'vue'
import { useBreakpoints } from '@vueuse/core'
import { SCREEN_BREAKPOINTS } from '@/config/screenBreakpoints'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'

// outside function -> re-calculated once on resize
const screenBreakpoints = useBreakpoints(SCREEN_BREAKPOINTS)

export function useScreenBreakpoints() {
  // screenBreakpoints methods returns refs
  const isScreenSmall = screenBreakpoints.smaller('sm')
  const isScreenExtraSmall = computed(() => screenBreakpoints.active().value === 'xs')
  const currentBreakpointMark = computed(
    () => (screenBreakpoints.active().value ?? 'md') as SizeBreakpointMark,
  )

  return {
    screenBreakpoints,
    isScreenSmall,
    isScreenExtraSmall,
    currentBreakpointMark,
  }
}
