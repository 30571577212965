<template>
  <div class="auth">
    <AuthForm
      v-if="kind === FormKind.auth"
      @reset="kind = FormKind.reset"
    />
    <ResetPasswordFrom
      v-if="kind === FormKind.reset"
      @return="back"
    />
    <ResetPasswordComplete
      v-if="kind === FormKind.resetCompete"
      :token="resetPasswordToken"
      @return="back"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AuthForm from './AuthForm.vue'
import ResetPasswordComplete from './ResetPasswordComplete.vue'
import ResetPasswordFrom from './ResetPasswordForm.vue'


const router = useRouter()
const route = useRoute()

const resetPasswordToken = computed(() =>
  route.name === 'resetPasswordComplete'
    ? (Array.isArray(route.params.token)
        ? route.params.token.join('')
        : route.params.token ?? ''
      )
    : '',
)

enum FormKind {
  auth,
  reset,
  resetCompete,
}

const kind = ref(FormKind.auth)

onBeforeMount(() => {
  if (route.name === 'resetPasswordComplete') {
    kind.value = FormKind.resetCompete
  }
})

const back = () => {
  kind.value = FormKind.auth
  router.push({ path: '/' })
}
</script>

<style scoped>
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background:
    url("@/assets/images/login-bg.jpg"),
    linear-gradient(
      -180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    )
    0 0;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

</style>
