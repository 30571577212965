<template>
  <div class="auth">
    <IForm
      v-model="schema"
      class="auth-form"
      :loading="busy"
      @submit="submit"
    >
      <WithInplaceMessage :loading="busy">
        <div class="wrapper">
          <template v-if="!isSuccessfullySent">
            <h1 class="title">
              Восстановление пароля
            </h1>
            <IFormGroup
              size="lg"
              class="ps-form-group"
            >
              <IInput
                v-model="schema.username.value"
                name="username"
                type="text"
                autocomplete="username"
                placeholder="Электронная почта"
                size="lg"
              />
              <IFormError
                for="username"
                :visible="['invalid']"
              />
            </IFormGroup>
            <IFormGroup size="lg">
              <PsButton
                block
                size="lg"
                type="submit"
                color="primary"
                :disabled="schema.prisine || (schema.touched && schema.invalid)"
              >
                Отправить запрос на восстановление
              </PsButton>
            </IFormGroup>
          </template>
          <div
            v-else
            class="auth-form__message"
          >
            Ссылка на восстановление пароля направлена вам на почту.
          </div>
        </div>
      </WithInplaceMessage>
    </IForm>
    <div class="optional-link">
      <a
        href="javascript:;"
        @click="emit('return')"
      >Вернуться</a>{{ " " }}к форме
      входа в систему
    </div>
  </div>
</template>

<script setup lang="ts">
import { IForm, IFormGroup, IInput, IFormError } from '@inkline/inkline'
import { ref, computed } from 'vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import WithInplaceMessage from '@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue'
import { useWiredForm } from '@/ContextTab/composables/useWiredForm'
import { useStore } from '@/ContextTab/useStore'
import type { AuthStore } from '@/ContextApp/stores/auth'


const emit = defineEmits<{
  (e: 'return'): void
}>()

const authStore = useStore<AuthStore>('auth', {
  shared: true,
})

const storeError = computed(() => authStore.data.error ?? null)
const isSuccessfullySent = ref(false)
const busy = ref(false)

const formConfig = {
  username: {
    validators: [
      { name: 'required', message: 'Введите электронную почту' },
      { name: 'email', message: 'Введите корректный адрес электронной почты' },
      {
        name: 'custom',
        key: 'api',
        fallbackMessage: 'Пользователь не найден',
      },
    ],
  },
}

const handleResetPassword = async () => {
  busy.value = true
  await authStore.requestPasswordReset?.({
    username: form.value.username,
  })
  if (!storeError.value) {
    isSuccessfullySent.value = true
  }
  busy.value = false
}

const { schema, form, submit } = useWiredForm<{
  username: string
}>(formConfig, storeError, handleResetPassword)
</script>

<style scoped>
@import "../styles/auth.pcss";
</style>
