<template>
  <span class="date-widget">{{ formattedDate }}</span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { isThisYear, dateTimeFormat, isSameDay } from '@/utils/dates'


const props = withDefaults(
  defineProps<{
    date: string | number | Date
    onlyTime?: boolean
    moscowTime?: boolean
    highlightColor?: string
  }>(),
  {
    onlyTime: false,
    moscowTime: false,
    highlightColor: 'inherit',
  },
)

const formattedDate = computed(() => {
  const date = new Date(props.date)
  const options: Intl.DateTimeFormatOptions = props.moscowTime
    ? {
        timeZone: 'Europe/Moscow',
      }
    : {}

  if (props.onlyTime || isSameDay(date, new Date())) {
    Object.assign(options, {
      year: undefined,
      month: undefined,
      day: undefined,
    })
  } else {
    options.year = !isThisYear(date)
      ? 'numeric'
      : undefined
  }

  return dateTimeFormat(date, options)
})
</script>

<style scoped>
.date-widget {
  color: v-bind(highlightColor);
}
</style>
