<template>
  <!-- left pull-out menu & its overlay -->
  <ISidebar
    :modelValue="isMenuShown"
    :collapse="true"
    :contentStyle="{ background: 'none' }"
    size="lg"
    color="dark"
    placement="left"
    class="menu-drawer"
    :class="{ sm: isScreenSmall }"
    @update:modelValue="onToggleSidebar"
  >
    <MainMenu
      @close="toggleMenu"
      @logout="logout"
    />
  </ISidebar>

  <div
    class="ps-main-grid"
    :class="{
      sm: isScreenSmall,
      'with-task-panel': isTaskPanelShown,
    }"
  >
    <!-- left vertical black bar with workspace buttons -->
    <aside class="ps-nav-container">
      <MainNav>
        <template #group-main>
          <TasksPanelMenu
            :active="currentTaskPanelTabId"
            @click="toggleTaskPanel"
          />
          <WorkspaceSwitcher />
        </template>

        <template #group-secondary>
          <MenuButton
            :active="isMenuShown"
            @click="toggleMenu"
          />
        </template>
      </MainNav>
    </aside>

    <!-- task panel -->
    <ISidebar
      :modelValue="isTaskPanelShown"
      :collapse="true"
      collapsePosition="relative"
      :contentStyle="{ background: 'none' }"
      size="lg"
      placement="left"
      class="task-panel"
      @update:modelValue="$event === false && emit('hideTaskPanel')"
    >
      <Suspense>
        <TasksPanel
          v-model="currentTaskPanelTabId"
          @close="closeTaskPanel"
        />
      </Suspense>
    </ISidebar>

    <main class="ps-main-content">
      <slot v-bind="{ isTaskPanelShown }" />
    </main>
  </div>
</template>

<script setup lang="ts">
import { ISidebar } from '@inkline/inkline'
import { ref } from 'vue'
import { useStorage } from '@vueuse/core'
import { tabs } from '@/ContextTab/modules/news/tasks-panel/components/nav/tabs'
import WorkspaceSwitcher from '@/ContextTab/components/WorkspaceSwitcher.vue'
import MenuButton from '@/ContextTab/modules/menu/components/MenuButton.vue'
import TasksPanelMenu from '@/ContextTab/modules/news/tasks-panel/components/nav/TasksPanelMenu.vue'
import MainNav from '@/ContextTab/layout/MainNav.vue'
import MainMenu from '@/ContextTab/modules/menu/components/MainMenu.vue'
import TasksPanel from '@/ContextTab/modules/news/tasks-panel/components/TasksPanel.vue'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'
import type { SetType } from '@/lib/types'


const emit = defineEmits<{
  (event: 'hideTaskPanel'): void
  (event: 'logout'): void
}>()

const isMenuShown = ref(false)
const isTaskPanelShown = useStorage('ps-task-panel-shown', false, sessionStorage)
const currentTaskPanelTabId = useStorage('ps-task-panel-tab', tabs[0].id, sessionStorage)

const { isScreenSmall } = useScreenBreakpoints()

function toggleMenu() {
  isMenuShown.value = !isMenuShown.value
}

function toggleTaskPanel(tab: SetType) {
  if (tab !== currentTaskPanelTabId.value) {
    currentTaskPanelTabId.value = tab
    isTaskPanelShown.value = true
  } else {
    isTaskPanelShown.value = !isTaskPanelShown.value
  }
}

function closeTaskPanel() {
  isTaskPanelShown.value = false
}

function logout() {
  isMenuShown.value = false
  emit('logout')
}

function onToggleSidebar(visible: boolean) {
  if (!visible) {
    isMenuShown.value = false
  }
}

</script>

<style scoped>
.menu-drawer {
  --sidebar--border-radius: 0;
  --sidebar--overlay--background: rgba(0, 0, 0, 0.33);
  --sidebar--z-index: 100;
  left: var(--workspace-switcher-size);
  top: 0;

  &.sm {
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
  }
}

.ps-main-grid {
  display: grid;
  position: relative;
  height: 100%;
  overflow: hidden;
  /* first "auto" is required while task-panel transition is ongoing,
  otherwise main area re-renders */
  grid-template-columns: var(--workspace-switcher-size) auto auto;
  grid-template-rows: 100%;

  &.with-task-panel {
    grid-template-columns: var(--workspace-switcher-size) var(--task-panel-width) auto;
  }

  .ps-nav-container {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: column;
  }

  .ps-main-content {
    overflow: hidden;
    position: relative;
  }

  .task-panel {
    --sidebar--border-radius: 0;
    --sidebar--width: var(--task-panel-width);
    --sidebar--padding: 0;
    --sidebar--border-top-width: 0;
    --sidebar--z-index: 98; /* less than .menu-drawer */
    background-color: #fffaf2;
  }

  &.sm {
    grid-template-columns: auto;
    grid-template-rows:
      calc(100% - var(--workspace-switcher-size))
      var(--workspace-switcher-size);

    .ps-nav-container {
      grid-row: 2 / -1;
      grid-column: 1 / -1;
      flex-direction: row;
    }

    .ps-main-content {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
    }

    .task-panel {
      position: absolute;
      left: 0;
      top: 0;
      min-height: auto; /* inkline set it = 100% */
      height: calc(100vh - var(--workspace-switcher-size));
    }
  }
}
</style>
