<template>
  <PanelList
    :items="items"
    :selectedItemId="selectedId"
    :hasMoreItems="hasMoreItems"
    :errors="errors"
    :isFetching="isFetching"
    :initialSize="140"
    dateDelimeterColor="#f3e4ca"
    @select="selectItem"
    @reachedEnd="onReachedEnd"
    @mouseleave="() => selectItem()"
  >
    <template #default="{ index }">
      <NewsMyListItem
        :item="items[index]"
        :isSelected="items[index]?.id === selectedId"
        :isLegacyRoute="isLegacyRoute"
        :loading="loading"
        @open="emit('open', $event)"
        @pick="emit('pick', $event)"
        @mouseenter="() => selectItem(items[index].id)"
      />
    </template>
  </PanelList>
</template>

<script setup lang="ts">
import NewsMyListItem from './NewsMyListItem.vue'
import PanelList from '@/ContextTab/components/List/PanelList.vue'
import { useTaskPanelList } from '@/ContextTab/modules/news/tasks-panel/composables/useTaskPanelList'


defineProps<{
  isLegacyRoute: boolean
  loading: boolean
}>()

const emit = defineEmits<{
  (e: 'open', id: number): void
  (e: 'pick', id: number): void
}>()

const {
  items,
  selectedId,
  selectItem,
  onReachedEnd,
  hasMoreItems,
  isFetching,
  errors,
} = useTaskPanelList('my')
</script>

<style scoped></style>
