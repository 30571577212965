<template>
  <div
    ref="root"
    class="inplace-container"
    :class="rootClasses"
  >
    <CircularLoader
      v-if="currentState === States.Loading && !loaderElements.length"
      :style="{
        width: '36px',
        height: '36px',
        ...loaderElementsStyle,
      }"
    />
    <Teleport
      to="#message"
      :disabled="!props.useTeleport"
    >
      <transition name="slide-fade">
        <div
          v-if="currentState === States.Result && currentStateData.message?.text"
          class="message"
          :class="messageClasses"
        >
          <span
            v-if="messageRender === 'html'"
            v-html="currentStateData.message.text"
          />
          <span v-else>{{ currentStateData.message.text }}</span>
        </div>
      </transition>
    </Teleport>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { mount } from 'mount-vue-component'
import { ref, reactive, computed, watch, onMounted, useTemplateRef } from 'vue'
import CircularLoader from '@/ContextTab/components/Busy/Loader/Circular.vue'
import {
  States,
  type Message,
  type MessageRender,
  type HandlerArgs,
  type TriggerArgs,
} from './types'
import type { Ref } from 'vue'


const props = withDefaults(
  defineProps<{
    loading: boolean
    useTeleport?: boolean
    block?: boolean
    blockingElements?: Ref[]
    loaderElements?: Ref[]
    loaderElementsStyle?: Partial<CSSStyleDeclaration>
    message?: Message | null
    messageTimeout?: number
    messageRender?: MessageRender
    rootClass?: string
    messageClass?: string
  }>(),
  {
    block: true,
    useTeleport: false,
    blockingElements: () => [],
    loaderElements: () => [],
    loaderElementsStyle: () => ({}),
    message: null,
    messageTimeout: 1500,
    messageRender: 'string',
    rootClass: '',
    messageClass: '',
  },
)

const currentState = ref(States.Idle)
const currentStateData = reactive({
  message: props.message,
  loadersDestroyFns: [],
})

const rootClasses = computed(() => ({
  block:
    currentState.value === States.Loading
    && props.block
    && !props.blockingElements.length,
  [props.rootClass]: true,
}))

const messageClasses = computed(() => ({
  [currentStateData.message?.type || '']: true,
  [props.messageClass]: true,
}))

const root = useTemplateRef('root')

watch(
  () => props.loading,
  (next) => {
    if (next) {
      trigger({ state: States.Loading })
    }

    if (next === false && !props.message) {
      trigger({ state: States.Result })
    }
  },
)

watch(
  () => props.message,
  (next) => {
    if (next) {
      trigger({ state: States.Result, message: next })
    }
  },
)

const trigger = (args: TriggerArgs) => {
  const { state, ...rest } = { ...args }
  if (!state) {
    return
  }
  currentState.value = state
  handlers[currentState.value].forEach((handler) => handler(rest))
}

const block = () => {
  props.blockingElements.forEach((el: HTMLElement) => {
    if (elCanBeDisabled(el.tagName)) {
      el.setAttribute('disabled', '')
    }
    const block = document.createElement('div')
    block.classList.add('block')
    /* eslint-disable @stylistic/max-len */
    block.style.cssText = `position: absolute; width: 100%; height: 100%; top: 0; left: 0;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQIW2NkQAKrVq36zwjjgzhhYWGMYAEYB8RmROaABADeOQ8CXl/xfgAAAABJRU5ErkJggg==)
          repeat;`
    el.appendChild(block)
  })
}

const unblock = () => {
  props.blockingElements.forEach((el: HTMLElement) => {
    if (elCanBeDisabled(el.tagName)) {
      el.removeAttribute('disabled')
    }
    const block = el.querySelector('.block')
    if (block) {
      el.removeChild(block)
    }
  })
}

const attachLoaders = () => {
  props.loaderElements.forEach((el: HTMLElement) => {
    const { destroy } = mount(CircularLoader, {
      props: {
        style: {
          position: 'absolute',
          width: '22px',
          height: '22px',
          ...props.loaderElementsStyle,
        },
      },
      element: el,
    })
    currentStateData.loadersDestroyFns.push(destroy)
  })
}

const detachLoaders = () => {
  currentStateData.loadersDestroyFns.forEach((fn) => fn())
  currentStateData.loadersDestroyFns = []
}

const setResult = ({ message }: HandlerArgs) => {
  currentStateData.message = message
  setTimeout(() => {
    trigger({ state: States.Idle })
    currentStateData.message = null
  }, props.messageTimeout)
}

const handlers = {
  [States.Idle]: [],
  [States.Loading]: [block, attachLoaders],
  [States.Result]: [setResult, unblock, detachLoaders],
}

const elCanBeDisabled = (tagName: string) => {
  return ['BUTTON', 'INPUT', 'SELECT', 'OPTION', 'TEXTAREA'].includes(tagName)
}

onMounted(() => {
  if (props.loading) {
    trigger({ state: States.Loading })
  }
})
</script>

<style scoped>
.inplace-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.block:after {
  content: "";
  display: block;
  position: absolute;
  pointer-events: all;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQIW2NkQAKrVq36zwjjgzhhYWGMYAEYB8RmROaABADeOQ8CXl/xfgAAAABJRU5ErkJggg==)
    repeat;
  z-index: 2;
}
.loader {
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
  z-index: 1;
  width: 36px;
  height: 36px;
}

.message {
  position: absolute;
  padding: 3px 7px;
  font-size: 1.1rem;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  z-index: 3;

  &.success {
    background-color: #4caf50;
  }
  &.warn {
    background-color: #e4b866;
  }
  &.error {
    background-color: #ff5252;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  bottom: 0;
  opacity: 0;
}
</style>
