import { useProfile } from '@/ContextTab/modules/user/composables/useProfile'
import { useStore } from '@/ContextTab/useStore'
import type { SetProfileColorData } from '../types'
import type { NewsColorsStore } from '@/ContextApp/stores/news/colors'
import type { PublishedNewsStore } from '@/ContextApp/stores/news/published/set'


export function useColorMap() {
  const colorsStore = useStore<NewsColorsStore>('colors', {
    shared: true,
  })

  const publishedNewsStore = useStore<PublishedNewsStore>('publishedNews', {
    shared: false,
  })

  const { updateColors: updateProfileColors } = useProfile({})

  const setColors = async (items: SetProfileColorData) => {
    await updateProfileColors(items)
    colorsStore.setNewsCodesColorMap?.(publishedNewsStore.data.set ?? [])
  }

  return { setColors }
}
