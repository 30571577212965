<template>
  <PsPopover
    class="ps-codes-popover"
    trigger="manual"
    placement="right"
    :open="open"
    @clickOutside="emit('cancel')"
  >
    <slot />
    <template #body>
      <WithInplaceMessage :loading="loading">
        <section>
          <main>
            <slot name="main" />
          </main>
          <footer>
            <PsSubmitGroup
              @submit="onSubmit"
              @cancel="emit('cancel')"
            />
          </footer>
        </section>
      </WithInplaceMessage>
    </template>
  </PsPopover>
</template>

<script setup lang="ts">
import PsSubmitGroup from '@/ContextTab/components/PsSubmitGroup.vue'
import PsPopover from '@/ContextTab/components/UI/Popover/PsPopover.vue'
import WithInplaceMessage from '@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue'


withDefaults(defineProps<{ open: boolean, loading?: boolean }>(), {
  loading: false,
})

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

function onSubmit() {
  emit('submit')
}
</script>

<style>
.ps-codes-popover {
  --popover--padding: 0;
}
</style>

<style scoped>
header,
main,
footer {
  padding: var(--padding);
}
section {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  & main {
    overflow-y: auto;
    height: min(400px, 80vh);
    width: min(350px, 80vw);
    padding-bottom: 0;
  }

  & footer {
    padding-top: var(--padding-top-1-2);
  }
}
</style>
