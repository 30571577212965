import { onBeforeUnmount, onMounted, ref, type Ref } from 'vue'
import { differenceInMilliseconds, isSameDay, startOfTomorrow } from '@/utils/dates'
import type { NewsItemPublished } from '@/ContextTab/modules/news/published/components/types'

/**
 * Содержит логику отображения дата-разделителей.
 *
 * Выставляет таймаут таску на обновление текущей даты.
 */
export function useDateDelimiters(items: Ref<NewsItemPublished[]>) {
  const currentDate = ref(Date.now())
  let timeout

  function setDayChangeTimer() {
    timeout = setTimeout(
      () => {
        currentDate.value = Date.now()
        setDayChangeTimer()
      },
      differenceInMilliseconds(startOfTomorrow(), currentDate.value),
    )
  }

  onMounted(() => {
    setDayChangeTimer()
  })

  onBeforeUnmount(() => {
    clearTimeout(timeout)
  })

  function needDateDelimiter(index: number) {
    const currentEntry = items.value[index]

    if (!currentEntry?.id) {
      return false
    }

    return !isSameDay(
      new Date(items.value[index - 1]?.status_modified_at ?? new Date()),
      new Date(currentEntry.status_modified_at),
    )
  }

  return {
    currentDate,
    needDateDelimiter,
  }
}
