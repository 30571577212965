<template>
  <div class="configurator-wrapper">
    <div class="configurator">
      <div class="configurator-body">
        <slot />
      </div>
      <div class="configurator-footer">
        <slot name="footer">
          <PsSubmitGroup
            :submitDisabled="submitDisabled"
            @submit="emit('submit')"
            @cancel="emit('cancel')"
          />
        </slot>
      </div>
    </div>
    <div class="shadow" />
  </div>
</template>

<script setup lang="ts">
import PsSubmitGroup from '@/ContextTab/components/PsSubmitGroup.vue'
import { propsDefaults, type Props } from './props'


withDefaults(defineProps<Props>(), propsDefaults)
const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()
</script>

<style scoped>
.configurator-wrapper {
  bottom: v-bind(bottom);
  color: #000000;
  height: 100%;
  left: v-bind(left);
  position: absolute;
  top: v-bind(top);
  width: v-bind(width);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.15);
  .configurator {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 50px;
    box-sizing: border-box;
    background: #f2f2f2;
    height: v-bind(height);
    overflow: hidden;
    position: relative;
    padding: 14px 10px;
  }

  .configurator-body {
    height: 100%;
    overflow-y: hidden;
    position: relative;
  }
  .configurator-footer {
    align-items: center;
    display: flex;
  }

  .shadow {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 21px;
  }
}
</style>
