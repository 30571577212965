import { ref } from 'vue'


class LegacyAppHandlerInstance {
  public app: any = null
  public created = ref(false)

  set(app: any) {
    this.app = app
    this.created.value = true
  }

  remove() {
    this.app = null
    this.created.value = false
  }
}

export const legacyAppHandler = new LegacyAppHandlerInstance()
