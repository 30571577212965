<template>
  <IButton
    class="ps-button"
    v-bind="inklineProps"
    :class="{
      '-round': round,
      '-transparent': !color,
      '-no-padding': !padding,
    }"
    @click="emit('click', $event)"
  >
    <span class="ps-button-wrap">
      <template v-if="slots.prefix">
        <slot name="prefix" />
      </template>
      <slot />
      <template v-if="slots.suffix">
        <slot name="suffix" />
      </template>
    </span>
  </IButton>
</template>

<script setup lang="ts">
import { IButton } from '@inkline/inkline'
import { computed } from 'vue'
import { buttonPropsDefaults, type ButtonProps } from './props'


const props = withDefaults(defineProps<ButtonProps>(), buttonPropsDefaults)

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
}>()

const slots = defineSlots<{
  default?(props: any): any
  prefix?(props: any): any
  suffix?(props: any): any
}>()

const inklineProps = computed(() => {
  return props
})

const borderStyle = computed(() => (props.border === false
  ? 'none'
  : 'solid'))
</script>

<style scoped>
.ps-button {
  /* Переопределяем паддинги, чтобы размер кнопок меньше стандартного */
  --padding-top: calc(1rem * 0.8);
  --padding-right: calc(1rem * 0.8);
  --padding-bottom: calc(1rem * 0.8);
  --padding-left: calc(1rem * 0.8);

  --ps-button-content--colum-gap: 0.5em;
  --button--border-style: v-bind(borderStyle) !important;

  .ps-button-wrap {
    display: inline-flex;
    align-items: center;
    column-gap: var(--ps-button-content--colum-gap);
  }

  &.-round {
    --button--border-radius: 34px;
  }
  &.-transparent {
    --button--hover--border-color: var(--color-primary);

    &:not(:disabled, .-disabled):hover {
      --button--color: var(--color-primary);
    }
  }
  &.-no-padding {
    --button--padding: 0;
  }
}
</style>
