<template>
  <MainNavButton
    icon="button-menu"
    name="main-menu"
    :active="active"
    label="Главное меню"
    class="menu-button"
    color="white"
    @click="emit('click')"
  />
</template>

<script setup lang="ts">
import MainNavButton from '@/ContextTab/components/MainNav/MainNavButton.vue'


withDefaults(defineProps<{ active?: boolean }>(), { active: false })
const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>
