import { PsHubMessageHubHandler } from './messageHub'
import type { PsHubMessageListener } from './messageHub'
import type {
  PsHubMessagePayload,
  PsHubMessageMeta,
  PsHubCommandMeta,
  PsHubCommandType,
} from './messages'


export class MessageHubClient {
  protected _messageHub: PsHubMessageHubHandler

  constructor(state: { contextId: string }) {
    this._messageHub = new PsHubMessageHubHandler(state)
    this._messageHub.sendInit('Client initialized', 'client')
  }

  addMessageListener(listener: PsHubMessageListener) {
    return this._messageHub.addMessageListener({
      ...listener,
      // !!! listener.metaFilter в данном случае игнорируется. Это норм?
      metaFilter: (meta: PsHubMessageMeta) => {
        if (meta.source !== 'server') {
          return false
        }

        if (listener.metaFilter) {
          return listener.metaFilter(meta)
        }
        return true
      },
    })
  }

  addCommandListener(commandType: PsHubCommandType, listener: PsHubMessageListener) {
    return this._messageHub.addCommandListener(commandType, listener)
  }

  sendMessage(meta: Partial<PsHubMessageMeta>, payload: PsHubMessagePayload) {
    return this._messageHub.sendMessage({ ...meta, source: 'client' }, payload)
  }

  removeMessageListener(uuid: string) {
    return this._messageHub.removeMessageListener(uuid)
  }

  async sendCommand(
    meta: Partial<PsHubCommandMeta>,
    payload?: PsHubMessagePayload,
  ) {
    return this._messageHub.sendCommand({ ...meta, source: 'client' }, payload)
  }
}
