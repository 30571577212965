<template>
  <div
    id="legacy-app"
    :class="{
      'has-aside': isTaskPanelShowed && !isScreenSmall, // task panel shown, but not visible
      sm: isScreenSmall,
    }"
  >
    <div id="legacy-app-wrapper" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, nextTick, onUnmounted, watch } from 'vue'
import createLegacyApp from '@/LegacyApp/dist/publishing-ui.mjs'
import '@/LegacyApp/dist/style.css'
import { usePassAuth } from './composables/usePassAuth'
import { usePassCodes } from './composables/usePassCodes'
import { usePassProfile } from './composables/usePassProfile'
import { usePassRefs } from './composables/usePassRefs'
import { usePassUiState } from './composables/usePassUiState'
import { usePassUsers } from './composables/usePassUsers'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'
import { legacyAppHandler } from '@/ContextTab/modules/legacy/appHandler'


const props = withDefaults(
  defineProps<{
    isTaskPanelShowed?: boolean
  }>(),
  {
    isTaskPanelShowed: false,
  },
)

let legacyApp: any = null

const { isScreenSmall } = useScreenBreakpoints()

const passAuth = await usePassAuth()
const passProfile = await usePassProfile()
const passRefs = await usePassRefs()
const passCodes = await usePassCodes()
const passUsers = await usePassUsers()
const passUiState = usePassUiState()

async function initLegacy() {
  legacyApp = createLegacyApp()

  await passAuth(legacyApp)
  await passRefs(legacyApp)
  await passProfile(legacyApp)
  await passCodes(legacyApp)
  await passUsers(legacyApp)

  await nextTick()

  legacyApp.$mount('#legacy-app-wrapper')
  legacyAppHandler.set(legacyApp)
}

onUnmounted(async () => {
  if (legacyApp !== null) {
    legacyApp.$store.commit('auth/UNMOUNT')
    await legacyApp.$store.dispatch('auth/reset')
    await legacyApp.$store.dispatch('userProfile/clearProfile')
    legacyApp.$destroy()
    legacyApp = null
    legacyAppHandler.remove()
  }
})

onMounted(() => {
  initLegacy()
})

watch(
  () => props.isTaskPanelShowed,
  (next) => passUiState(legacyApp, next),
)
</script>

<style scoped>
#legacy-app {
  &.sm {
    width: 100vw;
  }
}
</style>
