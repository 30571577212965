<template>
  <div class="auth">
    <IForm
      v-model="schema"
      class="auth-form"
      :loading="busy"
      @submit="submit"
    >
      <WithInplaceMessage :loading="busy">
        <div class="wrapper">
          <h1 class="title">
            Авторизация
          </h1>
          <div
            v-if="unmappedErrors.length"
            class="unmapped-errors"
          >
            <small
              v-for="error in unmappedErrors"
              :key="error.code"
              class="unmapped-error"
            >
              {{ error.title }}
            </small>
          </div>
          <IFormGroup
            size="lg"
            class="ps-form-group"
          >
            <IInput
              v-model="schema.username.value"
              name="username"
              type="text"
              autocomplete="username"
              placeholder="Электронная почта"
              size="lg"
            />
            <IFormError
              for="username"
              :visible="['invalid']"
            />
          </IFormGroup>
          <IFormGroup
            size="lg"
            class="ps-form-group"
          >
            <IInput
              v-model="schema.password.value"
              name="password"
              type="password"
              placeholder="Пароль"
              autocomplete="current-password"
              size="lg"
              :showPasswordToggle="true"
            />
            <IFormError
              for="password"
              :visible="['invalid']"
            />
          </IFormGroup>
          <IFormGroup size="lg">
            <PsButton
              block
              size="lg"
              type="submit"
              color="primary"
              :disabled="schema.prisine || (schema.touched && schema.invalid)"
            >
              Войти в систему
            </PsButton>
          </IFormGroup>
        </div>
      </WithInplaceMessage>
    </IForm>
    <div class="optional-link">
      Если вы забыли пароль,{{ " " }}
      <a
        href="javascript:;"
        @click="emit('reset')"
      >восстановите его</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IForm, IFormGroup, IInput, IFormError } from '@inkline/inkline'
import { computed, ref } from 'vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import WithInplaceMessage from '@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue'
import { useWiredForm } from '@/ContextTab/composables/useWiredForm'
import { useStore } from '@/ContextTab/useStore'
import type { AuthStore } from '@/ContextApp/stores/auth'


const emit = defineEmits<{
  (e: 'reset'): void
}>()

const authStore = useStore<AuthStore>('auth', { shared: true })

const busy = ref(false)

const storeErrors = computed(() => authStore.data.error ?? null)

const formConfig = {
  username: {
    validators: [
      { name: 'required', message: 'Введите электронную почту' },
      { name: 'email', message: 'Введите корректный адрес электронной почты' },
      {
        name: 'custom',
        key: 'api',
        fallbackMessage: 'Пользователь не найден',
      },
    ],
  },
  password: {
    validators: [
      {
        name: 'custom',
        key: 'api',
        fallbackMessage: 'Неверный пароль',
      },
    ],
  },
}

const handleLogin = async () => {
  busy.value = true
  await authStore.authorize?.({
    username: form.value.username,
    password: form.value.password,
  })
  busy.value = false
}

const { schema, form, submit, unmappedErrors } = useWiredForm<{
  username: string
  password: string
}>(formConfig, storeErrors, handleLogin)

</script>

<style scoped>
@import "../styles/auth.pcss";
</style>
