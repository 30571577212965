import { computed, type Ref } from 'vue'
import { GroupsOrder } from '@/lib/glossary'

/* FIXME: исправить типы, когда будет типизация данных из API */
type Code = any
type CodeCategory = any

function sortAlphaBy<Item extends Record<string, any>>(
  array: Item[],
  key: keyof Item,
) {
  return array.sort((a, b) => {
    const valA = a[key].toLowerCase()
    const valB = b[key].toLowerCase()
    if (valA < valB) {
      return -1
    }

    if (valA > valB) {
      return 1
    }
    return 0
  })
}

function groupSort<Item extends Record<string, any>>(array: Item[]): Item[] {
  return array.sort((a, b) => {
    if (GroupsOrder[a.path] < GroupsOrder[b.path]) {
      return -1
    }

    if (GroupsOrder[a.path] > GroupsOrder[b.path]) {
      return 1
    }
    return 0
  })
}

/**
 * Формирует список кодов, разбитый по их корневым категориям.
 */
function groupCodes(codes: Code[]): any[] {
  // Получаем корневые категории, отсортированные по названию:
  const groupsById: Record<CodeCategory['id'], CodeCategory> = {}
  for (const item of codes) {
    if (item?.category) {
      groupsById[item.category.id] = item.category
    }
  }

  return groupSort(Object.values(groupsById))
    .map((cat) => {
      // Получаем коды для каждой категории, отсортированные по названию:
      const items = sortAlphaBy(
        codes.filter((item) => item.category?.id === cat.id),
        'name',
      )

      return { category: cat, codes: items }
    })
}

export function useGroupedCodes(codes: Ref<Code[] | undefined>) {
  return {
    groupedCodes: computed(() => codes.value
      ? groupCodes(codes.value)
      : undefined),
  }
}
