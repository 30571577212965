<template>
  <Teleport
    v-if="useTeleport"
    :to="teleportTo"
  >
    <ConfiguratorContent
      v-bind="props"
      @submit="emit('submit')"
      @cancel="emit('cancel')"
    >
      <slot />
      <template #footer>
        <slot name="footer" />
      </template>
    </ConfiguratorContent>
  </Teleport>
  <ConfiguratorContent
    v-else
    v-bind="props"
    @submit="emit('submit')"
    @cancel="emit('cancel')"
  >
    <slot />
    <template #footer>
      <slot name="footer" />
    </template>
  </ConfiguratorContent>
</template>

<script setup lang="ts">
import ConfiguratorContent from './ConfiguratorContent.vue'
import { propsDefaults, type Props } from './props'


const props = withDefaults(
  defineProps<
    Props & {
      useTeleport?: boolean
      teleportTo?: string
    }
  >(),
  {
    ...propsDefaults,
    useTeleport: false,
    teleportTo: undefined,
  },
)
const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()
</script>

<style scoped>
.dialog {
  box-sizing: border-box;
  width: 100vw !important;
  height: 90vh !important;
  position: unset !important;
  left: 0 !important;
  padding: 0;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .dialog-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}
</style>
