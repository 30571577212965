import { ref, computed } from 'vue'
import { useStore } from '@/ContextTab/useStore'
import type { SetType } from '@/lib/types'
import type { NewsSetStore } from '@/ContextApp/stores/news/task-panel/composables/useSet.ts'


export function useTaskPanelList(setType: SetType) {

  // stores inProgressNews/myNews/waitingNews are requested here
  const store = useStore<NewsSetStore>(`${setType}News`, {
    onStart: (store) => store.loadNext(),
  })
  const items = computed(() => store.data.newsList?.filter((item) => !!item) ?? [])
  const hasMoreItems = computed(() => store.data.hasMoreItems ?? true)
  const isFetching = computed(() => store.data.isFetching ?? true)
  const initialLoaded = computed(() =>
    !isFetching.value && (items.value.length || !hasMoreItems.value),
  )

  const errors = computed(() => {
    return store.data.error ? [store.data.error] : []
  })

  const DEFAULT_SELECTED_ID = -1
  const selectedId = ref(DEFAULT_SELECTED_ID)

  const selectItem = (id?: number) => {
    selectedId.value = id ?? DEFAULT_SELECTED_ID
  }

  const getIsNewsItemInSet = (id: string | number) => {
    return store.data.set?.includes(id)
  }

  const onReachedEnd = () => {
    if (store.data.hasMoreItems) {
      store.loadNext?.()
    }
  }

  return {
    items,
    reload: () => store.reload?.(),
    selectedId,
    selectItem,
    onReachedEnd,
    hasMoreItems,
    errors,
    isFetching,
    initialLoaded,
    getIsNewsItemInSet,
  }
}
