<template>
  <PsPopover
    class="share-settings__popover"
    placement="bottom"
    trigger="manual"
    :open="curShow"
    @clickOutside="close"
  >
    <slot />
    <template #body>
      <div class="share-settings__popover__body">
        <IRadio
          name="save"
          size="md"
          :modelValue="actionState.save"
          :disabled="!canSetDefault"
          @update:modelValue="switchActionState('save')"
        >
          Сохранить выбранного пользователя (группу) по умолчанию
        </IRadio>
        <div class="share-settings__option">
          <ISelect
            v-if="canSetDefault"
            v-model="checkedRecipientId"
            :options="options"
            @update:modelValue="onSelect"
          />
        </div>
        <div>
          <IRadio
            name="reset"
            size="md"
            :modelValue="actionState.reset"
            :disabled="!defaultRecipient"
            @update:modelValue="switchActionState('reset')"
          >
            Удалить сохраненного пользователя (группу) по умолчанию
          </IRadio>
          <div
            v-if="defaultRecipient"
            class="share-settings__option _text:bold"
          >
            ({{ defaultRecipient.name }})
          </div>
        </div>
      </div>
      <footer class="share-settings__popover__footer">
        <PsSubmitGroup
          :submitDisabled="!canSubmit"
          @submit="onSubmit"
          @cancel="close"
        />
      </footer>
    </template>
  </PsPopover>
</template>

<script setup lang="ts">
import { ISelect, IRadio } from '@inkline/inkline'
import { useVModel } from '@vueuse/core'
import { ref, computed, watch } from 'vue'
import PsPopover from '@/ContextTab/components/UI/Popover/PsPopover.vue'
import PsSubmitGroup from '@/ContextTab/components/PsSubmitGroup.vue'


const props = withDefaults(
  defineProps<{
    show?: boolean | undefined
    defaultRecipient?: any
    selectedRecipients?: any[]
  }>(),
  {
    show: undefined,
    appliedColor: () => ({}),
    codeHasChildren: false,
    defaultRecipient: null,
    selectedRecipients: () => [],
  },
)

const emit = defineEmits<{
  (e: 'update:show', value: (typeof props)['show']): void
  (e: 'submit', value: {
    defaultRecipientGroupId: string | number | null
    defaultRecipientUserId: string | number | null
  }): void
}>()

const curShow = useVModel(props, 'show', emit)

const ACTION_STATE_INIT = () => ({
  save: !!props.selectedRecipients.length,
  reset: false,
})
const CHECKED_RECIPIENT_INIT = () => props.selectedRecipients[0]?.id ?? null

const actionState = ref(ACTION_STATE_INIT())

const options = computed(() =>
  props.selectedRecipients.map((r) => ({ label: r.name, id: r.id })),
)

const checkedRecipientId = ref(CHECKED_RECIPIENT_INIT())

const canSetDefault = computed(
  () =>
    !!props.selectedRecipients.length
    && checkedRecipientId.value !== props.defaultRecipient?.id,
)

const canSubmit = computed(
  () => Object.values(actionState.value).indexOf(true) !== -1,
)

const onSubmit = () => {
  const params = {
    defaultRecipientGroupId: null,
    defaultRecipientUserId: null,
  }
  if (actionState.value.save) {
    const checkedRecipient = props.selectedRecipients.find(
      (r) => r.id === checkedRecipientId.value,
    )
    if (!checkedRecipient) {
      return
    }

    if (checkedRecipient.is_group) {
      params.defaultRecipientGroupId = checkedRecipientId.value
    } else {
      params.defaultRecipientUserId = checkedRecipientId.value
    }
  } else if (actionState.value.reset) {
    params.defaultRecipientGroupId = null
    params.defaultRecipientUserId = null
  }
  emit('submit', params)
  close()
}

const switchActionState = (id) => {
  Object.keys(actionState.value).map(
    (key) => (actionState.value[key] = key === id),
  )
}

const onSelect = () => {
  switchActionState('save')
}

const reset = () => {
  actionState.value = ACTION_STATE_INIT()
  checkedRecipientId.value = CHECKED_RECIPIENT_INIT()
}

const close = () => {
  curShow.value = false
}

watch(curShow, (next) => {
  if (next) {
    reset()
  }
})
</script>

<style>
.share-settings__popover {
  --popover--padding-top: 1rem;
  --popover--padding-right: 2rem;
  --popover--padding-bottom: 1rem;
  --popover--padding-left: 1rem;

  .pskit__popover__content {
    overflow: visible;
  }
}

.share-settings__popover__body {
  --margin-right: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 450px;
}
.share-settings__popover__footer {
  margin-top: 1rem;
}
.share-settings__option {
  padding-left: 24px;
}
</style>
