<template>
  <PsResponsiveButton
    :tooltip="tooltip"
    :buttonComp="PsButtonToolbar"
    :disabled="disabled"
    :contentStyle="{ gap: '0.25rem' }"
    :size="size"
    @click="emit('click', $event)"
  >
    <template v-if="slots.default">
      <slot />
    </template>
    <template
      v-if="slots.prefix"
      #prefix
    >
      <slot name="prefix" />
    </template>
    <template
      v-if="slots.xs"
      #xs
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
        </span>
      </template>
      <template v-else>
        <slot name="xs" />
      </template>
    </template>
    <template
      v-if="slots.sm"
      #sm
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="sm" />
      </template>
    </template>
    <template
      v-if="slots.md"
      #md
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="md" />
      </template>
    </template>
    <template
      v-if="slots.lg"
      #lg
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="lg" />
      </template>
    </template>
    <template
      v-if="slots.xl"
      #xl
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="xl" />
      </template>
    </template>
    <template
      v-if="slots.xxl"
      #xxl
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="xxl" />
      </template>
    </template>
    <template
      v-if="slots.xxxl"
      #xxxl
    >
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="xxxl" />
      </template>
    </template>
    <template #suffix>
      <slot name="suffix">
        <PbIcon
          v-if="showClear"
          title="Сбросить на текущую дату"
          class="clear-button"
          type="font"
          name="close"
          size="14"
          role="clear"
          @click.capture.stop.prevent="emit('clear')"
        />
      </slot>
    </template>
  </PsResponsiveButton>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import PsButtonToolbar from './PsButtonToolbar.vue'
import PsResponsiveButton from './PsResponsiveButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { pluralize } from '@/utils/pluralize'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'


withDefaults(
  defineProps<{
    name?: string
    tooltip?: string
    count?: number
    plural?: string[]
    showClear?: boolean
    size?: SizeBreakpointMark
    disabled?: boolean
  }>(),
  {
    name: undefined,
    tooltip: '',
    count: 0,
    plural: undefined,
    showClear: false,
    size: 'sm',
    disabled: false,
  },
)

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
  (e: 'clear'): void
}>()

defineSlots<{
  xs(): any
  sm(): any
  md(): any
  lg(): any
  xl(): any
  xxl(): any
  xxxl(): any
  default(): any
  prefix(): any
  suffix(): any
}>()

const slots = useSlots()
</script>

<style scoped lang="postcss">
.clear-button {
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgb(0, 0, 0, 0.1);
  }
  cursor: pointer;
}
</style>
