import { useRoute } from 'vue-router'
import { ref, watch, computed, type Ref } from 'vue'
import { useFavicon, useTitle } from '@vueuse/core'
import { usePsRoute } from '@/ContextTab/composables/psRoute'
import { legacyAppHandler } from '@/ContextTab/modules/legacy/appHandler'
import { base64ToUtf } from '@/utils/base64'
import { trimHTML } from '@/utils/trimHTML'
import { LEGACY_PANELS_TAB_DATA } from '@/lib/legacyPanelsTabData'


interface LegacyPanel {
  dataId: string | null
  id: number
  settings: any
  supply: any
  type: keyof typeof LEGACY_PANELS_TAB_DATA
}

export const useTabDescription = () => {
  const route = useRoute()
  const { isLegacyRoute } = usePsRoute()

  const focusedLegacyPanel: Ref<LegacyPanel | null> = ref(null)
  const legacyPanelNewsSlugline: Ref<string | null> = ref(null)

  const DEFAULT_FAVICON = 'published.png'
  const DEFAULT_TITLE = 'Издательская система'

  const favicon = computed(() => {
    if (isLegacyRoute.value && focusedLegacyPanel.value) {
      return LEGACY_PANELS_TAB_DATA[focusedLegacyPanel.value.type]?.favicon ?? DEFAULT_FAVICON
    }
    return route.meta.favicon ?? DEFAULT_FAVICON
  })

  const title = computed(() => {
    if (isLegacyRoute.value && focusedLegacyPanel.value) {
      return legacyPanelNewsSlugline.value
        || (LEGACY_PANELS_TAB_DATA[focusedLegacyPanel.value.type]?.title ?? DEFAULT_TITLE)
    } else if (route.name === 'published' && route.hash) {
      const presetName = getPresetName(route.hash)
      if (presetName) {
        return presetName
      }
    }
    return route.meta.title ?? DEFAULT_TITLE
  })

  useFavicon(favicon, { baseUrl: '/favicons/' })
  useTitle(title)

  function getPresetName(hash: string) {
    try {
      const base64 = hash.slice(1, window.location.hash.length)
      const decoded = base64ToUtf(base64)
      const data = JSON.parse(decoded)
      return data?.name ?? ''
    } catch {
      return ''
    }
  }

  function getSlugline(entry: any) {
    return entry?.slugline
      ? trimHTML(entry.slugline)
      : null
  }

  watch(
    legacyAppHandler.created,
    (isCreated) => {
      if (isCreated && legacyAppHandler.app) {
        const store = legacyAppHandler.app.$store

        store.subscribe((mutation: any) => {
          let panel: LegacyPanel | null = null
          let newsItemId: string | null = null
          let newsVersion: any = null
          let entry: any = null

          switch (mutation.type) {
            case 'panels/PANELS_CLEAR_FOCUS': // сброс фокуса
              focusedLegacyPanel.value = null
              legacyPanelNewsSlugline.value = null
              break
            case 'panels/PANEL_FOCUS': // фокус на любой панели
              focusedLegacyPanel.value = null
              legacyPanelNewsSlugline.value = null
              panel = store.state.panels.data.find((p: any) => p.id === mutation.payload)
              if (!panel) {
                return
              }
              focusedLegacyPanel.value = panel

              if (panel.type === 'newsItem') {
                newsItemId = String(panel.dataId)
                newsVersion = store.getters['news/getNewsItemCurrentVersion'](newsItemId) ?? null
                legacyPanelNewsSlugline.value = getSlugline(newsVersion)
              }
              break

            case 'news/versions/SET_NEWS_VERSION': // заполнение слаглайна в редакторе
              newsItemId = String(mutation.payload.newsItemId)

              if (focusedLegacyPanel.value?.type === 'newsItem' && focusedLegacyPanel.value.dataId === newsItemId) {
                newsVersion = store.getters['news/getNewsItemCurrentVersion'](newsItemId) ?? null
                legacyPanelNewsSlugline.value = getSlugline(newsVersion)
              }
              break

            case 'panels/PANEL_EMBARGO_SELECT_NEWS_ITEM': // открытие новости в саплай-панели у панели Эмбарго
              newsItemId = mutation.payload.entry?.id
              newsVersion = store.getters['news/getNewsItemCurrentVersion'](newsItemId) ?? null
              legacyPanelNewsSlugline.value = getSlugline(newsVersion)
              break

            case 'panels/PANEL_LIST_SELECT_ENTRY': // открытие новости в саплай-панели у панели Эмбарго: заметки АСОИ
              if (focusedLegacyPanel.value?.type === 'asoiNotesEmbargo') {
                entry = store.getters.getAsoiNoteById('embargo')(mutation.payload.entryId)
                legacyPanelNewsSlugline.value = getSlugline(entry)
              }
              break

            case 'panels/PANEL_HIDE_SUPPLY': // закрытие новости в саплай-панели у панели Эмбарго и Эмбарго: заметки АСОИ
              legacyPanelNewsSlugline.value = null
              break

            case 'panels/PANEL_REMOVE': // закрытие любой панели
              if (mutation.payload === focusedLegacyPanel.value?.id) {
                focusedLegacyPanel.value = null
                legacyPanelNewsSlugline.value = null
              }
              break
          }
        })
      }
    },
  )
}
