<template>
  <PsPopover
    trigger="hover"
    placement="bottom"
    :arrow="false"
    @show="onShow"
  >
    <div class="news-republish-status__icon">
      <PsIcon
        :name="iconName"
        size="14"
        data-test-selector="republish-status-icon"
      />
      <span
        v-if="childrenInWorkCount"
        class="news-republish-status__counter"
      >
        {{ childrenInWorkCount }}
      </span>
    </div>
    <template #body>
      <Circular
        v-if="isLoading"
        class="news-republish-status__loader"
      />
      <table v-else-if="hasChildren">
        <tr
          v-for="child in childrenNotDisposed"
          :key="child.news_id"
          class="news-republish-status__item"
        >
          <td
            v-if="child.is_published"
            class="tag published"
            data-test-selector="republish-status"
          >
            Выпущено
          </td>
          <td
            v-else-if="childStatusWaitForEdit(child)"
            class="tag waiting"
            data-test-selector="republish-status"
          >
            Ожидает ред.
          </td>
          <td
            v-else-if="childStatusWaitForPublication(child)"
            class="tag waiting"
            data-test-selector="republish-status"
          >
            Ожидает опубл.
          </td>
          <td
            v-else
            class="tag inwork"
          >
            В&nbsp;работе
          </td>
          <td
            class="news-republish-status__user"
            data-test-selector="republish-user"
            @click="emit('open', child.news_id)"
          >
            {{ userData(child) }}
          </td>
        </tr>
      </table>
      <div
        v-else
        class="news-republish-status__item"
      >
        Нет переопубликованных новостей
      </div>
    </template>
  </PsPopover>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Circular from '@/ContextTab/components/Busy/Loader/Circular.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import PsPopover from '@/ContextTab/components/UI/Popover/PsPopover.vue'
import { usePublishedLinks } from '@/ContextTab/modules/news/published/composables/usePublishedLinks'
import { useStore } from '@/ContextTab/useStore'
import { NEWS_LINK_TYPE_BY_NAME } from '@/lib/referencesByName'
import type { NewsItemPublished } from '@/ContextTab/modules/news/published/components/types'
import type { NewsStore } from '@/ContextApp/stores/news'


const props = defineProps<{
  item: NewsItemPublished
}>()

const emit = defineEmits<{
  (e: 'open', id: number): void
}>()

const newsStore = useStore<NewsStore>('news', {
  shared: true,
})

const isLoading = ref(false)

const {
  childLoaded,
  childStatusPublished,
  childStatusWaitForEdit,
  childStatusWaitForPublication,
  userData,
} = usePublishedLinks()

const childrenNotDisposed = computed(() => {
  const children = props.item.child_links

  if (!children) {
    return []
  }
  return children.filter(
    (child) =>
      !child.is_disposed
      && (child.type_id
        === NEWS_LINK_TYPE_BY_NAME['REPUBLICATION'].id
        || child.type_id
        === NEWS_LINK_TYPE_BY_NAME['REPUBLICATION_UNEDITED'].id),
  )
})

const publishedChildren = computed(() => {
  return childrenNotDisposed.value.filter(childStatusPublished)
})

const hasChildren = computed(() => {
  return !!(publishedChildren.value.length || childrenNotDisposed.value.length)
})

const childrenInWorkCount = computed(() => {
  return childrenNotDisposed.value.length - publishedChildren.value.length
})

const iconName = computed(() => {
  if (publishedChildren.value.length) {
    return 'republish/republish-active'
  }

  if (childrenNotDisposed.value.length) {
    return 'republish/republish-work'
  }

  return 'republish/republish-none'
})

const onShow = async () => {
  const hasAllNewsLoaded
    = childrenNotDisposed.value
      .reduce((acc, curr) => {
        acc.push(!!childLoaded(curr))
        return acc
      }, [])
      .filter((value) => !!value).length === childrenNotDisposed.value.length

  if (!hasAllNewsLoaded) {
    isLoading.value = true
    await newsStore.fetchNewsLinks?.(props.item.id)
  }

  isLoading.value = false
}
</script>

<style scoped>
.news-republish-status__counter {
  margin-left: 2px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #ff9900;
}
.news-republish-status__item {
  white-space: nowrap;
  font-weight: 700;
  font-size: 12px;
}

.news-republish-status__icon {
  display: flex;
  align-items: flex-end;
}

.tag {
  padding: 1px 3px;
}

.inwork {
  background-color: #ff9900;
}

.published {
  background-color: #47de00;
}

.waiting {
  background-color: #ccff00;
}

.news-republish-status__loader {
  width: 12px;
  height: 12px;
}

.news-republish-status__user {
  padding-left: 5px;
  margin-bottom: -1px;
  text-decoration: underline;
  transition: color 0.2s ease-out;
  cursor: pointer;

  &:hover {
    color: #0064d9;
  }
}
</style>
