import { computed } from 'vue'
import { useRoute } from 'vue-router'


export function usePsRoute() {
  const route = useRoute()

  const isLegacyRoute = computed(() =>
    typeof route?.name === 'string'
      ? route.name.includes('legacy')
      : false,
  )

  return {
    isLegacyRoute,
  }
}
