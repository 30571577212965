<template>
  <div
    class="ps-avatar"
    data-test-selector="ps-avatar"
  >
    <img
      v-if="image"
      :src="image"
      :alt="alt"
      class="image"
      data-test-selector="ps-avatar-image"
    >
    <span
      v-else-if="placeholder"
      class="placeholder"
      data-test-selector="ps-avatar-placeholder"
    >
      {{ placeholder }}
    </span>
    <i
      v-if="indicator"
      class="indicator"
      :class="{ online }"
      data-test-selector="ps-avatar-indicator"
      :data-test-state="online ? 'online' : 'offline'"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { stringToColor } from '@/utils/stringToColor'


interface Props {
  image?: string
  alt?: string
  placeholder?: string
  background?: string
  indicator?: boolean
  online?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  image: undefined,
  alt: undefined,
  placeholder: undefined,
  background: undefined,
  indicator: false,
  online: false,
})

const bg = computed(() => {
  if (props.background) {
    return props.background
  }

  if (props.alt) {
    return stringToColor(props.alt, 40, 90)
  }
  return 'var(--color-gray)'
})
</script>

<style scoped>
.ps-avatar {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  width: 42px;
  height: 42px;
  line-height: 40px;
  text-align: center;
  background-color: v-bind(bg);
  border: 1px solid var(--color-white);
  border-radius: 50%;
  user-select: none;

  .image {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .placeholder {
    font-size: var(--font-size-sm);
    color: var(--color-gray);
  }

  .indicator {
    background-color: var(--color-gray-300);
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 3px;
    right: 3px;
    height: 8px;
    width: 8px;

    &.online {
      background-color: var(--color-success);
    }
  }
}
</style>
