<template>
  <span
    v-if="newsItemIsAssignedToMe"
    class="to-me"
  >Мне</span>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import { useNewsItem } from '@/ContextTab/modules/news/composables/useNewsItem'


const props = defineProps<{
  itemId: string | number
}>()

const { newsItemIsAssignedToMe } = useNewsItem(toRef(() => props.itemId))
</script>

<style scoped>
.to-me {
  display: inline-block;
  margin-right: 0.375rem;
  padding: 0 3px;
  line-height: 1rem;
  vertical-align: 2px;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #bd985c;
  border: 2px solid #bd985c;
  border-radius: 3px;
  opacity: 1;
}
</style>
