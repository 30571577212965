/**
 * Декодит HTML-мнемоники.
 * Нужно для текстового представления полей новости в UI.
 *
 * ProseMirror преобразует в мнемоники
 * - знак `&` (сам амперсанд является частью синтаксиса мнемоник)
 * - знаки `<` и `>` (защита от html-разметки)
 * - неразрывный пробел
 */
const renderHtmlEntity = (str: string): string =>
  str
    .replace('&amp;', '&')
    .replace('&lt;', '<')
    .replace('&gt;', '>')
    .replace('&nbsp;', ' ') // переводим в обычный пробел
    .replace('&quot;', '"') // это вроде не обязательно (ProseMirror не заменяет кавычки на мнемоники)

/**
 * Функция для текстового представления полей новости
 *
 * ProseMirror: Text -> HTML
 * Эта функция: HTML -> Text
 */
export function trimHTML(html: unknown): string {
  if (typeof html !== 'string') {
    return ''
  }

  return html
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/&([^;\s]+);/g, renderHtmlEntity)
    .trim()
}

export function replaceHtmlEntity(text: string): string {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
}
