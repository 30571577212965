<template>
  <div class="wrapper">
    <div class="tree-wrapper">
      <NewsCodesTree
        ref="tree"
        :items="filteredItems"
        :filterState="filterState"
        :allOpened="currentTabIndex > 0"
        :emptyText="currentTabIndex === 1 ? 'Ничего не выбрано' : undefined"
        mode="multi"
        @change="changeItemState"
      />
    </div>
    <div
      v-if="selectedCodes?.length"
      class="codes-shelf"
    >
      <span>Добавленные коды:</span>
      <div class="codes-list">
        <div
          v-for="code of selectedCodes"
          :key="code.item.id"
          :class="code.state"
        >
          <span>{{ code.item.name }}</span>
          <PsIcon
            type="font"
            name="close"
            size="14"
            class="remove-code"
            @click="uncheckItem(code.item)"
          />
        </div>
      </div>
    </div>
    <div class="tabs">
      <span>Показывать:</span>
      <PsRadioButtons
        v-model="currentTabIndex"
        :options="tabs"
        class="news-filter-codes"
        variant="button-group"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, useTemplateRef, watch } from 'vue'
import NewsCodesTree from './codesTree/NewsCodesTree.vue'
import PsRadioButtons from '@/ContextTab/components/UI/Button/PsRadioButtons.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { useFilterCodes } from '../composables/useFilterCodes'
import { EQuadboxState } from '@/ContextTab/components/UI/types'
import { useFilterTreeUtils } from '@/ContextTab/modules/news/composables/useFilterTreeUtils'
import type {
  CodeFilterState,
  CodeItemsMap, FilterProp,
  ICodeItem,
} from '@/ContextTab/modules/news/codes/types'


const props = withDefaults(
  defineProps<{
    filters: FilterProp
    type?: 'all' | 'feeds' | 'non-feeds'
  }>(),
  {
    type: 'all',
  },
)

const emit = defineEmits<{
  (e: 'update', filterState: CodeFilterState): void
}>()

const enum VisibleCodesMode {
  all = 0,
  selected = 1,
}

const { filteredByUid, filtered, allCodes } = useFilterCodes(props.type)

const itemsById = computed<CodeItemsMap>(() => filtered.value as any)
const itemsByUid = computed<CodeItemsMap>(() => filteredByUid.value as any)

const filterState = reactive<CodeFilterState>({})

const {
  removePartiallySelected,
  recalcPartiallySelected,
} = useFilterTreeUtils(filterState, allCodes, itemsByUid)

watch(
  itemsByUid,
  (next) => {
    if (next && props.filters) {
      const idsKey = props.type === 'feeds'
        ? 'feed_code_ids'
        : 'code_ids'
      const excludedIdsKey
        = props.type === 'feeds'
          ? 'feed_code_excluded_ids'
          : 'code_excluded_ids'

      Object.entries<(number | string)[]>(props.filters)
        .forEach(([key, list]) => {
          list?.forEach((id: number | string) => {
            if (key === idsKey) {
              includeItem(allCodes.value[id], true)
            } else if (key === excludedIdsKey) {
              excludeItem(allCodes.value[id], true)
            }
          })
        })
    }
  },
  { immediate: true },
)

const tabs = [
  {
    id: VisibleCodesMode.all,
    label: 'Все',
  },
  {
    id: VisibleCodesMode.selected,
    label: 'Выбранные',
  },
]

const currentTabIndex = ref(0)

const tree = useTemplateRef('tree')

watch(currentTabIndex, (next, prev) => {
  if (next !== prev) {
    tree.value?.scrollToTop()
  }
})

const selectedCodes = computed(() =>
  Object.values(filterState)
    .filter(
      ({ id, state }) =>
        itemsById.value
        && itemsById.value[id]
        && (state === EQuadboxState.included || state === EQuadboxState.excluded),
    )
    .map(({ id, state }) => ({
      item: itemsById.value?.[id],
      state,
    })),
)

const itemsFilteredBySelected = computed<CodeItemsMap>(() => {
  const filtered: CodeItemsMap = {}
  const allIds = selectedCodes.value.map((code) => code.item.id)

  if (!allIds?.length) {
    return filtered
  }

  Object.values(itemsByUid.value)
    .forEach((item: any) => {
      if (allIds.indexOf(item.id) !== -1) {
        filtered[item.uid] = item
        if (item.track?.length) {
          item.track.forEach((uid: string) => {
            filtered[uid] = itemsByUid.value[uid]
          })
        }
      }
    })

  return filtered
})

const filteredItems = computed<CodeItemsMap>(() =>
  currentTabIndex.value === 0
    ? itemsByUid.value
    : itemsFilteredBySelected.value,
)

function uncheckParent(itemId: number | string) {
  if (itemId === 0) {
    return
  }
  const record = filterState[itemId] ?? { id: itemId, state: EQuadboxState.partial }
  if (record.state === EQuadboxState.partial) {
    record.state = EQuadboxState.unchecked
  }
  filterState[itemId] = record
}

function includeParent(itemId: number | string) {
  if (!itemId) {
    return
  }
  const record = filterState[itemId] ?? { id: itemId, state: EQuadboxState.partial }
  if (record.state === EQuadboxState.unchecked) {
    record.state = EQuadboxState.partial
  }
  filterState[itemId] = record
}

function emitUpdate(silent: boolean) {
  recalcPartiallySelected()

  if (!silent) {
    emit('update', removePartiallySelected(filterState))
  }
}

function includeItem(item: ICodeItem, silent: boolean = false) {
  if (!item) {
    return
  }

  const record = filterState[item.id] ?? { id: item.id, state: EQuadboxState.included }
  record.state = EQuadboxState.included
  filterState[item.id] = record
  item.track?.forEach(includeParent)

  emitUpdate(silent)
}

function excludeItem(item: ICodeItem, silent: boolean = false) {
  if (!item) {
    return
  }

  const record = filterState[item.id] ?? { id: item.id, state: EQuadboxState.excluded }
  record.state = EQuadboxState.excluded
  filterState[item.id] = record
  item.track?.forEach(includeParent)

  emitUpdate(silent)
}

function uncheckItem(item: ICodeItem, silent: boolean = false) {
  if (!item) {
    return
  }

  const record = filterState[item.id] ?? { id: item.id, state: EQuadboxState.unchecked }
  record.state = EQuadboxState.unchecked
  filterState[item.id] = record
  item.track?.forEach(uncheckParent)

  emitUpdate(silent)
}

function changeItemState(item: ICodeItem, state: string) {
  if (state === EQuadboxState.included) {
    includeItem(item)
  }

  if (state === EQuadboxState.excluded) {
    excludeItem(item)
  }

  if (state === EQuadboxState.unchecked) {
    uncheckItem(item)
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tree-wrapper {
  overflow: hidden;
  flex: 1;
}
.tabs {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  height: 2.625rem;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;

  .button-group {
    box-shadow:
      0 3px 1px -2px #0003,
      0 2px 2px #00000024,
      0 1px 5px #0000001f;
  }

  & span {
    font-size: 0.875rem;
    margin-right: 6px;
  }
}

.codes-shelf {
  max-height: 111px;
  overflow: hidden;
  padding-top: 1rem;
  width: 100%;
  font-size: 0.75rem;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  .codes-list {
    display: flex;
    max-height: 100%;
    overflow: auto;
    gap: 6px;
    width: 100%;
    flex-flow: wrap;

    & > div {
      align-items: center;
      display: flex;
      border-radius: 2px;
      padding: 0 3px 0 6px;
    }
    .included {
      border: 1px solid #9dbccd;
      background: #d9ebfb;
    }
    .excluded {
      border: 1px solid #cd9d9d;
      background: #fbd9d9;
    }

    .remove-code {
      cursor: pointer;
      margin-top: 1px;
    }
  }
}
</style>
