<template>
  <div>
    <label
      v-for="{ value, label, icon } in panelMode"
      :key="value"
      :title="label"
    >
      <input
        type="radio"
        name="panel-mode"
        :checked="publishedPanelMode == value"
        :value="value"
        @change="({ target }: EventChange) => updatePublishedPanelMode(target?.value)"
      >
      <PsIcon
        :name="icon"
        :size="28"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import {
  useProfile,
  PanelMode,
} from '@/ContextTab/modules/user/composables/useProfile'


const { publishedPanelMode, updatePublishedPanelMode } = useProfile({})

interface PanelModeItem {
  value: PanelMode
  label: string
  icon: string
}


type EventChange = Event & { target: HTMLInputElement }

const panelMode: PanelModeItem[] = [
  {
    value: PanelMode.horizontal,
    label: 'Вертикальный',
    icon: 'panel-layout-vertical',
  },
  {
    value: PanelMode.vertical,
    label: 'Горизонтальный',
    icon: 'panel-layout-horizontal',
  },
]
</script>

<style scoped>
  label:not(:has(:checked)) {
    cursor: pointer;
    --color-white-a: 0.7;
  }

  label {
    color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-white-a));
  }

  input {
    appearance: none;
  }
</style>
