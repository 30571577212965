<template>
  <div
    ref="popoverRef"
    class="pskit__popover"
  >
    <div
      ref="triggerRef"
      class="pskit__popover__trigger"
    >
      <slot />
    </div>
    <Teleport :to="popoverContainer">
      <div
        v-if="isOpen"
        ref="bodyRef"
        v-bind="$attrs"
        :style="floatingStyles"
        class="pskit__popover__body"
      >
        <div
          v-if="props.arrow"
          ref="arrowRef"
          :style="arrowStyle"
          class="pskit__popover__arrow"
        />
        <div class="pskit__popover__content">
          <slot name="body" />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import {
  arrow,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  type CSSProperties,
} from 'vue'
import type { PopoverProps } from './types'


const props = withDefaults(defineProps<PopoverProps>(), {
  placement: undefined,
  offset: 6,
  arrow: true,
  trigger: 'click',
  hideOnClickAway: true,
  padding: 5,
  popoverContainer: '#popovers',
})

const emit = defineEmits<{
  (event: 'clickTrigger'): void
  (event: 'clickOutside'): void
  (event: 'clickBody'): void
  (event: 'show'): void
  (event: 'hide'): void
}>()

defineSlots<{
  default(props?: any): any
  body(props?: any): any
}>()

defineOptions({
  inheritAttrs: false,
})

const popoverRef = ref<HTMLElement | null>(null)
const triggerRef = ref<HTMLElement | null>(null)
const bodyRef = ref<HTMLElement | null>(null)
const arrowRef = ref<HTMLElement | null>(null)

// это внутренний флаг: для управления видимостью используйте open
const isVisible = ref(false)

const isOpen = computed({
  get: () => {
    if (props.trigger === 'manual') {
      return props.open
    }
    return isVisible.value
  },
  set: (val: boolean) => {
    if (props.disabled) {
      return
    }

    if (props.trigger !== 'manual') {
      isVisible.value = val
    }
  },
})

enum staticSides {
  top = 'bottom',
  right = 'left',
  bottom = 'top',
  left = 'right',
}

const middleware = ref([
  offset(props.offset),
  flip(),
  shift({ padding: props.padding }),
  arrow({ element: arrowRef }),
  hide(),
])

const { floatingStyles, update, middlewareData, placement } = useFloating(
  triggerRef,
  bodyRef,
  {
    placement: props.placement,
    middleware,
    open: isOpen,
    whileElementsMounted: (...args) => {
      document.addEventListener('layoutResize', update)
      const autoUpdateCleanup = autoUpdate(...args)
      const cleanup = () => {
        autoUpdateCleanup()
        document.removeEventListener('layoutResize', update)
      }

      return cleanup
    },
  },
)

const arrowStyle = computed<CSSProperties>(() => {
  if (props.arrow && middlewareData.value?.arrow) {
    const side = placement.value.split('-')[0]
    const staticSide = staticSides[side as staticSides] ?? 'top'
    const { x, y } = middlewareData.value.arrow
    const borders = {
      'border-top-width': '0px',
      'border-bottom-width': '0px',
      'border-left-width': '0px',
      'border-right-width': '0px',
    }
    switch (side) {
      case 'bottom':
        borders['border-top-width'] = '1px'
        borders['border-bottom-width'] = '0px'
        borders['border-left-width'] = '1px'
        borders['border-right-width'] = '0px'
        break
      case 'top':
        borders['border-top-width'] = '0px'
        borders['border-bottom-width'] = '1px'
        borders['border-left-width'] = '0px'
        borders['border-right-width'] = '1px'
        break
      case 'right':
        borders['border-top-width'] = '0px'
        borders['border-bottom-width'] = '1px'
        borders['border-left-width'] = '1px'
        borders['border-right-width'] = '0px'
        break
      case 'left':
        borders['border-top-width'] = '1px'
        borders['border-bottom-width'] = '0px'
        borders['border-left-width'] = '0px'
        borders['border-right-width'] = '1px'
        break
    }
    return {
      left: x
        ? `${x}px`
        : '',
      top: y
        ? `${y}px`
        : '',
      [staticSide]: '-5px',
      ...borders,
    }
  }
  return {
    left: '0px',
    top: '0px',
  }
})

function showPopover() {
  if (props.disabled) {
    return
  }
  isOpen.value = true
  emit('show')
}

function hidePopover() {
  isOpen.value = false
  emit('hide')
}

function toggle() {
  if (props.disabled) {
    return
  }

  if (props.trigger === 'manual') {
    if (isOpen.value) {
      hidePopover()
    }
  } else {
    isOpen.value = !isOpen.value
  }
}

onClickOutside(bodyRef, (event) => {
  if (
    triggerRef.value === event.target
    || triggerRef.value?.contains(event.target as Node)
  ) {
    return
  }

  if (bodyRef.value?.contains(event.target as Node)) {
    emit('clickBody')
    return
  }

  if (props.hideOnClickAway) {
    if (props.trigger !== 'manual') {
      hidePopover()
    }
    emit('clickOutside')
  }
})

function onTriggerClick() {
  if (props.disabled) {
    return
  }

  if (props.trigger !== 'manual') {
    toggle()
  }
  emit('clickTrigger')
}

function onMouseLeave(event: MouseEvent) {
  if (triggerRef.value?.contains(event.relatedTarget as Node)) {
    return
  }
  hidePopover()
}

onMounted(() => {
  if (triggerRef.value) {
    if (props.trigger === 'click' || props.trigger === 'manual') {
      triggerRef.value.addEventListener('click', onTriggerClick, {
        passive: true,
        capture: true,
      })
    }

    if (props.trigger === 'hover') {
      triggerRef.value.addEventListener('mouseover', showPopover, {
        capture: true,
        passive: true,
      })
      triggerRef.value.addEventListener('mouseout', onMouseLeave, {
        capture: true,
        passive: true,
      })
      triggerRef.value.addEventListener('focus', showPopover, {
        capture: true,
        passive: true,
      })
      triggerRef.value.addEventListener('blur', hidePopover, {
        capture: true,
        passive: true,
      })
    }
  }
})

onBeforeUnmount(() => {
  if (triggerRef.value) {
    triggerRef.value.removeEventListener('click', onTriggerClick)
    triggerRef.value.removeEventListener('mouseover', showPopover)
    triggerRef.value.removeEventListener('mouseout', onMouseLeave)
    triggerRef.value.removeEventListener('focus', showPopover)
    triggerRef.value.removeEventListener('blur', hidePopover)
  }
})

defineExpose({
  update,
  showPopover,
  hidePopover,
})
</script>

<style>
.pskit__popover__body {
  --pskit__background: var(--popover--background, hsl(210, 17%, 98%));
  --pskit__padding-left: var(--popover--padding-left, 0.5rem);
  --pskit__padding-right: var(--popover--padding-right, 0.5rem);
  --pskit__padding-top: var(--popover--padding-top, 0.5rem);
  --pskit__padding-bottom: var(--popover--padding-bottom, 0.5rem);
  --pskit__-padding: var(--pskit__padding-top) var(--pskit__padding-right)
    var(--pskit__padding-bottom) var(--pskit__padding-left);
  --pskit__padding: var(--popover--padding, var(--pskit__-padding));

  --pskit__border-color: var(--popover--border-color, hsl(196, 11%, 80%));
  --pskit__border-style: solid;

  --pskit__border-radius: var(--popover--border-radius, 4px);
  --pskit__border-width: 1px;
  --pskit__box-shadow: var(
    --popover--box-shadow,
    rgba(0, 0, 0, 0.15) 0px 8px 16px -12px
  );
  --pskit__color: var(--popover--color, hsl(192, 10%, 10%));
  --pskit__font-size: var(--popover--font-size, 1em);
  --pskit__line-height: var(--popover--line-height, 1em);
  --pskit__max-width: var(--popover--max-width, 90vw);
  --pskit__max-height: auto;
  --pskit__height: var(--popover--height, auto);
  --pskit__width: var(--popover--width, auto);

  max-width: calc(100vw - 10px);
  z-index: 10001;

  background: var(--pskit__background);
  border-color: var(--pskit__border-color);
  border-radius: var(--pskit__border-radius);
  border-style: var(--pskit__border-style);
  border-width: var(--pskit__border-width);
  box-shadow: var(--pskit__box-shadow);
  color: var(--pskit__color);
  font-size: var(--pskit__font-size);
  line-height: var(--pskit__line-height);
  padding: var(--pskit__padding);
}
.pskit__popover {
  display: inline-block;
  text-indent: 0;
}
.pskit__popover__trigger {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.pskit__popover__content {
  overflow: hidden;
  z-index: 1001;
  height: var(--pskit__height);
  max-height: var(--pskit__max-height);
  max-width: var(--pskit__max-width);
  width: var(--pskit__width);
  position: relative;
}
.pskit__popover__arrow {
  background: inherit;
  border-width: 0;
  border-color: var(--pskit__border-color);
  border-style: var(--pskit__border-style);
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}
</style>
