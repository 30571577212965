<template>
  <PsButton
    :class="{ inverted: !!color }"
    :disabled="disabled"
    :color="color"
    :size="size"
  >
    <template
      v-if="slots.prefix"
      #prefix
    >
      <slot name="prefix" />
    </template>
    <template v-if="slots.default">
      <slot />
    </template>
    <template
      v-if="slots.suffix"
      #suffix
    >
      <slot name="suffix" />
    </template>
  </PsButton>
</template>

<script setup lang="ts">
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import type { Color } from '@/ContextTab/components/UI/types'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'


export interface Props {
  size?: SizeBreakpointMark
  disabled?: boolean
  color?: Color
}

withDefaults(defineProps<Props>(), {
  size: 'sm',
  disabled: false,
  border: true,
  color: undefined,
})

const slots = defineSlots<{
  default?(props: any): any
  prefix?(props: any): any
  suffix?(props: any): any
}>()
</script>

<style scoped>
.ps-button {
  &:not(.inverted) {
    --button--background: rgba(255, 255, 255, 0.15) !important;
    --button--hover--background: rgba(255, 255, 255, 0.3) !important;
    --button--color: white !important;
    --button--border-style: none !important;
    --button--padding-bottom: 7px !important;
    --button--padding-top: 7px !important;
    --button--padding-left: 10px !important;
    --button--padding-right: 10px !important;
  }

  /*cursor: default !important;*/
}
</style>
