<template>
  <IBadge
    :color="color"
    :size="size"
    class="ps-tag"
    :class="{ '-hoverable': hoverable }"
    v-bind="$attrs"
  >
    <template v-if="slots.prefix">
      <span
        class="ps-tag-prefix"
        :class="{ active: prefixActive }"
        @click="emit('prefixClick')"
      >
        <slot name="prefix" />
      </span>
    </template>
    <span
      class="ps-tag-content"
      :class="{ active: contentActive }"
      @click="emit('contentClick')"
    >
      <slot />
      <span
        v-if="closable"
        class="ps-tag-close"
        @click="emit('close')"
      >
        <PsIcon
          name="close"
          size="8"
        />
      </span>
    </span>
    <template v-if="slots.suffix">
      <span
        class="ps-tag-suffix"
        :class="{ active: suffixActive }"
        @click="emit('suffixClick')"
      >
        <slot name="suffix" />
      </span>
    </template>
  </IBadge>
</template>

<script setup lang="ts">
import { IBadge } from '@inkline/inkline'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import type { Color } from './types'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'


withDefaults(
  defineProps<{
    color?: Color
    size?: SizeBreakpointMark
    hoverable?: boolean
    hoverColor?: string
    closable?: boolean
    contentActive?: boolean
    prefixActive?: boolean
    suffixActive?: boolean
  }>(),
  {
    color: undefined,
    size: 'md',
    hoverable: false,
    hoverColor: undefined,
    closable: false,
    contentActive: false,
    prefixActive: false,
    suffixActive: false,
  },
)

const emit = defineEmits<{
  (e: 'contentClick'): void
  (e: 'prefixClick'): void
  (e: 'suffixClick'): void
  (e: 'close'): void
}>()

const slots = defineSlots<{
  default(props: any): any
  prefix?(props: any): any
  suffix?(props: any): any
}>()
</script>

<style scoped>
.ps-tag {
  --badge--padding: 0;
  --badge--font-weight: var(--font-weight-normal);
  display: flex;
  align-items: stretch;
  text-align: left;

  &.-hoverable {
    cursor: pointer;
  }

  & span {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-wrap: wrap;

    &.active {
      background-color: v-bind(hoverColor);
    }
  }

  .ps-tag-content {
    padding: var(--ps-tag--content-padding);
    line-height: 1.2em;

    &:hover {
      background-color: v-bind(hoverColor);
    }
  }

  .ps-tag-prefix {
    padding-left: var(--ps-tag--prefix-padding-left);
    padding-bottom: var(--ps-tag--prefix-padding-bottom);
    padding-top: var(--ps-tag--prefix-padding-top);

    &:after {
      content: "";
      border-left-width: var(
        --badge--border-width,
        var(--border-right-width),
        1px
      );
      border-left-style: var(
        --badge--border-right-style,
        var(--border-right-style)
      );
      border-left-color: var(--badge--border-color, var(--border-right-color));
      height: 100%;
      padding-right: var(--ps-tag--prefix-padding-left);
    }
    &:hover {
      background-color: v-bind(hoverColor);
    }
  }

  .ps-tag-suffix {
    padding-right: var(--ps-tag--suffix-padding-right);
    padding-bottom: var(--ps-tag--suffix-padding-bottom);
    padding-top: var(--ps-tag--suffix-padding-top);

    &:before {
      content: "";
      border-left-width: var(
        --badge--border-width,
        var(--border-left-width),
        1px
      );
      border-left-style: var(
        --badge--border-left-style,
        var(--border-left-style)
      );
      border-left-color: var(--badge--border-color, var(--border-left-color));
      height: 100%;
      padding-left: var(--ps-tag--suffix-padding-left);
    }

    &:hover {
      background-color: v-bind(hoverColor);
    }
  }

  .ps-tag-close {
    margin-left: 4px;
    padding: 4px;
    border-radius: 2px;
    color: var(--badge--color);
    cursor: pointer;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: var(
        --badge--hover--close-background,
        rgba(0, 0, 0, 0.1)
      );
    }
  }
}
</style>

<style scoped>
.ps-tag {
  /* ##### Content padding ##### */
  --ps-tag--content-padding-left: var(--padding-left-3-4);
  --ps-tag--content-padding-right: var(--padding-right-3-4);
  --ps-tag--content-padding-top: var(--padding-top-1-3);
  --ps-tag--content-padding-bottom: var(--padding-bottom-1-3);

  --ps-tag--content-padding: var(--ps-tag--content-padding-top)
    var(--ps-tag--content-padding-right) var(--ps-tag--content-padding-bottom)
    var(--ps-tag--content-padding-left);

  /* ##### Prefix padding ##### */
  --ps-tag--prefix-padding-left: var(--badge--padding-left);
  --ps-tag--prefix-padding-right: var(--badge--padding-right);
  --ps-tag--prefix-padding-top: var(--badge--padding-top);
  --ps-tag--prefix-padding-bottom: var(--badge--padding-bottom);

  --ps-tag--prefix-padding: var(--ps-tag--prefix-padding-top)
    var(--ps-tag--prefix-padding-right) var(--ps-tag--prefix-padding-bottom)
    var(--ps-tag--prefix-padding-left);

  /* ##### Suffix padding ##### */
  --ps-tag--suffix-padding-left: var(--badge--padding-left);
  --ps-tag--suffix-padding-right: var(--badge--padding-right);
  --ps-tag--suffix-padding-top: var(--badge--padding-top);
  --ps-tag--suffix-padding-bottom: var(--badge--padding-bottom);

  --ps-tag--suffix-padding: var(--ps-tag--suffix-padding-top)
    var(--ps-tag--suffix-padding-right) var(--ps-tag--suffix-padding-bottom)
    var(--ps-tag--suffix-padding-left);
}
</style>
