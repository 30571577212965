export function base64ToUtf(base64: string) {
  const binString = globalThis.atob(base64)
  return new TextDecoder().decode(
    Uint8Array.from(binString, (m) => m.codePointAt(0)!),
  )
}

export function utfToBase64(string: string) {
  const bytes = new TextEncoder().encode(string)
  const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join(
    '',
  )
  return btoa(binString)
}
