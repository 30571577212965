export const LEGACY_PANELS_TAB_DATA = Object.freeze({
  newsItem: {
    favicon: 'editor.png',
    title: 'Редактор',
  },
  awaitingPublication: {
    favicon: 'embargo.png',
    title: 'Эмбарго',
  },
  asoiNotesEmbargo: {
    favicon: 'embargo.png',
    title: 'Эмбарго АСОИ',
  },
  newsCanvas: {
    favicon: 'canvas.png',
    title: 'Холст',
  },
  originals: {
    favicon: 'originals.png',
    title: 'Лента оригиналов',
  },
  disposed: {
    favicon: 'disposed.png',
    title: 'Отвал',
  },
  asoiNotes: {
    favicon: 'disposed.png',
    title: 'Заметки из АСОИ',
  },
  asoiNotesSent: {
    favicon: 'disposed.png',
    title: 'Отправленные в АСОИ',
  },
  search: {
    favicon: 'search.png',
    title: 'Поиск',
  },
  flow: {
    favicon: 'flows.png',
    title: 'Поток',
  },
})
