import { ref, computed, type Ref } from 'vue'


interface InstallationPrompt extends Event {
  userChoice: Promise<{ outcome: string }>
  prompt: () => void
}

class PWAInstallation {
  private _installationPrompt: InstallationPrompt | null = null
  private _installed: Ref<boolean> = ref(true)

  isInstalled: Ref<boolean>

  constructor() {
    this.isInstalled = computed(() => this._installed.value)

    window.addEventListener('beforeinstallprompt', (e: Event) => {
      e.preventDefault()
      this._installationPrompt = e as InstallationPrompt
      this._installed.value = false
    })

    globalThis.addEventListener('appinstalled', () => {
      this._installed.value = true
    })
  }

  async install() {
    if (this._installationPrompt) {
      this._installationPrompt.prompt()
      const { outcome } = await this._installationPrompt.userChoice
      if (outcome === 'accepted') {
        this._installed.value = true
      }
      this._installationPrompt = null
    }
  }
}

export const pwaInstallation = new PWAInstallation()
