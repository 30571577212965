import { escapeRegExp as $escapeRegExp } from 'lodash-es'
import { formatUsername } from '@/utils/formatUsername'
import { translateLayout } from '@/utils/translateLayout'

/**
 * Функция, преобразовывающая строку поиска в массив регулярных
 * выражений, по одному для каждого слова строки поиска. Учитывает ввод
 * с кириллической и латинской раскладок.
 *
 * @param string
 * @returns {Array}
 */

export function searchQueryToRegExps(searchQuery: string): RegExp[] {
  const searchItems = searchQuery.trim().split(' ')
  const regExps = searchItems.map((item) => {
    const query = formatUsername(item)
    const translatedQuery = translateLayout(query)

    // Эскейпим регулярку, чтобы не искать по спец. символам
    let escapedRegex = $escapeRegExp(query)

    // Добавляем к регулярке транслированную раскладку клавиатуры
    escapedRegex += '|' + $escapeRegExp(translatedQuery)
    return new RegExp(escapedRegex, 'gi')
  })

  return regExps
}
