import { computed } from 'vue'
import { useStore } from '@/ContextTab/useStore'
import { ACCESS } from '@/lib/accessCodes'
import type { AuthStore } from '@/ContextApp/stores/auth'


export function useAuthInfo() {
  const store = useStore<AuthStore>('auth', {
    shared: true,
  })

  const userId = computed(() => store.data.tokenData?.sub ?? null)

  const getUserPermissions = computed(
    () => store.data.tokenData?.permissions ?? [],
  )

  const getUserHasSuperUserPermissions = (permissionCodes: string[]) => {
    // на эти коды права суперпользователя не распространяются
    const extraAccessCodes = [
      ACCESS.NEWS.GROUPMATES_NEWS,
      ACCESS.NEWS.EMBARGO_MGT,
      ACCESS.NEWS.REVOKE,
    ]
    return (
      getUserPermissions.value.includes(ACCESS.ADMIN.SUPERUSER)
      && permissionCodes.every((code) => !extraAccessCodes.includes(code))
    )
  }

  const getIsUserHasAllPermissions = (permissionCodes: string[]) =>
    Boolean(getUserPermissions.value)
    && permissionCodes.reduce((acc, value) => {
      if (getUserHasSuperUserPermissions(permissionCodes)) {
        return true
      }

      if (!acc) {
        return false
      }
      return getUserPermissions.value.includes(String(value))
    }, true)

  const getIsUserHasAnyPermission = (permissionCodes: string[]) =>
    Boolean(getUserPermissions.value)
    && permissionCodes.reduce((acc, value) => {
      if (getUserHasSuperUserPermissions(permissionCodes)) {
        return true
      }

      if (acc) {
        return true
      }
      return getUserPermissions.value.includes(String(value))
    }, false)

  return {
    userId,
    getUserPermissions,
    getIsUserHasAllPermissions,
    getIsUserHasAnyPermission,
  }
}
