<template>
  <div
    v-if="codes.length"
    class="list-item-codes"
  >
    <span
      v-for="({ code }, index) in filteredCodes"
      :key="code.id"
      class="code"
    >
      <span class="code__name">{{ nameGetter(code) }}</span>
      <span v-if="index !== filteredCodes.length - 1">{{ delimiter }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'


const props = withDefaults(
  defineProps<{
    codes?: any[]
    codesFilter?: (code: any) => boolean
    preferableGroupIds?: (string | number)[]
    nameGetter?: (code: any) => string
    delimiter?: string
  }>(),
  {
    codes: () => [],
    codesFilter: () => true,
    preferableGroupIds: () => [],
    nameGetter: (code: any) => code.name,
    delimiter: ', ',
  },
)

const sortedCodes = computed(() => {
  if (!props.preferableGroupIds.length) {
    return props.codes
  }

  return [...props.codes].sort((a, b) => {
    const aCategoryIsPreferable = props.preferableGroupIds.includes(
      a.code?.category?.id,
    )
    const bCategoryIsPreferable = props.preferableGroupIds.includes(
      b.code?.category?.id,
    )
    if (aCategoryIsPreferable && !bCategoryIsPreferable) {
      return -1
    }

    if (!aCategoryIsPreferable && bCategoryIsPreferable) {
      return 1
    }
    return 0
  })
})

const filteredCodes = computed(() => {
  return sortedCodes.value.filter((code) => props.codesFilter(code.code))
})
</script>

<style scoped>
.list-item-codes {
  font-size: 13px;
  color: #0681ec;
  text-shadow: 0.7px 0 #ccc;
  width: 100%;
}

.code__name {
  white-space: nowrap;
}
</style>
