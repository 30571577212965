<template>
  <svg
    v-if="type === 'svg'"
    class="ps-icon"
    :style="[cssBase, cssOverride]"
  >
    <use :href="href" />
  </svg>

  <i
    v-else-if="type === 'font'"
    class="ps-icon material-icons"
    :style="[cssBase, cssOverride]"
  >
    {{ name }}
  </i>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { CSSProperties } from 'vue'


type Type = 'svg' | 'font'

const props = withDefaults(
  defineProps<{
    type?: Type
    name: string
    color?: string
    size?: string | number
    css?: string
  }>(),
  {
    type: 'svg',
    color: 'inherit',
    size: 20,
    css: '',
  },
)
const href = computed(() => {
  if (props.type === 'font') {
    return undefined
  }
  return `#${props.name}`
})

const cssBase = computed(() => {
  return {
    // В Firefox `size` всегда строка, просто typeof this.size не поможет
    // Проверяем, что в size только цифры и точка, тогда добавляем `px`
    'font-size': /^(\d*|\.)*$/g.test(String(props.size))
      ? `${props.size}px`
      : props.size,
    'color': props.color,
  }
})

const cssOverride = computed(() => {
  return convertCSS(props.css)
})

const convertCSS = (cssText: string): CSSProperties => {
  const regex = new RegExp(/([\w-]*)\s*:\s*([^;]*)/g)
  let match
  const obj: Record<string, string> = {}
  while ((match = regex.exec(cssText)) !== null) {
    obj[match[1]] = match[2].trim()
  }
  return obj
}
</script>

<style scoped>
.ps-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
</style>
