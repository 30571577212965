import type { Ref } from 'vue'


export function findInputElement(
  el: Ref<HTMLElement | any>,
): HTMLInputElement | null {
  const element = el.value instanceof HTMLElement
    ? el
    : el.value.$el
  if (element instanceof HTMLElement) {
    if (element instanceof HTMLInputElement) {
      return element
    }
    const inputEl = element.querySelector('input')
    if (inputEl) {
      return inputEl
    }
    console.warn('HTML element not found in argument el', el)
  }
  return null
}
