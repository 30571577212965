<template>
  <Collapsible
    v-model="showButtonsPopover"
    collapseOn="xs"
  >
    <template #trigger>
      <PsFilterButton @click.capture.stop.prevent="() => null">
        <template #prefix>
          <PsDropdownIcon
            :open="showButtonsPopover"
            @click.capture.stop.prevent="() => null"
          />
        </template>
      </PsFilterButton>
    </template>
    <PresetsButton
      :open="configuratorOpen"
      :preset="preset"
      @click="onButtonClick('toggle')"
    />
    <ResetButton
      v-if="preset"
      @clear="onButtonClick('clear')"
    />
    <SaveButton
      :preset="preset"
      :disabled="saveDisabled"
      @click="onButtonClick('save')"
    />
    <NewPresetButton
      :disabled="saveDisabled || filtersEmpty"
      :filters="filters"
      @apply="onButtonClick('apply', $event)"
    />
  </Collapsible>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import NewPresetButton from './buttons/NewPreset.vue'
import PresetsButton from './buttons/Presets.vue'
import ResetButton from './buttons/ResetPreset.vue'
import SaveButton from './buttons/SavePreset.vue'
import Collapsible from '@/ContextTab/components/Toolbar/Collapsible.vue'
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PsDropdownIcon from '@/ContextTab/components/UI/PsDropdownIcon.vue'


defineProps<{
  preset: any
  configuratorOpen: boolean
  saveDisabled: boolean
  filtersEmpty: boolean
  filters: any
}>()

const emit = defineEmits<{
  (e: 'toggle'): void
  (e: 'save'): void
  (e: 'apply', value: number | string): void
  (e: 'clear'): void
}>()

const showButtonsPopover = ref(false)

const onButtonClick = (
  type: 'apply' | 'clear' | 'toggle' | 'save',
  event?: any,
) => {
  if (showButtonsPopover.value) {
    showButtonsPopover.value = false
  }
  emit(type, event)
}
</script>
