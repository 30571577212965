<template>
  <TasksPanelListItem
    class="my-list-item"
    :item="item"
    :isSelected="isSelected"
    :loading="loading"
    @dblclick="() => open(true)"
  >
    <template #before-text>
      <NewsAssignBadge
        class="my-list-item__badge"
        :itemId="item.id"
      />
    </template>
    <template #expansion-extra>
      <div
        class="my-list-item__status"
        data-test-selector="my-list-item-status"
      >
        {{ getVersionStatusDescription }}
      </div>
    </template>
    <template #footer>
      <div
        v-if="isSelected"
        class="my-list-item__footer"
      >
        <PsButton
          :title="
            isLegacyRoute
              ? 'Открыть в отдельной панели'
              : 'Открыть в новой вкладке'
          "
          circle
          :border="false"
          class="action-button"
          @click="() => open(false)"
        >
          <PsIcon
            name="button-icon-show-more"
            :size="24"
          />
        </PsButton>
      </div>
    </template>
  </TasksPanelListItem>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import TasksPanelListItem from '../components/TasksPanelListItem.vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import NewsAssignBadge from '@/ContextTab/modules/news/tasks-panel/components/NewsAssignBadge.vue'
import { useNewsItem } from '@/ContextTab/modules/news/composables/useNewsItem'
import { useNewsItemVersion } from '@/ContextTab/modules/news/composables/useNewsItemVersion'


const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    isLegacyRoute: boolean
    loading: boolean
  }>(),
  {
    isSelected: false,
  },
)
const emit = defineEmits<{
  (e: 'open', id: number): void
  (e: 'pick', id: number): void
}>()

const { newsItemIsReadyToEdit } = useNewsItem(toRef(() => props.item.id))

const { getVersionStatusDescription } = useNewsItemVersion(
  toRef(() => props.item.id),
)

const open = (handleDblClick = false) => {
  emit('open', props.item.id)

  if (handleDblClick && newsItemIsReadyToEdit.value) {
    emit('pick', props.item.id)
  }
}
</script>

<style lang="postcss" scoped>
.my-list-item__footer .action-button:not(:disabled, .-disabled):hover {
  --button--color: #bd985c;
}
.my-list-item__status {
  color: var(--color-gray-300);
  font-size: 12px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.my-list-item__badge {
  float: left;
}
</style>
