import { v1 as uuidv1 } from 'uuid'
import { MessageHubClient } from '@/lib/messageHub/client'


// TODO: generate it in /main.ts (now it need refactoring)
// used for MessageHubClient and in legacyApp
window.contextId = uuidv1()

export const messageHub = new MessageHubClient({
  contextId: window.contextId,
})
