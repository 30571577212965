<template>
  <div
    ref="root"
    class="virtual-list"
  >
    <div
      ref="beforeLoadingTrigger"
      class="virtual-list__top-trigger"
    />

    <div
      class="virtual-list__container"
      :style="containerStyle"
    >
      <div
        class="virtual-list__items"
        :style="itemsStyle"
      >
        <div
          v-for="virtualItem in virtualRows"
          :ref="measureElement"
          :key="virtualItem.index"
          :data-index="virtualItem.index"
        >
          <slot
            name="item"
            v-bind="{ index: virtualItem.index }"
          />
        </div>
      </div>
    </div>

    <div ref="afterLoadingTrigger">
      <slot name="after" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef, watch, nextTick } from 'vue'
import { useEventListener, useElementVisibility } from '@vueuse/core'
import { useVirtualizer } from '@tanstack/vue-virtual'


const props = withDefaults(
  defineProps<{
    count: number
    hasMoreItems?: boolean
    overscan?: number
    estimateSize?: number
    isReversed?: boolean
  }>(),
  {
    hasMoreItems: false,
    overscan: 10,
    estimateSize: 100,
    isReversed: false,
  },
)

const emit = defineEmits<{
  (e: 'reachedStart', isVisible: boolean): void
  (e: 'loadMore'): void
}>()

const root = useTemplateRef<HTMLDivElement>('root')
const beforeLoadingTrigger = useTemplateRef<HTMLDivElement>('beforeLoadingTrigger')
const afterLoadingTrigger = useTemplateRef<HTMLDivElement>('afterLoadingTrigger')
const isBeforeVisible = useElementVisibility(beforeLoadingTrigger)
const isAfterVisible = useElementVisibility(afterLoadingTrigger, {
  scrollTarget: root,
  rootMargin: '0px 0px 200% 0px',
})
const virtualizer = useVirtualizer(computed(() => ({
  count: props.count,
  overscan: props.overscan,
  getScrollElement: () => root.value,
  estimateSize: () => props.estimateSize,
  isScrollingResetDelay: 80,
  useScrollendEvent: false,
  enable: true,
  catchScrollErrors: true,
})))

const virtualRows = computed(() => virtualizer.value.getVirtualItems())
const totalSize = computed(() => virtualizer.value.getTotalSize())

const containerStyle = computed(() => ({
  height: `${totalSize.value}px`,
  pointerEvents: virtualizer.value.isScrolling ? 'none' : 'auto',
}))

const itemsStyle = computed(() => ({
  transform: `translateY(${virtualRows.value[0]?.start ?? 0}px)`,
}))

const measureElement = (el) => {
  // setTimeout fixes virtualizer's issue
  // https://github.com/TanStack/virtual/issues/659?ysclid=m5w8hpk1m2509176583
  setTimeout(() => {
    virtualizer.value.measureElement(el)
  })
}

// handle resize observer errors... https://github.com/TanStack/virtual/issues/531
useEventListener(window, 'error', (e) => {
  if (e.message == 'ResizeObserver loop completed with undelivered notifications.') {
    e.preventDefault()
  }
})

watch(
  [isBeforeVisible, isAfterVisible],
  ([isBeforeVisible, isAfterVisible], [oldIsBeforeVisible]) => {
    const { isReversed, hasMoreItems } = props

    if (
      isBeforeVisible != oldIsBeforeVisible
      && (
        !isReversed
        || (isReversed && !hasMoreItems)
      )
    ) {
      emit('reachedStart', isBeforeVisible)
    }

    if (!hasMoreItems) {
      return
    }

    if ((isBeforeVisible && isReversed) || (isAfterVisible && !isReversed)) {
      emit('loadMore')
    }
  },
)

const scrollToOffset = (offset = 0) => {
  const vitrualizerTotalHeight = virtualizer.value.getTotalSize()

  nextTick(() => {
    virtualizer.value.scrollOffset = vitrualizerTotalHeight - offset
    virtualizer.value?.scrollToOffset(virtualizer.value.scrollOffset)
  })
}

// При изменении count при isReversed=true скролим на разницу высот
watch(() => props.count, () => {
  if (props.isReversed) {
    scrollToOffset(root.value?.scrollHeight ?? 0)
  }
})

// При isReversed=true скролим к последнему элементу
watch(() => props.isReversed, (isReversed) => {
  if (isReversed) {
    scrollToOffset()
  }
}, { immediate: true })

defineExpose(virtualizer.value) // expose all methods
</script>

<style scoped>
.virtual-list {
  height: 100%;
  overflow: auto;
  overflow-anchor: none;
  contain: strict;
  will-change: scroll-position;
}
.virtual-list__top-trigger {
  height: 1px;
}
.virtual-list__items {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
