import {
  ref,
  onBeforeUnmount,
  reactive,
  readonly,
  type Ref,
  type Reactive,
  type UnwrapRef,
} from 'vue'
import { messageHub } from '@/ContextTab/services/messageHubClient'
import { commandTypes, messageTypes } from '@/lib/messageHub/messages'
import { tabMetrics } from '@/ContextTab/services/tabMetrics'
import type {
  ExtractStoreData,
  ExtractStoreFunctions,
} from '@/utils/typeUtils'


type StoreOptions<T extends object> = {
  shared?: boolean
  onStart?: (store: ClientStore<T>, ...args: any[]) => any
}

type StoreData<T> = ExtractStoreData<T>

type ClientStoreNotReady<StoreT extends object> = {
  data: Partial<UnwrapRef<StoreData<StoreT>>>
  fetching: Ref<boolean>
  created: Ref<boolean>
  ready: PromiseLike<boolean>
  storeId: Ref<string>
  destruct: () => void
} & Partial<ExtractStoreFunctions<StoreT>>

export type ClientStore<StoreT extends object> = ClientStoreNotReady<StoreT>
  & { data: Required<ClientStoreNotReady<StoreT>['data']> }
  & Required<ExtractStoreFunctions<StoreT>>

const contextStores: Record<
  string,
  ClientStore<object> | ClientStoreNotReady<object>
> = {}

const contextStoresCount: Record<string, number> = {}
const sharedStores: Record<
  string,
  ClientStore<object> | ClientStoreNotReady<object>
> = {}

export function useStore<StoreT extends object>(
  name: string,
  { shared = false, onStart }: StoreOptions<StoreT> = {},
): ClientStoreNotReady<StoreT> {
  const storeId = ref<string>(shared
    ? name
    : '')

  const internalData = reactive<StoreData<StoreT>>({} as StoreData<StoreT>)
  const data = readonly<Reactive<StoreData<StoreT>>>(internalData)

  if (shared) {
    if (sharedStores[name]) {
      return sharedStores[name] as ClientStoreNotReady<StoreT>
    }
  } else {
    if (contextStores[name]) {
      contextStoresCount[name] += 1
      return contextStores[name] as ClientStoreNotReady<StoreT>
    }
    contextStoresCount[name] = 1
  }

  function deleteStore() {
    if (shared) {
      return
    }
    messageHub.removeMessageListener(updateUuid)
    delete contextStores[name]

    messageHub.sendCommand(
      {
        commandType: commandTypes.store,
        signal: 'delete',
      },
      storeId.value,
    )
  }

  function destruct() {
    if (shared) {
      return
    }
    contextStoresCount[name] -= 1
    if (contextStoresCount[name] === 0) {
      deleteStore()
    }
  }

  const store: Record<string, any> = {
    storeId,
    data,
    ready: Promise.resolve(true),
    created: ref(false),
    fetching: ref(true),
    destruct,
  }

  async function dispatch(action: string, ...args: any[]) {
    store.fetching.value = true
    const result = await messageHub.sendCommand(
      {
        commandType: commandTypes.store,
        signal: 'dispatch',
      },
      {
        storeId: storeId.value,
        action,
        args,
      },
    )
    store.fetching.value = false
    return result
  }

  const updateUuid = messageHub.addMessageListener({
    metaFilter: (meta) =>
      messageTypes.byId(meta.typeId).name === messageTypes.names.data
      && meta.storeId === storeId.value,
    callback: (event) => {
      if (event.data.payload?.data) {
        Object.assign(internalData, event.data.payload.data)
      } else {
        Object.assign(internalData, event.data.payload)
      }
    },
  })

  store.ready = messageHub
    .sendCommand(
      {
        commandType: commandTypes.store,
        signal: 'create',
      },
      {
        name,
        shared,
      },
    )
    .then((created) => {
      storeId.value = created.payload.storeId
      Object.assign(internalData, created.payload.data)

      created.payload.actions.forEach((name: string) => {
        store[name] = (...args: unknown[]) => dispatch(name, ...args)
      })

      if (contextStoresCount[name] === 1) {
        globalThis.addEventListener('beforeunload', deleteStore)
      }

      store.created.value = true
      if (onStart) {
        onStart(store as ClientStore<StoreT>)
      }
      return Promise.resolve(true)
    })
    .catch((error) => {
      tabMetrics.sendException(`Store mirror "${name}" creation failed`, error)
      return Promise.resolve(false)
    })

  onBeforeUnmount(() => {
    destruct()
  })

  if (shared) {
    sharedStores[name] = store as ClientStoreNotReady<object>
  } else {
    contextStores[name] = store as ClientStoreNotReady<object>
  }

  return store as ClientStoreNotReady<StoreT>
}

export async function useStoreAsync<StoreT extends object>(
  name: string,
  { shared = false }: StoreOptions<StoreT> = {},
) {
  const store = useStore<StoreT>(name, { shared })
  await store.ready
  return store as ClientStore<StoreT>
}
