<template>
  <PsFilterButton
    tooltip="Скрыть повторы"
    name="Скрыть повторы"
    size="sm"
    @click="emit('click')"
  >
    <template #prefix>
      <PsCheckbox
        :checked="checked"
        checkedValue="included"
        uncheckedValue="unchecked"
      />
    </template>
    <template #xs />
    <template #xl>
      Скрыть повторы
    </template>
  </PsFilterButton>
</template>

<script setup lang="ts">
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PsCheckbox from '@/ContextTab/components/UI/PsCheckbox.vue'


defineProps<{
  checked: string
}>()

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>
