<template>
  <div
    class="news-item"
    :class="{
      loading: loading,
      selected: isSelected,
      flash: isVersionOfTypeFlash,
      express: isVersionOfTypeExpress,
    }"
    @dblclick="emit('dblclick')"
  >
    <slot name="header">
      <div class="news-item__header">
        <span
          v-if="showReadStatus"
          class="news-item__status"
        >
          <i v-show="!newsItemIsRead">●</i>
        </span>
        <PsNewsTypeButton
          :typeId="item.version.type_id"
          :disabled="true"
        />
        <span
          class="news-item__date"
          data-test-selector="date"
        >
          <DateWidget
            :date="item.status_modified_at"
          />
        </span>
        <label
          v-show="embargoExpired"
          title="Вышло время публикации"
          class="news-item__warning-icon"
        >
          <PsIcon
            name="warning"
            :size="18"
          />
        </label>
      </div>
    </slot>
    <slot name="body">
      <div class="news-item__text">
        <b v-if="textHeader">
          <slot
            name="before-text"
            :item="item"
          />
          <span v-html="text" />
        </b>
        <span v-else-if="textBody">
          <slot
            name="before-text"
            :item="item"
          />
          <span v-html="text" />
        </span>
        <span
          v-else
          class="-no-text"
        >
          <slot
            name="before-text"
            :item="item"
          />{{ text }}
        </span>
      </div>
    </slot>
    <slot name="expansion">
      <span
        v-if="showEmbargoInfo"
        class="news-item__embargo"
      >
        Будет опубликована&nbsp;
        <EmbargoBadge
          :date="version.publish_at"
          :postponed="version.is_postponed"
        />
      </span>
      <span
        class="news-item__authors"
        data-test-selector="authors"
      >
        {{ authors }}
      </span>
      <slot name="expansion-extra" />
    </slot>
    <div
      v-if="slots.footer"
      class="news-item__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import DOMPurify from 'dompurify'
import { computed, useSlots, toRef } from 'vue'
import DateWidget from '@/ContextTab/components/Date/DateWidget.vue'
import EmbargoBadge from '@/ContextTab/components/Date/EmbargoBadge.vue'
import PsNewsTypeButton from '@/ContextTab/components/UI/Button/PsNewsTypeButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { useNewsItem } from '@/ContextTab/modules/news/composables/useNewsItem'
import { useNewsItemVersion } from '@/ContextTab/modules/news/composables/useNewsItemVersion'
import { formatUsername } from '@/utils/formatUsername'
import { NEWS_STATUSES_BY_NAME } from '@/lib/referencesByName'


const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    showReadStatus?: boolean
    loading: boolean
  }>(),
  {
    isSelected: false,
    showReadStatus: false,
  },
)

const emit = defineEmits<{
  (e: 'dblclick'): void
}>()

const slots = useSlots()

const { newsItemIsRead, newsItemIsScheduled, newsItemIsExpired } = useNewsItem(
  toRef(() => props.item.id),
)
const {
  version,
  getVersionAuthorsNames,
  isVersionOfTypeFlash,
  isVersionOfTypeExpress,
} = useNewsItemVersion(toRef(() => props.item.id))

const textHeader = computed(() => {
  if (
    props.item.version.headline
  ) {
    return DOMPurify.sanitize(props.item.version.headline, { ALLOWED_TAGS: ['p'] }).slice(0, 140)
  }
  return null
})

const textBody = computed(() => {
  if (props.item.version.body) {
    return DOMPurify.sanitize(props.item.version.body, { ALLOWED_TAGS: ['p'] }).slice(0, 120)
  }
  return null
})

const text = computed(() => {
  if (textHeader.value) {
    return textHeader.value
  } else if (textBody.value) {
    return textBody.value
  } else {
    return 'Пустая новость'
  }
})

const embargoExpired = computed(() => {
  const ignoredStatusIds = [
    NEWS_STATUSES_BY_NAME['PUBLISHED'].id,
    NEWS_STATUSES_BY_NAME['DISPOSED'].id,
  ]

  return (
    !ignoredStatusIds.includes(version.value?.status_id)
    && newsItemIsExpired.value
  )
})

const showEmbargoInfo = computed(() => {
  const ignoredStatusIds = [
    NEWS_STATUSES_BY_NAME['PUBLISHED'].id,
    NEWS_STATUSES_BY_NAME['DISPOSED'].id,
  ]
  return (
    !ignoredStatusIds.includes(version.value?.status_id)
    && newsItemIsScheduled.value
  )
})

const authors = computed(() => {
  const statusIds = [
    NEWS_STATUSES_BY_NAME['WAITS_FOR_PUBLICATION'].id,
    NEWS_STATUSES_BY_NAME['PUBLISHED'].id,
  ]
  const authorsList = getVersionAuthorsNames.value
  let authorsFormatted = authorsList.join(', ')

  if (
    statusIds.includes(props.item.version.status_id)
    && props.item.publisher
  ) {
    authorsFormatted
      = formatUsername(props.item.publisher.name) + ' / ' + authorsFormatted
  } else {
    authorsFormatted
      = (authorsList.length > 1
        ? 'Авторы: '
        : 'Автор: ') + authorsFormatted
  }

  return authorsFormatted
})
</script>

<style scoped>
.news-item {
  padding: 0.5rem 1rem 3rem;
  border-bottom: 1px solid var(--color-gray-200);

  &.loading {
    pointer-events: none;
    opacity: 0.75;
  }

  &.selected {
    --button--color: #bd985c;
    --button--hover--background: #f3e4ca;

    background-color: #fff6e6;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #f4b650;
    }
  }

  .news-item__header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0.4rem;
    color: var(--color-gray-300);
    font-size: 12px;
  }

  .news-item__warning-icon {
    margin-left: auto;
    color: var(--color-danger-tint-50);
  }

  .news-item__status {
    position: absolute;
    left: -0.7rem;
    font-size: 14px;
  }

  .news-item__date {
    margin-left: 2px;
  }

  .news-item__text {
    margin-bottom: 0.4rem;
    line-height: 20px;
    overflow-wrap: break-word;

    .-no-text {
      display: flex;
      font-style: italic;
      text-align: center;
      color: var(--color-gray-shade-150);
      height: 100%;
      align-items: center;
    }
  }

  .news-item__authors,
  .news-item__embargo {
    color: var(--color-gray-300);
    font-size: 12px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .news-item__footer {
    position: absolute;
    right: 1rem;
  }

  &.flash {
    & .news-item__text {
      color: var(--color-danger-shade-150);
      & span {
        &:not(.-no-text) {
          text-transform: uppercase;
        }
      }
    }
  }

  &.express {
    & .news-item__text {
      color: var(--color-danger-shade-150);
    }
  }
}
</style>
