import { computed, watch, type Ref } from 'vue'
import { type Message } from '@/ContextTab/components/UI/WithInplaceMessage/types'
import { useStore } from '@/ContextTab/useStore'
import { legacyAppHandler } from '@/ContextTab/modules/legacy/appHandler'
import type { ShareNewsStore } from '@/ContextApp/stores/shareNews'
import type { ShareManagerState } from '@/lib/types'


export function useShareModal() {
  const store = useStore<ShareNewsStore>('shareNews')

  const shareModalVisible = computed(
    () => store.data.shareModalVisible ?? false,
  )

  const modalState = computed(() => store.data.modalState as ShareManagerState)

  const message: Ref<Message | null> = computed(() => {
    return store.data.message ?? null
  })

  const shareDispatcher = async (payload: any) => {
    if (!modalState.value.itemId) {
      return
    }

    let response
    switch (modalState.value.actionType) {
      case 'sendToEdit':
        response = await store.send?.(payload, modalState.value.itemId)
        break
      case 'sendToAsoi':
        response = await store.sendToAsoi?.(payload, modalState.value.itemId)
        break
    }
    return response
  }

  watch(shareModalVisible, (isVisible) => {
    // pass open share modal flag back to legacy to stop switching panels
    legacyAppHandler.app?.$store.commit('MODAL_SET', isVisible)
  })

  return {
    shareModalVisible,
    modalState,
    showShareModal: (state: ShareManagerState) => store.showShareModal?.(state),
    hideShareModal: () => store.hideShareModal?.(),
    shareDispatcher,
    message,
  }
}
