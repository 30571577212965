<template>
  <template v-if="!item.is_revoked">
    <PsResponsiveButton
      v-if="getIsUserHasAllPermissions([ACCESS.NEWS.REPUBLISH])"
      size="sm"
      tooltip="Повтор"
      @click="emit('republish', item.id)"
    >
      <template #prefix>
        <PbIcon name="republish" />
      </template>
      <template #lg>
        Повтор
      </template>
    </PsResponsiveButton>
    <PsResponsiveButton
      v-if="getIsUserHasAllPermissions([ACCESS.NEWS.EXTENSION])"
      size="sm"
      tooltip="Расширить"
      @click="emit('extend', item.id)"
    >
      <template #prefix>
        <PbIcon name="extension" />
      </template>
      <template #lg>
        Расширить
      </template>
    </PsResponsiveButton>
    <PsResponsiveButton
      v-if="getIsUserHasAllPermissions([ACCESS.NEWS.REPUBLISH])"
      size="sm"
      tooltip="Добавить коды"
      @click="emit('toggleCodesPopup', !showCodesPopup)"
    >
      <template #prefix>
        <PbIcon name="plus" />
      </template>
      <template #md>
        Добавить коды
      </template>
    </PsResponsiveButton>
  </template>
  <template v-if="showCodesPopup">
    <Configurator
      top="3.7rem"
      bottom="0"
      :height="panelMode === 2 ? '75%' : undefined"
      :useTeleport="panelMode === 2"
      teleportTo="#popups-preview-horizontal"
      @submit="() => null"
      @cancel="emit('toggleCodesPopup', false)"
    >
      <NewsCodes
        :omit="newsItemCodeIds"
        profileName="PUBLISH_NEWS"
        :lang="newsLanguage?.name"
        @update="onCheckedUpdate"
      />
      <template #footer>
        <PsSubmitGroup
          @submit="onSubmit"
          @cancel="emit('toggleCodesPopup', false)"
        />
      </template>
    </Configurator>
  </template>

  <PsResponsiveButton
    size="sm"
    tooltip="Отправить"
    @click="emit('share', { itemId: props.item.id, actionType: 'sendToEdit', hasKeepOriginal: false })"
  >
    <template #prefix>
      <PbIcon
        name="send"
        size="18"
      />
    </template>
    <template #md>
      Отправить
    </template>
  </PsResponsiveButton>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import Configurator from '@/ContextTab/components/Configurator/Configurator.vue'
import PsSubmitGroup from '@/ContextTab/components/PsSubmitGroup.vue'
import PsResponsiveButton from '@/ContextTab/components/UI/Button/PsResponsiveButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'
import NewsCodes from '@/ContextTab/modules/news/codes/components/NewsCodes.vue'
import { ACCESS } from '@/lib/accessCodes'
import { useAuthInfo } from '@/ContextTab/modules/auth/composables/useAuthInfo'
import type { PsApiV2 } from 'ps-client'
import type { ShareManagerState } from '@/lib/types'


const props = defineProps<{
  item: any
  newsLanguage: any
  showCodesPopup: boolean
  panelMode: PsApiV2.PublishedPanelMode
}>()
const emit = defineEmits<{
  (e: 'republish', id: number): void
  (e: 'extend', id: number): void
  (e: 'toggleCodesPopup', value: boolean): void
  (e: 'submit', value: string[]): void
  (e: 'share', value: ShareManagerState)
}>()

const { getIsUserHasAllPermissions } = useAuthInfo()

const newsItemCodeIds = computed(
  () => props.item?.version?.codes?.map(({ code }) => code.id),
)

type CheckedState = { [key: string]: any }
const checkedState = reactive<CheckedState>({})

const onCheckedUpdate = (state: CheckedState) => {
  Object.assign(checkedState, state)
}

const onSubmit = () => {
  const ids = Object.keys(checkedState).filter(
    (codeId) => checkedState[codeId]?.state === 'included',
  )
  emit('toggleCodesPopup', false)
  emit('submit', ids)
}

const resetState = () => {
  emit('toggleCodesPopup', false)
  Object.keys(checkedState).forEach((key: string) => {
    delete checkedState[key]
  })
}

watch(
  () => props.item,
  (next, prev) => {
    if (next?.id !== prev?.id) {
      resetState()
    }
  },
)
</script>

<style scoped>
.horizontal {
  box-sizing: border-box;
  max-height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 2.8rem;
}
</style>
