<template>
  <div class="ps-main-menu">
    <header>
      <PsButton
        :circle="true"
        :border="false"
        size="sm"
        class="close-button"
        @click="emit('close')"
      >
        <PsIcon
          name="close"
          :size="14"
        />
      </PsButton>
    </header>
    <div class="global-info">
      <PsIcon
        name="logo"
        :size="55"
      />
      <h2 class="brand-name">
        Интерфакс
      </h2>
      <h4 class="brand-app">
        Издательская система
      </h4>
      <div class="resources-links">
        <a
          :href="DOCS_PAGE"
          target="_blank"
          @click="onResourceLinkClick"
        >База знаний</a>
        <a
          :href="entitiesPage"
          target="_blank"
          @click="onResourceLinkClick"
        >Список сущностей</a>
      </div>
      <div class="version">
        Версия приложения: {{ uiVersion }}
      </div>
      <div class="version">
        Версия воркера: {{ workerVersion }}
      </div>
      <div
        v-if="uiVersion !== workerVersion"
        class="update-required"
      >
        ⚠ Требуется обновление
      </div>
      <PsButton
        v-if="!installed"
        :bordered="false"
        :block="true"
        class="global-action _margin-top:1"
        @click="install"
      >
        Установить приложение
      </PsButton>
    </div>
    <div class="user-info">
      <h4 class="user-name">
        {{ name }}
      </h4>
      <h5 class="user-asoi">
        {{ code }}
      </h5>
      <PsButton
        class="global-action"
        color="dark"
        :border="false"
        :block="true"
        @click="emit('logout')"
      >
        <b>Выход</b>
      </PsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { VERSION, DOCS_PAGE } from '@/config'
import { useProfile } from '@/ContextTab/modules/user/composables/useProfile'
import { pwaInstallation } from '@/ContextTab/services/pwaInstallation'
import { useStore } from '@/ContextTab/useStore'
import type { InitStore } from '@/ContextApp/stores/init'


const emit = defineEmits<{
  (e: 'close'): void
  (e: 'logout'): void
}>()

const initStore = useStore<InitStore>('init', {
  shared: true,
})

const { name, code } = useProfile({})

const installed = computed(() => pwaInstallation.isInstalled.value)

const uiVersion = computed(() => VERSION)
const workerVersion = computed(
  () => initStore.data.workerVersion ?? '< 1.14.2 ⚠',
)

const entitiesPage = `${globalThis.location.protocol}//${globalThis.location.host}/legacy/entities`
const onResourceLinkClick = () => {
  emit('close')
}

async function install() {
  await pwaInstallation.install()
  emit('close')
}
</script>

<style scoped>
.ps-main-menu {
  box-sizing: border-box;
  color: var(--color-gray-400);
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;

  header {
    margin-bottom: -1rem;
  }

  .brand-name {
    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
  }

  .update-required {
    margin-top: 1rem;
    color: #f4b650;
  }

  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  .user-info {
    margin-top: auto;
  }

  .resources-links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1.5rem 0 2rem;
    font-size: 1.2rem;

    a {
      color: var(--color-gray-400);
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}
</style>
