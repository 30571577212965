import { type Ref } from 'vue'
import { useStore } from '@/ContextTab/useStore'
import { openedLegacyTabs } from '@/LegacyApp/src/services/openedLegacyTabs'
import type { Message } from '@/ContextTab/components/UI/WithInplaceMessage/types'
import type { NewsStore } from '@/ContextApp/stores/news'

// It handles news operations, that cause opening news panel at legacy
// and opens new window if there's no active editor tabs.
// Every operation calls legacyBridge in useNewsItemActions.ts
export function useNewsActions(
  loading: Ref<boolean>,
  message: Ref<Message | null>,
) {
  const store = useStore<NewsStore>('news', { shared: true })

  const action = async (newsOperation: any, id: string | number) => {
    if (store.created) {
      loading.value = true
      try {
        const result = await newsOperation(id, window.contextId)
        message.value = result.payload.message || null

        if (result?.payload?.response?.data?.id) {
          if (!openedLegacyTabs.hasEditorTabs()) {
            window.open(
              `${window.location.origin}/legacy/news/${result?.payload?.response?.data?.id}`,
            )
          }
        }
      } finally {
        loading.value = false
      }
    }
  }

  // all these actions cause sending `openNewsItem` event to legacyBridge
  // after check through browser extension (see `focus`), only one tab really opens panel
  const extend = (id: string | number) => action(store.extend, id)
  const republish = (id: string | number) => action(store.republish, id)
  const revoke = (id: string | number) => action(store.revoke, id)
  const open = (id: string | number) => action(store.open, id)

  return {
    extend,
    republish,
    revoke,
    open,
  }
}
