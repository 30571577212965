<template>
  <span
    class="embargo-badge"
    :class="{ '-no-style': notStyled }"
    :title="formattedTitle"
  >
    <span class="embargo-badge__text">
      {{ postponed ? "Эмбарго до звонка" : formattedDate }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { isThisYear, dateTimeFormat } from '@/utils/dates'


const props = withDefaults(
  defineProps<{
    date?: string | null
    postponed?: boolean
    notStyled?: boolean
  }>(),
  {
    date: null,
    postponed: false,
    notStyled: false,
  },
)

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  timeZone: 'Europe/Moscow',
}

const dateParsed = computed(() => {
  return props.date
    ? new Date(props.date)
    : null
})

const formattedDate = computed(() => {
  if (!dateParsed.value) {
    return ''
  }

  return dateTimeFormat(dateParsed.value, {
    year: !isThisYear(dateParsed.value)
      ? 'numeric'
      : undefined,
    ...dateTimeFormatOptions,
  })
})

const formattedTitle = computed(() => {
  if (props.postponed) {
    return 'Эмбарго до звонка'
  } else if (!dateParsed.value) {
    return ''
  }
  return 'Будет опубликована ' + dateTimeFormat(dateParsed.value, dateTimeFormatOptions)
})
</script>

<style scoped>
.embargo-badge {
  display: inline-block;
  height: 1rem;
  margin: 0 2px;
  padding: 0 4px;
  line-height: 1rem;
  font-size: 12px;
  white-space: nowrap;
  background: var(--color-danger-50);
  color: var(--color-danger-shade-150);
  border-radius: 2px;

  &.-no-style {
    margin: 0;
    padding: 0;
    background: none;
    color: inherit;
  }
}
</style>
