import * as sentryVue from '@sentry/vue'
import * as sentryBrowser from '@sentry/browser'
import { httpClientIntegration, extraErrorDataIntegration } from '@sentry/integrations'
import { SENTRY_DSN, VERSION, getCurrentEnv } from '@/config'
import type { User, SeverityLevel } from '@sentry/types'
import type { Breadcrumb } from '@sentry/types/types/breadcrumb'
import type { App } from 'vue'


export class Metrics {
  protected _userData: User = {
    id: undefined,
    name: undefined,
    email: undefined,
  }

  sentry: typeof sentryVue | typeof sentryBrowser | null = null

  init(app?: App) {
    const commonConfig = {
      dsn: SENTRY_DSN,
      debug: false,
      enabled: ['production', 'testing', 'stand'].includes(getCurrentEnv()),
      release: VERSION,
      environment: getCurrentEnv(),
      normalizeDepth: 8,
    }

    if (app) {
      this.sentry = sentryVue
      this.sentry.init({
        app,
        integrations: [
          extraErrorDataIntegration(),
        ],
        ...commonConfig,
      })
    } else {
      this.sentry = sentryBrowser
      this.sentry.init({
        integrations: [
          httpClientIntegration(),
          extraErrorDataIntegration(),
        ],
        ...commonConfig,
      })
    }
  }

  setUser(userData: User) {
    this._userData = {
      ...this._userData,
      ...userData,
    }
    this.sentry?.setUser({ ...this._userData, username: this._userData.name })
  }

  sendMessage(message: string, level: SeverityLevel = 'info') {
    this.sentry?.captureMessage(message, level)
    console.warn('🛈 Sentry:', message)
  }

  addBreadCrumb(messageOrBreadcrumb: string | Breadcrumb, category?: string) {
    const breadCrumb: Breadcrumb = {
      category: 'info',
      message: '',
      level: 'info',
      timestamp: Date.now() / 1000,
    }

    if (typeof messageOrBreadcrumb === 'string') {
      category = category ?? breadCrumb.category
      Object.assign(breadCrumb, { message: messageOrBreadcrumb, category })
    } else {
      Object.assign(breadCrumb, messageOrBreadcrumb)
    }

    this.sentry?.addBreadcrumb(breadCrumb)

    if (import.meta.env.DEV) {
      console.log('🛈 Sentry:', breadCrumb.message)
    }
  }

  sendException(errorOrMessage: string | Error, extraData?: any) {
    const error = errorOrMessage instanceof Error
      ? errorOrMessage
      : new Error(errorOrMessage)

    if (extraData) {
      if (extraData instanceof Error) {
        error.cause = extraData
      } else {
        (error as any).extraData = extraData
      }
    }

    this.sentry?.captureException(error)
    console.error('⚠ Sentry:', error)
  }
}
