<template>
  <div
    class="tree-item__wrapper"
    :class="{ opened, disabled: item.disabled }"
    @click="onClick"
  >
    <PsIcon
      v-if="item?.children?.length"
      name="tree-arrow"
      :size="11"
      class="tree-arrow"
    />
    <ColorPicker
      v-model:show="show"
      :appliedColor="appliedColor"
      :codeHasChildren="codeHasChildren"
      @submit="applyColor"
    >
      <template #trigger>
        <div
          class="tree-item"
          :class="`tree-item_${type}`"
        >
          <span
            class="tree-item__name"
            @click="show = true"
          >{{
            item.name
          }}</span>
          <span
            v-if="appliedColor"
            class="tree-item__indicator"
            :style="{ backgroundColor: appliedColor.color }"
          />
        </div>
      </template>
    </ColorPicker>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import ColorPicker from './ColorPicker.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import type { CodeFilterTypes } from '@/ContextTab/modules/news/codes/types'


const props = withDefaults(
  defineProps<{
    item: any
    type: CodeFilterTypes
    opened: boolean | number
    colors?: any
  }>(),
  {
    colors: () => ({}),
  },
)

const emit = defineEmits<{
  (e: 'applyColor', value: {
    color: string | null
    code: number
    includeChildren: boolean
  }): void
}>()

const show = ref(false)

const codeHasChildren = computed(() => {
  return !!props.item.children.length
})

const appliedColor = computed(() => {
  let color = props.colors[props.item.id]

  if (color) {
    return color
  }

  color = [...props.item.track].reverse().reduce((acc, curr) => {
    if (acc !== null) {
      return acc
    }

    if (props.colors[curr] && props.colors[curr].includeChildren) {
      return props.colors[curr]
    }
    return null
  }, null)

  return color
})

const onClick = (event: MouseEvent) => {
  if (event?.target?.closest('.tree-arrow')) {
    return
  }
  event.stopPropagation()
  event.preventDefault()
}

const applyColor = ({
  color,
  includeChildren,
}: {
  color: string | null
  includeChildren: boolean
}) => {
  emit('applyColor', {
    color,
    code: props.item.id,
    includeChildren,
  })
}
</script>

<style scoped>
.tree-item__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 0.25rem;
  min-height: 24px;
  padding: 2px 0;
}
.tree-item {
  display: flex;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: var(--color-primary);
  }
}
.tree-arrow {
  cursor: pointer;
}
.opened {
  .tree-arrow {
    transform: rotate(90deg);
  }
}
.disabled {
  background-color: rgba(255, 67, 67, 0.2);
  opacity: 0.5;
}
.tree-item_feeds {
  flex-direction: row-reverse;
  .tree-item__indicator {
    flex-shrink: 0;
    width: 5px;
    margin-right: 4px;
  }
}
.tree-item_non-feeds {
  align-items: center;
  .tree-item__indicator {
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    border-radius: 50%;
  }
}
</style>
