<template>
  <div
    v-for="button of workspaceButtons"
    :key="button.name"
    class="workspace-switcher__btn"
    :class="{ sm: isScreenSmall }"
  >
    <MainNavButton
      v-bind="button"
      :active="button.name === currentWorkspace"
      @click="switchWorkspace(button.name)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import MainNavButton from '@/ContextTab/components/MainNav/MainNavButton.vue'
import { useAuthInfo } from '@/ContextTab/modules/auth/composables/useAuthInfo'
import { ACCESS } from '@/lib/accessCodes'
import { usePsRoute } from '@/ContextTab/composables/psRoute'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'
import { messageHub } from '@/ContextTab/services/messageHubClient'
import { messageTypes } from '@/lib/messageHub/messages'
import type { WorkspaceName } from '@/lib/types'


type WorkspaceButton = {
  name: WorkspaceName
  icon: string
  label: string
  color: string
  visible: boolean
}

const { isLegacyRoute } = usePsRoute()
const { isScreenSmall } = useScreenBreakpoints()
const { getIsUserHasAllPermissions } = useAuthInfo()

const currentWorkspace = ref<WorkspaceName>('published')

const workspaceButtons = computed<WorkspaceButton[]>(() =>
  ([
    {
      name: 'published',
      icon: 'workspace/published',
      label: 'Опубликованные',
      color: 'hsla(212, 100%, 52%, 1)',
      visible: !isLegacyRoute.value,
    },
    {
      name: 'canvas',
      icon: 'workspace/canvas',
      label: 'Холсты',
      color: 'hsla(94, 100%, 39%, 1)',
      visible: isLegacyRoute.value && getIsUserHasAllPermissions([ACCESS.NEWS.CANVAS]),
    },
    // {
    //   name: "embargo",
    //   icon: "workspace/embargo",
    //   label: "Отложенные",
    //   color: "hsla(212, 100%, 52%, 1)",
    // },
    // {
    //   name: "editor",
    //   icon: "workspace/editor",
    //   label: "Создать новость",
    //   color: "hsla(212, 100%, 52%, 1)",
    // },
    // {
    //   name: "search",
    //   icon: "workspace/search",
    //   label: "Поиск",
    //   color: "hsla(212, 100%, 52%, 1)",
    // },
  ] satisfies WorkspaceButton[]).filter((button) => button.visible),
)

// ask worker to switch to legacy and open canvas panel
// (because can't directly instantiate legacyBridge)
function openCanvas() {
  messageHub.sendMessage(
    {
      typeId: messageTypes.byName(messageTypes.names.service).id,
      signal: 'legacyAction',
    },
    {
      contentType: 'news',
      action: 'openCanvas',
      contextId: window.contextId,
    },
  )
}

const switchWorkspace = (workspace: WorkspaceName) => {
  // до появления еще какого-нибудь ворскпейса, кроме published
  // пока не переключает воркспейсы, а реализует только открытие холста в легаси

  if (workspace === 'canvas') {
    openCanvas()
  }

  if (currentWorkspace.value === workspace) {
    return
  }
  currentWorkspace.value = workspace
}
</script>

<style scoped>
.workspace-switcher__btn {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    height: 1px;
    left: 10%;
    top: 0;
    background: var(--color-gray-700);
  }

  &.sm:before {
    width: 1px;
    height: 70%;
    left: 0;
    top: 15%;
  }
}
</style>
