<template>
  <PsModal
    :show="show"
    class="ps-modal--calendar"
    header="Дата и время"
    size="sm"
    @update:show="emit('update:show', $event)"
  >
    <div class="ps-modal-calendar">
      <div class="ps-modal-calendar__fields">
        <DateTimeModal
          v-model:date="dates.start"
          :maxTime="timeValidationRules.start"
        />
        <span> - </span>
        <DateTimeModal
          v-model:date="dates.end"
          :hasDate="false"
          :minTime="timeValidationRules.end"
        />
      </div>

      <div class="pskit__datepicker_body">
        <VDatePicker
          ref="datepicker"
          class="ps-modal-calendar__calendar"
          :modelValue="dates.start"
          :maxDate="new Date()"
          borderless
          locale="ru-RU"
          @update:modelValue="handleCalendarUpdate"
        />
        <div class="pskit__datepicker_arrows-container">
          <PsButton
            class="pskit__datepicker_arrow pskit__datepicker_arrow-back"
            size="sm"
            :padding="false"
            @click.prevent.stop="() => datepicker?.moveBy(-1)"
          >
            <template #prefix>
              <PsIcon
                class="arrowIcon"
                name="triangle-right"
                size="7"
                color="#848484"
              />
            </template>
          </PsButton>

          <PsButton
            class="pskit__datepicker_arrow"
            size="sm"
            :padding="false"
            @click.prevent.stop="() => datepicker?.moveBy(1)"
          >
            <template #prefix>
              <PsIcon
                class="arrowIcon"
                name="triangle-right"
                size="7"
                color="#848484"
              />
            </template>
          </PsButton>
        </div>
      </div>
    </div>

    <template #footer>
      <PsSubmitGroup
        class="ps-modal-calendar__submit"
        submitLabel="Сохранить"
        @submit="apply"
        @cancel="() => emit('update:show', false)"
      />
    </template>
  </PsModal>
</template>

<script setup lang="ts">
import { reactive, watch, computed, useTemplateRef } from 'vue'
import { DatePicker as VDatePicker } from 'v-calendar'
import { useStoreAsync } from '@/ContextTab/useStore'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import PsSubmitGroup from '@/ContextTab/components/PsSubmitGroup.vue'
import PsModal from '@/ContextTab/components/UI/PsModal.vue'
import DateTimeModal from '@/ContextTab/modules/news/published/components/dateTime/DateTimeModal.vue'
import { dateToDateTimeArray, dateTimeArrayToDate } from '@/utils/dates'
import type { PublishedLocalFiltersStore } from '@/ContextApp/stores/news/published/localFilters'


defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'update:show', val: boolean): void
}>()

const datepicker = useTemplateRef<InstanceType<typeof VDatePicker>>('datepicker')
const store = await useStoreAsync<PublishedLocalFiltersStore>('publishedLocalFilters')
const dates = reactive({ ...store.data.dates })
const timeValidationRules = computed(() => {
  return {
    start: dateToDateTimeArray(dates.end)[1],
    end: dateToDateTimeArray(dates.start)[1],
  }
})

const apply = () => {
  store.updateDates(dates)
  emit('update:show', false)
}

const handleCalendarUpdate = (date: Date) => {
  const [selectedDate] = dateToDateTimeArray(date)
  const start = dateTimeArrayToDate([selectedDate, dateToDateTimeArray(dates.start)[1]])
  const end = dateTimeArrayToDate([selectedDate, dateToDateTimeArray(dates.end)[1]])

  Object.assign(dates, { start, end })
}

//  Обновляем выбранную дату при изменении даты из стора (актульно только при открытой модалке)
watch(() => store.data.dates, (next, prev) => {
  if (!Object.is(next, prev)) {
    Object.assign(dates, next)
  }
})
</script>

<style scoped>
.ps-modal-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin: 0 auto;
  width: fit-content;
}
.ps-modal-calendar__fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}
:global(.ps-modal-calendar__calendar) {
  padding-inline: 0.5rem;
}
:global(.ps-modal--calendar:has(*:invalid) .ps-modal-calendar__submit [data-test-selector="submit-button"]) {
  pointer-events: none;
  opacity: 0.5;
}

.pskit__datepicker_body {
  position: relative;
  display: flex;
  justify-content: center;
}

.pskit__datepicker_arrows-container {
  position: absolute;
  top: 13px;
  right: 32px;
  display: flex;
  align-items: center;
}

.pskit__datepicker_arrow {
  position: relative;
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  vertical-align: middle;
}
.pskit__datepicker_arrow-back {
  margin-right: 10px;
  transform: rotate(180deg);
}
</style>
