import { REFERENCES } from '@/lib/references'


interface FormattedRef {
  id: number
  description: string
}

interface OriginalRef {
  name: string
  description: string
}

function formatRefs<T extends Record<string, OriginalRef>>(ref: T): Record<T[keyof T]['name'], FormattedRef> {
  const processed = {}
  Object.keys(ref).forEach((id) => {
    processed[ref[id].name] = {
      id: Number(id),
      description: ref[id].description,
    }
  })
  return processed as Record<T[keyof T]['name'], FormattedRef>
}

export const NEWS_STATUSES_BY_NAME = Object.freeze(formatRefs(REFERENCES.News.Status))
export const NEWS_LINK_TYPE_BY_NAME = Object.freeze(formatRefs(REFERENCES.NewsLink.Type))
export const NEWS_TYPES_BY_NAME = Object.freeze(formatRefs(REFERENCES.News.Type))
