import { version } from '../../package.json'

// Настройки окружения

export const IS_DEV = import.meta.env.DEV
export const VERSION = version

// Настройки Sentry

export const SENTRY_PUBLIC_KEY = '07c22769baf0422b8e86574a1cb718e8'

// Настройки подключения к серверу

let apiAddress = `https://${globalThis.location.host}/api/v1`
let gatewayAddress = `wss://${globalThis.location.host}/gateway/v1`
let sentryAddress = `https://${SENTRY_PUBLIC_KEY}@${globalThis.location.host}/sentry/7`
let checkAddress = `https://${globalThis.location.host}/check`
let docsAddress = `https://${globalThis.location.host}/docs`

const localAPI = 'https://publishing/api/v1'
const localGateway = 'wss://publishing/gateway/v1'
const localCheckAddress = 'https://publishing/check'
const localDocsAddress = 'https://test-publishing.interfax.ru/docs'

const publicTestAPI = 'https://test-publishing.interfax.ru/api/v1'
const publicTestGateway = 'wss://test-publishing.interfax.ru/gateway/v1'
const publicTestSentryAddress = `https://${SENTRY_PUBLIC_KEY}@test-publishing.interfax.ru/sentry/7`
const publicTestCheckAddress = 'https://test-publishing.interfax.ru/check'

const proxyTestAPI = `http://${globalThis.location.host}/api/v1`
const proxyTestGateway = `ws://${globalThis.location.host}/gateway/v1`
const proxyTestSentryAddress = `http://${SENTRY_PUBLIC_KEY}@${globalThis.location.host}/sentry/7`
const proxyTestCheckAddress = `http://${globalThis.location.host}/check`

const USE_TESTING_API = import.meta.env.USE_TESTING_API
const VITE_USE_DEV_PROXY = import.meta.env.VITE_USE_DEV_PROXY

if (IS_DEV) {
  apiAddress = localAPI
  gatewayAddress = localGateway
  checkAddress = localCheckAddress
  docsAddress = localDocsAddress
}

if (USE_TESTING_API) {
  apiAddress = publicTestAPI
  gatewayAddress = publicTestGateway
  sentryAddress = publicTestSentryAddress
  checkAddress = publicTestCheckAddress
}

if (VITE_USE_DEV_PROXY === 'true') {
  apiAddress = proxyTestAPI
  gatewayAddress = proxyTestGateway
  sentryAddress = proxyTestSentryAddress
  checkAddress = proxyTestCheckAddress
}

export const API_ROOT = apiAddress
export const GATEWAY_URL = gatewayAddress
export const CHECK_ONLINE_URL = checkAddress
export const SENTRY_DSN = sentryAddress
export const DOCS_PAGE = docsAddress

type SentryEnv = 'development' | 'testing' | 'stand' | 'production' | 'unknown'

export function getCurrentEnv(): SentryEnv {
  if (globalThis.location.hostname === 'localhost') {
    return 'development'
  }

  if (globalThis.location.hostname === 'test-publishing.interfax.ru') {
    return 'testing'
  }

  if (globalThis.location.hostname === 'publishing.interfax.ru') {
    return 'production'
  }

  if (
    new RegExp(/.+\.test-publishing\.interfax\.ru/gim, 'i').test(
      globalThis.location.hostname,
    )
  ) {
    return 'stand'
  }

  return 'unknown'
}
