<template>
  <WithInplaceMessage
    :loading="busy"
    :loaderElementsStyle="{
      width: '22px',
      height: '22px',
      top: 'calc(50% - 11px)',
      left: 'calc(50% - 11px)',
    }"
  >
    <IForm
      v-model="schema"
      class="ps-one-line-form"
      @submit="submit"
    >
      <IFormGroup>
        <IInput
          ref="input"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled || busy"
          :error="['invalid']"
          autofocus
          class="ps-one-line-input"
        >
          <template #append>
            <PsButtonGroup class="ps-oneline-button-group">
              <PsButton type="submit">
                <PbIcon
                  name="check-icon"
                  :color="disabled ? 'grey' : 'green'"
                  size="14"
                />
              </PsButton>
              <PsButton @click="emit('cancel')">
                <PbIcon
                  name="close"
                  color="red"
                  size="14"
                />
              </PsButton>
            </PsButtonGroup>
          </template>
        </IInput>
        <IFormError
          :for="name"
          :visible="['invalid']"
          class="errors"
        />
      </IFormGroup>
    </IForm>
  </WithInplaceMessage>
</template>

<script setup lang="ts">
import { IForm, IFormGroup, IInput, IFormError } from '@inkline/inkline'
import { computed, ref } from 'vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsButtonGroup from '@/ContextTab/components/UI/Button/PsButtonGroup.vue'
import WithInplaceMessage from '@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { useWiredForm } from '@/ContextTab/composables/useWiredForm'
import { useAutofocus } from './composables/useAutofocus'
import { useAutoselect } from './composables/useAutoselect'


const props = withDefaults(
  defineProps<{
    active?: boolean
    busy?: boolean
    disabled?: boolean
    errors?: any
    autofocus?: boolean
    autoselect?: boolean
    initialValue?: string
    placeholder?: string
    width?: string
    name?: string
    onApply?: (...args: any[]) => Promise<any>
  }>(),
  {
    active: true,
    busy: false,
    disabled: false,
    errors: null,
    autoselect: false,
    autofocus: true,
    initialValue: '',
    placeholder: undefined,
    width: '250px',
    name: 'input',
    onApply: async () => null,
  },
)

const emit = defineEmits<{
  (event: 'apply', value: string): void
  (event: 'cancel'): void
}>()

const err = computed(() => ({ errors: props.errors }))

const input = ref<typeof IInput | null>(null)

if (props.autofocus) {
  useAutofocus(input)
}

if (props.autoselect) {
  useAutoselect(input)
}

const apply = async () => {
  if (props.onApply) {
    await props.onApply(form.value[props.name])
  } else {
    emit('apply', form.value[props.name].value)
  }
}

const { form, schema, submit } = useWiredForm<{
  [key: string]: string
}>(
  {
    [props.name]: {
      value: props.initialValue ?? '',
      validators: [
        {
          name: 'maxLength',
          value: 255,
          message: 'Имя пресета должно быть не более 255 символов',
        },
        {
          name: 'custom',
          key: 'api',
          fallbackMessage: 'Ошибка в работе с сервером',
        },
      ],
    },
  },
  err,
  apply,
)
</script>

<style scoped>
.ps-one-line-form {
  width: v-bind(width);

  .errors {
    padding: 0.5em;
  }
}
.ps-one-line-input {
  --input--prepend-append--padding-left: 0 !important;
  --input--prepend-append--padding-right: 0 !important;
  position: relative;
}

.ps-oneline-button-group {
  padding: 0;
}
</style>
