<template>
  <div
    class="ps-horizontal-grid"
    :class="{ sm: isScreenSmall }"
  >
    <div
      class="list"
      :style="{ flexBasis: gridColWidths[0] + 'px' }"
    >
      <slot name="left" />
    </div>
    <Resizer
      v-if="isFluidLayout"
      dir="vertical"
      @resize="onGridResize(1, $event)"
    />
    <div
      class="sub-layout"
      :style="{ flexBasis: gridColWidths[0] + 'px' }"
    >
      <section
        id="popups-panel-horizontal"
        class="popups"
      />
      <div
        class="main"
        :style="{ flexBasis: subGridColWidths[0] + 'px' }"
      >
        <slot name="main" />
      </div>
      <Resizer
        v-if="isFluidLayout"
        dir="horizontal"
        @resize="onSubGridResize(1, $event)"
      />
      <div
        class="preview"
        :style="{ flexBasis: subGridColWidths[1] + 'px' }"
      >
        <section
          id="popups-preview-horizontal"
          class="popups"
        />
        <slot name="preview" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import { reactive, watchEffect } from 'vue'
import Resizer from '../Resizer.vue'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'


defineProps<{
  isFluidLayout: boolean
}>()

const storedColWidths = useStorage('ps-layout-published-horizontal', {
  grid: [200, 450],
  subGrid: [300, 300],
})

const { isScreenSmall } = useScreenBreakpoints()

const gridColWidths = reactive(storedColWidths.value.grid)
const subGridColWidths = reactive(storedColWidths.value.subGrid)

watchEffect(() => {
  storedColWidths.value = { grid: gridColWidths, subGrid: subGridColWidths }
})

const onGridResize = (index: number, diffs: { x: number, y: number }) => {
  const newSize = gridColWidths[index - 1] + diffs.x
  if (newSize > 100) {
    gridColWidths[index - 1] += diffs.x
  }
}

const onSubGridResize = (index: number, diffs: { x: number, y: number }) => {
  const newSize = subGridColWidths[index - 1] + diffs.y
  if (newSize > 100) {
    subGridColWidths[index - 1] += diffs.y
  }
}
</script>

<style scoped>
.ps-horizontal-grid {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: row;

  .list,
  .sub-layout {
    position: relative;
    overflow: hidden;
    min-width: 100px;
    flex-grow: 0;
  }

  .sub-layout {
    flex-grow: 1;
    display: flex;
    min-width: 100px;
    flex-direction: column;
    position: relative;

    .main,
    .preview {
      position: relative;
      overflow: hidden;
      min-height: 100px;
      flex-grow: 0;
    }

    .preview {
      flex-grow: 1;
    }
  }

  &.sm {
    .list {
      display: none;
    }
  }
}
</style>
