<template>
  <TreeSearch
    :searchParams="searchParams"
    :items="items"
    :showDepth="allOpened ? Infinity : 1"
    :emptyText="emptyText"
    idField="uid"
    :padding="32"
  >
    <template #default="{ item, opened }">
      <NewsCodesTreeItem
        :item="item"
        :filterState="filterState"
        :opened="allOpened || opened || false"
        :mode="mode"
        @click="onClick"
        @change="emit('change', item, $event)"
      />
    </template>
  </TreeSearch>
</template>

<script setup lang="ts">
import NewsCodesTreeItem from './NewsCodesTreeItem.vue'
import TreeSearch from '@/ContextTab/components/TreeSearch/TreeSearch.vue'
import { useTreeSearch } from '@/ContextTab/components/TreeSearch/useTreeSearch'
import type { CodeFilterState, CodeTreeSelectMode } from '@/ContextTab/modules/news/codes/types'


withDefaults(
  defineProps<{
    items: any
    filterState: CodeFilterState
    mode?: CodeTreeSelectMode
    allOpened?: boolean
    emptyText?: string
  }>(),
  {
    mode: 'check',
    allOpened: false,
    emptyText: 'Ничего не найдено',
  },
)

const emit = defineEmits<{
  (e: 'change', item: any, state: CodeFilterState[keyof CodeFilterState]['state']): void
}>()

const { makeSearchParams } = useTreeSearch()

const searchParams = makeSearchParams()

const onClick = (event: MouseEvent) => {
  if (
    event?.target
    && (event.target instanceof Element)
    && event.target.closest('.tree-arrow')
  ) {
    return
  }
  event.stopPropagation()
}
</script>

<style scoped></style>
