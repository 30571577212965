import { replaceHtmlEntity } from '@/utils/trimHTML'
import type { DirectiveBinding } from 'vue'


type EntityFields = {
  id: number
  list_ids: number[]
  name: string
  properties: Record<string, string>
  type_id: number
}

export const vHighlightEntities = (el: HTMLElement, binding: DirectiveBinding) => {
  const {
    entities = [],
    searchMode,
    selector = 'span[data-entity-id]',
  } = binding.value

  if (!entities?.length) {
    return
  }

  function entityTitleById(id: number): string | undefined {
    const { entity } = entities.find(({ entity }: { entity: EntityFields }) => entity.id === id) ?? {}

    if (entity) {
      const properties = Object.values(entity.properties).map((value) => `${value} — `)

      return replaceHtmlEntity(`${properties}${entity.name}`)
    }
  }

  el.querySelectorAll(selector)?.forEach((node: HTMLElement) => {
    const id = parseInt(node.dataset.entityId ?? '-1')
    const title = entityTitleById(id)

    if (title) {
      node.innerHTML = `
        <span title="${title}" class="_text-color:primary">
          ${searchMode
            ? `<em>${node.innerText}</em>`
            : node.innerText}
        </span>
      `
    }
  })
}
