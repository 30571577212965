<template>
  <TreeSearch
    :searchParams="searchParams"
    :items="items"
    :showDepth="1"
    :padding="32"
    idField="uid"
    sortField="code"
    emptyText="Ничего не найдено"
    :placeholder="placeholder"
  >
    <template #default="{ item, opened }">
      <AuthorsTreeItem
        :item="item"
        :filterState="filterState"
        :opened="opened || false"
        :mode="mode"
        @click="onClick"
        @change="emit('change', item, $event)"
      />
    </template>
  </TreeSearch>
</template>

<script setup lang="ts">
import AuthorsTreeItem from './AuthorsTreeItem.vue'
import TreeSearch from '@/ContextTab/components/TreeSearch/TreeSearch.vue'
import { useTreeSearch } from '@/ContextTab/components/TreeSearch/useTreeSearch'
import type { IAuthorTreeItem, AuthorFilterState } from './types'


withDefaults(
  defineProps<{
    items: any
    filterState: any
    mode?: 'check' | 'multi'
    placeholder?: string
  }>(),
  {
    mode: 'check',
    placeholder: 'Поиск по пользователям',
  },
)

const emit = defineEmits<{
  (e: 'change', item: IAuthorTreeItem, state: AuthorFilterState[keyof AuthorFilterState]['state']): void
}>()

const { makeSearchParams } = useTreeSearch()

const searchParams = makeSearchParams({
  fields: [
    'name',
    'first_name',
    'last_name',
    'patronymic',
    'code',
  ],
})

const onClick = (event: MouseEvent) => {
  if (
    event?.target
    && (event.target instanceof Element)
    && event.target.closest('.tree-arrow')
  ) {
    return
  }
  event.stopPropagation()
}
</script>

<style scoped></style>
