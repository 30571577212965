<template>
  <div
    class="date-delimiter"
    data-test-selector="date-delimiter"
  >
    <div
      class="date-delimiter__date"
      :style="{ background: color, color: fontColor }"
    >
      {{ formatted }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { isThisYear, dateTimeFormat } from '@/utils/dates'


const {
  date: initialDate,
  color = '#eeeeee',
  fontColor = '#333',
} = defineProps<{
  date: string | Date
  color?: string
  fontColor?: string
}>()

const formatted = computed(() => {
  const date = new Date(initialDate)
  return dateTimeFormat(date, {
    year: !isThisYear(date)
      ? 'numeric'
      : undefined,
    hour: undefined,
    second: undefined,
    minute: undefined,
  })
})
</script>

<style scoped>
.date-delimiter {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.date-delimiter__date {
  padding: 2px 5px;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 2px;
}
</style>
