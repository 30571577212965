<template>
  <PsPopover
    trigger="hover"
    placement="bottom"
    :arrow="false"
    @show="onShow"
  >
    <div class="news-extension-status__icon">
      <PsIcon
        name="extension"
        color="#d31515"
        size="14"
        data-test-selector="extension-status-icon"
      />
      <span
        v-if="extensionsCount"
        class="news-extension-status__counter"
      >
        {{ extensionsCount }}
      </span>
    </div>

    <template #body>
      <Circular
        v-if="isLoading"
        class="news-extension-status__loader"
      />
      <table v-else-if="extensionsCount">
        <tr
          v-for="child in extensionLinks"
          :key="child.news_id"
          class="news-extension-status__item"
        >
          <td
            v-if="childStatusPublished(child)"
            class="tag published"
            data-test-selector="extension-status"
          >
            Выпущено
          </td>
          <td
            v-else-if="childStatusWaitForEdit(child)"
            class="tag waiting"
            data-test-selector="extension-status"
          >
            Ожидает ред.
          </td>
          <td
            v-else-if="childStatusWaitForPublication(child)"
            class="tag waiting"
            data-test-selector="extension-status"
          >
            Ожидает опубл.
          </td>
          <td
            v-else
            class="tag inwork"
            data-test-selector="extension-status"
          >
            В&nbsp;работе
          </td>
          <td
            class="news-extension-status__user"
            @click="emit('open', child.news_id)"
          />
          <td
            class="news-extension-status__user"
            data-test-selector="extension-user"
            @click="emit('open', child.news_id)"
          >
            <PsNewsTypeButton
              :typeId="childTypeId(child)"
              :iconSize="18"
              :disabled="true"
            />
            <span>{{ userData(child) }}</span>
          </td>
        </tr>
      </table>

      <div
        v-else
        class="news-extension-status__item"
      >
        Нет расширений
      </div>
    </template>
  </PsPopover>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Circular from '@/ContextTab/components/Busy/Loader/Circular.vue'
import PsNewsTypeButton from '@/ContextTab/components/UI/Button/PsNewsTypeButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import PsPopover from '@/ContextTab/components/UI/Popover/PsPopover.vue'
import { usePublishedLinks } from '@/ContextTab/modules/news/published/composables/usePublishedLinks'
import { useStore } from '@/ContextTab/useStore'
import { NEWS_LINK_TYPE_BY_NAME } from '@/lib/referencesByName'
import type { NewsItemPublished } from '@/ContextTab/modules/news/published/components/types'
import type { NewsStore } from '@/ContextApp/stores/news'


const props = defineProps<{
  item: NewsItemPublished
}>()

const emit = defineEmits<{
  (e: 'open', id: number): void
}>()

const newsStore = useStore<NewsStore>('news', {
  shared: true,
})

const isLoading = ref(false)

const extensionLinks = computed(() => {
  const children = props.item.child_links

  if (!children) {
    return []
  }
  return children.filter(
    (child) =>
      child.type_id === NEWS_LINK_TYPE_BY_NAME['EXTENSION'].id,
  )
})

const extensionsCount = computed(() => extensionLinks.value.length)

const onShow = async () => {
  const hasAllNewsLoaded
    = extensionLinks.value
      .reduce((acc, curr) => {
        acc.push(!!childLoaded(curr))
        return acc
      }, [])
      .filter((value) => !!value).length === extensionLinks.value.length

  if (!hasAllNewsLoaded) {
    isLoading.value = true
    await newsStore.fetchNewsLinks?.(props.item.id)
  }

  isLoading.value = false
}

const {
  childLoaded,
  childStatusPublished,
  childStatusWaitForEdit,
  childStatusWaitForPublication,
  userData,
} = usePublishedLinks()

const childTypeId = (child) =>
  newsStore.data.news?.[child.news_id]?.version?.type_id
</script>

<style scoped>
.news-extension-status__counter {
  margin-left: 2px;
  font-size: 12px;
  font-weight: bold;
  color: #d31515;
  line-height: 1;
}

.inwork {
  background-color: #ff9900;
}

.published {
  background-color: #47de00;
}

.waiting {
  background-color: #ccff00;
}

.news-extension-status__icon {
  display: flex;
  align-items: flex-end;
  span {
    line-height: 1;
  }
}

.news-extension-status__loader {
  width: 12px;
  height: 12px;
}

.news-extension-status__item {
  white-space: nowrap;
  font-weight: 700;
  font-size: 12px;
}

.news-extension-status__user {
  margin-bottom: -1px;
  text-decoration: underline;
  transition: color 0.2s ease-out;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #0064d9;
  }
}
.tag {
  padding: 1px 3px;
}
</style>
../../composables/useStatusIcons
