<template>
  <PsFilterButton
    tooltip="Пользователь является автором новости или выпускающим редактором"
    :showClear="count > 0"
    name="Авторы"
    :count="count"
    size="sm"
    :plural="['польз.', 'польз.', 'польз.']"
    @click="emit('click', $event)"
    @clear="emit('clear')"
  >
    <template #prefix>
      <PbIcon
        name="user"
        css="stroke-width: unset;"
        size="14"
      />
    </template>
    <template #xs />
    <template #md>
      Польз.
    </template>
    <template #lg>
      Пользователи
    </template>
  </PsFilterButton>
</template>

<script setup lang="ts">
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'


defineProps<{
  count: number
}>()

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
  (e: 'clear'): void
}>()
</script>
