<template>
  <div @input="updateDate">
    <input
      v-if="hasDate"
      :class="{'input--no-picker': !hasDatePicker}"
      type="date"
      :value="dateParts[0]"
      min="1970-01-01"
      :max="dateToDateTimeArray(new Date())[0]"
    >
    <input
      v-if="hasTime"
      type="time"
      :value="dateParts[1]"
      :min="minTime"
      :max="maxTime"
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { dateToDateTimeArray, dateTimeArrayToDate } from '@/utils/dates'


const props = withDefaults(
  defineProps<{
    date: Date
    hasDatePicker?: boolean
    hasDate?: boolean
    hasTime?: boolean
    minTime?: string
    maxTime?: string
  }>(),
  {
    hasDatePicker: false,
    hasDate: true,
    hasTime: true,
    minTime: '00:00',
    maxTime: '23:59',
  },
)

const emit = defineEmits<{
  (e: 'update:date', value: Date): void
}>()

const dateParts = computed(() => dateToDateTimeArray(props.date))
const updateDate = ({ target }) => {
  if (!target.checkValidity()) {
    return
  }

  const parts = dateParts.value

  parts[Number(target.type === 'time')] = target.value

  emit('update:date', dateTimeArrayToDate(parts))
}
</script>

<style scoped>
div {
  display: contents;
}

input {
  flex: 1;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--color-gray-200, color-mix(in srgb, currentColor 20%, transparent));
  border-radius: var(--border-radius, 0.25rem);

  &.input--no-picker {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  &:user-invalid {
    background: var(--color-danger-50);
    border-color: var(--color-danger);
  }

  &::-webkit-calendar-picker-indicator {
    margin: 0;
    padding-right: 0;
  }

  &::-webkit-datetime-edit {
    width: min-content;
    max-width: max-content;
  }
}
</style>
