<template>
  <div class="pskit__virtual-tree-wrapper">
    <VirtualTreeItem
      v-for="item in visibleList"
      :key="item[idField]"
      :item="item"
      :selected="selectedId === item[idField]"
      :opened="
        Boolean(
          item?.children?.length && internal.visualMap[item?.[idField]]
        )
      "
      :trackShift="trackShift"
      :openPadding="openPadding"
      @toggle="toggle"
    >
      <template #default="{ opened, selected }">
        <slot v-bind="{ item, selected, opened }" />
      </template>
    </VirtualTreeItem>
  </div>
</template>

<script setup lang="ts">
import { computed, watchEffect, ref, type Ref } from 'vue'
import VirtualTreeItem from './VirtualTreeItem.vue'
import {
  normalizeChildrenByTrack,
  makeItems,
  makeList,
  defaultSearch,
  makeDefaultPredicate,
  makeSearchParams, type DefaultSearchParams,
} from './utils'


const props = withDefaults(defineProps<{
  items?: Record<string, any>
  openPadding?: number
  searchParams?: Partial<DefaultSearchParams>
  searchQuery?: string
  showDepth?: number
  skipPrepare?: boolean
  trackShift?: number
  selectedId?: number | string | undefined
  sortField?: string
  idField?: string
}>(), {
  items: undefined,
  openPadding: 20,
  searchParams: () => makeSearchParams(),
  searchQuery: '',
  showDepth: 1,
  // FIXME
  skipPrepare: true,
  trackShift: 1,
  selectedId: undefined,
  sortField: 'id',
  idField: 'id',
})

defineSlots<{
  after(): any
  default(props: { item: any, selected: boolean, opened: boolean }): any
  before(): any
  empty(): any
}>()

const searchParams = makeSearchParams(props.searchParams)
const searchMode = computed(
  () => props.searchQuery?.toString().length >= searchParams.minimalQueryLength,
)

const filterMode = computed(() => false)

const processed = computed(() => {
  if (!props.items) {
    return {}
  }

  if (props.skipPrepare) {
    return props.items
  }
  const processed = makeItems(props.items, 'children', props.idField)
  normalizeChildrenByTrack(processed)
  return processed
})

const fullList = computed(() => makeList(processed.value, props.sortField))

const filteredList = computed(() => {
  if (searchMode.value) {
    return search()
  } else {
    return fullList.value
  }
})

type InternalMap = {
  visualMap: {
    [key: string]: boolean
  }
}
const internal: InternalMap = {
  visualMap: {},
}
const visibleList: Ref<any[]> = ref([])

function updateVisibleList(clearVisualMap = false) {
  if (clearVisualMap) {
    internal.visualMap = {}
  }

  if (!props.items) {
    return
  }

  const visible = []

  // Проходим по всем отфильтрованным элементам
  for (let i = 0; i < filteredList.value.length; i++) {
    const item = filteredList.value[i]
    /* Если компонент в режиме поиска или фильтрации,
     * то в списке только отфильтрованные элементы и их родители,
     * нужно показать всё
     */
    if (searchMode.value || filterMode.value) {
      const parent = props.items[item.track[item.track.length - 1]]
      if (parent && internal.visualMap[parent[props.idField]] === false) {
        continue
      }

      visible.push(item)
      continue
    }

    // Учитываем props trackShift при повторном рендере
    if (
      item.track.length - props.trackShift >= 0
      && item.track.length <= props.showDepth
    ) {
      visible.push(item)
      continue
    }

    // Если элемент указан в visualMap — нужно его показать
    if (internal.visualMap[item[props.idField]]) {
      visible.push(item)
      continue
    }

    // Если родительский элмент в visualMap,
    // значит, он раскрыт и текущий элемент надо показать
    if (internal.visualMap[item.track[item.track.length - 1]]) {
      visible.push(item)
    }
  }
  visibleList.value = visible
}

function search() {
  const predicates = []
  if (props.searchQuery.length >= searchParams?.minimalQueryLength) {
    predicates.push(
      makeDefaultPredicate({
        searchString: props.searchQuery,
        params: {
          fields: searchParams.fields,
          caseSensitive: searchParams.caseSensitive,
        },
      }),
    )
  }
  predicates.push(...searchParams.predicates)
  const searchFunc = searchParams.searchFunction ?? defaultSearch
  return searchFunc({
    list: fullList.value,
    items: props.items,
    predicates,
    idField: props.idField,
  })
}

function toggle(item: any) {
  if (!item.children?.length) {
    return
  }
  const newState = !internal.visualMap[item[props.idField]]
  if (!newState) {
    hideRecursive(item)
  }
  internal.visualMap[item[props.idField]] = newState
  updateVisibleList()
}

function hideRecursive(item: any) {
  if (!item?.children?.length) {
    return
  }

  for (let i = 0; i < item.children.length; i++) {
    const child: any = processed.value[item.children[i] as string]
    if (child?.children?.length) {
      internal.visualMap[item.children[i]] = false
      hideRecursive(child)
    }
  }
}

watchEffect(() => {
  if (props.items) {
    updateVisibleList(true)
  }
})
</script>

<style scoped>
.pskit__virtual-tree-wrapper {
  height: 100%;
  overflow: auto;
  position: relative;
}
</style>
