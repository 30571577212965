const LEGACY_TABS_KEY = 'ps-open-legacy-tabs'
const ALIVE_UPDATE_INTERVAL = 10_000

const isPwa = !!(
  navigator.standalone
  || window.matchMedia('(display-mode: standalone)').matches
)


// it's valid until legacy isn't transformed to the one of other "workspaces" we can switch between

class OpenedLegacyTabs {

  getOpened() {
    return JSON.parse(localStorage.getItem(LEGACY_TABS_KEY) ?? '{ "tabs": [] }')
  }

  saveOpened(opened) {
    localStorage.setItem(LEGACY_TABS_KEY, JSON.stringify(opened))
  }

  startCurrentTabRegistration() {
    this.registerCurrentTabOnce()
    setInterval(() => {
      this.registerCurrentTabOnce()
    }, ALIVE_UPDATE_INTERVAL)
  }

  registerCurrentTabOnce() {
    const opened = this.getOpened()
    const i = opened.tabs.findIndex((tab) => tab.id === window.contextId)

    if (i >= 0) {
      opened.tabs[i].time = Date.now()
    } else {
      opened.tabs.push({ // if we'll replace `push` with `unshift` - will change strategy
        id: window.contextId,
        time: Date.now(),
        isPwa,
      })
    }
    this.saveOpened(opened)
  }

  unregisterCurrentTab() {
    const opened = this.getOpened()
    const i = opened.tabs.findIndex((tab) => tab.id === window.contextId)
    if (i >= 0) {
      opened.tabs.splice(i, 1)
    }
    this.saveOpened(opened)
  }

  removeObsolete(opened) {
    const tooLate = Date.now() - 3 * ALIVE_UPDATE_INTERVAL
    opened.tabs = opened.tabs.filter((tab) => tab.time > tooLate)
  }

  shouldOpenNewsInCurrentTab(senderContextId) {
    const opened = this.getOpened()
    this.removeObsolete(opened)
    this.saveOpened(opened)

    if (!window.contextId) {
      console.error('shouldOpenNewsInCurrentTab: no contextId set!')
      return false
    }

    if (!opened.tabs.length) {
      // something stuck (or current editor hadn't registered yet), who requested -> win
      return true
    }

    if (window.contextId === senderContextId) {
      // open in same tab -> the highest priority
      return true
    }

    if (!opened.tabs.find((tab) => tab.id === senderContextId)) {
      // requested from /published

      const pwaIndex = opened.tabs.findIndex((tab) => tab.isPwa)

      if (pwaIndex >= 0) {
        if (opened.tabs[pwaIndex].id === window.contextId) {
          return true
        }
      } else if (opened.tabs.length) {
        // force use first registered tab
        return opened.tabs[0].id === window.contextId
      }
    }

    return false
  }

  hasEditorTabs() {
    const opened = this.getOpened()
    this.removeObsolete(opened)
    this.saveOpened(opened)

    return opened.tabs.length > 0
  }

  isCurrentTabRegistered() {
    const opened = this.getOpened()
    this.removeObsolete(opened)
    this.saveOpened(opened)

    return !!opened.tabs.find((tab) => tab.id === window.contextId)
  }

}

export const openedLegacyTabs = new OpenedLegacyTabs()
