<template>
  <div v-if="visible">
    <div
      class="connection-status"
      :class="connectionState"
    >
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useStore } from '@/ContextTab/useStore'
import { appMetrics } from '@/ContextApp/services/appMetrics'
import { EGwState } from '@/ContextApp/services/gateway/gwConnection'
import type { GatewayStore } from '@/ContextApp/stores/gateway'


type TShownGwStatus = 'offline' | 'connecting' | 'online'

const TEXTS: Record<TShownGwStatus, string> = {
  offline: 'Нет соединения с сервером',
  connecting: 'Попытка соединения с сервером...',
  online: 'Подключено',
}

const gatewayStore = useStore<GatewayStore>('gateway', { shared: true })

const visible = ref(false)
const statusChangeTimestamp = ref(Date.now())
const statusOnlineTimestamp = ref(Date.now())
let visibilityTimer: number | null = null

const connectionState = computed<TShownGwStatus>(() => {
  switch (gatewayStore.data.status) {
    case EGwState.connecting:
    case EGwState.auth:
      return 'connecting'

    case EGwState.connected:
      return 'online'

    default:
      return 'offline'
  }
})

const message = computed(() => {
  return TEXTS[connectionState.value]
})

function show() {
  const startConnectionProblemsTime = Date.now()
  let intervalTime = Date.now()
  const TIMEOUT = 1500
  let attemptsLeft = 2

  if (!visibilityTimer) {
    visibilityTimer = setInterval(() => {
      // если статус за таймаут не изменился, значит разорванное соединение не может быстро восстановиться, и плашку нужно показать
      if (statusChangeTimestamp.value < intervalTime) {
        visible.value = true
        clearInterval(visibilityTimer)
        visibilityTimer = null
      } else if (connectionState.value === 'online') { // все в порядке, соединение восстановилось, плашку не показываем
        clearInterval(visibilityTimer)
        visibilityTimer = null
      } else {
        intervalTime = Date.now()
        // если за отведенное время ни разу соединение не восстанавливалось, значит, есть проблемы, и плашку нужно показать
        if (statusOnlineTimestamp.value < startConnectionProblemsTime) {
          attemptsLeft--
        }

        if (attemptsLeft === 0) {
          visible.value = true
          clearInterval(visibilityTimer)
          visibilityTimer = null
        }
      }
    }, TIMEOUT)
  }
}

function hide() {
  if (!visible.value) {
    return
  }

  const TIMEOUT = 1500
  const timeAt = Date.now()
  let isExec = false

  const timer = setInterval(() => {
    const timePassed = Date.now() - timeAt
    if (timePassed >= TIMEOUT * 2) {
      if (!isExec) {
        appMetrics.sendMessage('ConnectionStatus: hide() was not executed')
      }

      clearInterval(timer)
    }

    if (timePassed < TIMEOUT && isExec) {
      clearInterval(timer)
      appMetrics.sendMessage('ConnectionStatus: hide() was called too early')
    }
  }, TIMEOUT / 2)

  setTimeout(() => {
    isExec = true
    clearInterval(timer)
    if (Date.now() - timeAt < TIMEOUT) {
      appMetrics.sendMessage('ConnectionStatus: hide() was called too early')
    }
    visible.value = false
  }, TIMEOUT)
}

watch(connectionState, (connectionState) => {
  statusChangeTimestamp.value = Date.now()

  if (connectionState === 'online') {
    statusOnlineTimestamp.value = Date.now()
    hide()
  } else {
    show()
  }
})
</script>

<style scoped>
.connection-status {
  position: fixed;
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  z-index: 101;
  color: #fff;
  opacity: 0.85;

  &.offline {
    background-color: rgba(255, 82, 82, 0.8);
  }

  &.connecting {
    background-color: rgba(255, 193, 7, 1);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 24px 24px;
    animation: progress-bar-stripes 1s linear infinite;
  }

  &.online {
    background-color: #4caf50;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 24px 0;
  }
  to {
    background-position: 0 0;
  }
}
</style>
