<template>
  <PsTabs
    v-model="currTabId"
    :tabs="permittedTabs"
    class="task-panel-tabs"
  >
    <template #suffix>
      <PsButton
        class="close"
        :circle="true"
        :border="false"
        size="sm"
        @click="emit('close')"
      >
        <PsIcon
          type="font"
          name="close"
          size="14"
        />
      </PsButton>
    </template>
    <template #default="{ tab }">
      <component
        :is="tab?.component"
        v-if="tab?.component"
        :isLegacyRoute="isLegacyRoute"
        :loading="loading"
        @open="open"
        @pick="pick"
      />
      <div v-else>
        Вкладка "{{ currentTabName }}"
      </div>
    </template>
  </PsTabs>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import PsTabs from '@/ContextTab/components/UI/PsTabs.vue'
import { useAuthInfo } from '@/ContextTab/modules/auth/composables/useAuthInfo'
import { useNewsActions } from '@/ContextTab/modules/news/composables/useNewsActions'
import { tabs } from '@/ContextTab/modules/news/tasks-panel/components/nav/tabs'
import { useStoreAsync } from '@/ContextTab/useStore'
import { ACCESS } from '@/lib/accessCodes'
import { usePsRoute } from '@/ContextTab/composables/psRoute'
import type { TaskPanelSetType } from './nav/types'
import type { NewsStore } from '@/ContextApp/stores/news'
import type { TaskPanelStore } from '@/ContextApp/stores/news/task-panel/index'


const currTabId = defineModel<TaskPanelSetType>({ required: true })

defineProps<{
  modelValue: TaskPanelSetType
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { getIsUserHasAllPermissions } = useAuthInfo()
const { isLegacyRoute } = usePsRoute()

// стор taskPanel просто инициализируем, чтобы получать в нем сообщения из гейтвея
const [newsStore] = await Promise.all([
  useStoreAsync<NewsStore>('news', { shared: true }),
  useStoreAsync<TaskPanelStore>('taskPanel'),
])

const loading = ref(false)
const message = ref(null)

const permittedTabs = computed(() =>
  tabs.filter((tab) =>
    (tab.id === 'waiting' && getIsUserHasAllPermissions([ACCESS.NEWS.INCOMING]))
    || (tab.id === 'inProgress' && getIsUserHasAllPermissions([ACCESS.NEWS.IN_WORK]))
    || (tab.id === 'my' && getIsUserHasAllPermissions([ACCESS.NEWS.USER])),
  ),
)

const currentTabName = computed(() => {
  const curTab = tabs.find((tab) => tab.id === currTabId.value)
  return curTab
    ? curTab.label
    : ''
})

const { open } = useNewsActions(loading, message)

const pick = async (id: number) => {
  try {
    loading.value = true
    await newsStore.pick(id)
    await open(id)
  } finally {
    loading.value = false
  }
}
</script>

<style>
.task-panel-tabs {
  --tabs--header--background: #fff6e6;
  --tabs--header--border-radius: 0;
  --tabs--border-radius: 0;
  --tabs--tab--padding: 0;
  --tabs--padding-top: 1rem;
  --tabs--header--border-bottom-color: var(--color-gray);
  --tabs--header--border-bottom-width: 1px;
  --tabs--header--margin-bottom: 0;

  &.tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    max-height: 100vh;
  }

  &.tabs .tabs-header {
    padding: calc(0.5rem + 1px) 1rem;
    border-bottom: none;
    overflow: visible;
  }

  .tab {
    overflow-y: hidden;
    height: 100%;
  }

  & .tab-body {
    height: 100%;
  }

  .close.-transparent:not(:disabled, .-disabled):hover {
    color: #bd985c;
    background-color: #f3e4ca;
  }
}
</style>
