<template>
  <div
    class="tasks-panel-menu"
    :class="{ sm: isScreenSmall }"
  >
    <div class="tasks-panel-menu__group">
      <div class="tasks-panel-menu__group__title">
        вход
      </div>
      <TasksPanelButton
        :typeId="NEWS_TYPES_BY_NAME['FLASH'].id"
        :alertsCount="waitingFlashCount"
        :loading="!initialLoaded"
        title="Молнии"
        @click="emit('click', 'waiting')"
      />
      <TasksPanelButton
        :typeId="NEWS_TYPES_BY_NAME['EXPRESS'].id"
        :alertsCount="waitingExpressCount"
        :loading="!initialLoaded"
        title="Экспресс"
        @click="emit('click', 'waiting')"
      />
      <TasksPanelButton
        customIconName="menu-burger"
        :alertsCount="waitingNotUrgentCount"
        :loading="!initialLoaded"
        :iconSize="15"
        title="Не срочные"
        @click="emit('click', 'waiting')"
      />
    </div>
    <div class="tasks-panel-menu__group">
      <div class="tasks-panel-menu__group__title">
        работа
      </div>
      <TasksPanelButton
        :typeId="NEWS_TYPES_BY_NAME['FLASH'].id"
        :alertsCount="inProgressFlashCount"
        :loading="!initialLoaded"
        title="Молнии"
        @click="emit('click', 'inProgress')"
      />
      <TasksPanelButton
        :typeId="NEWS_TYPES_BY_NAME['EXPRESS'].id"
        :alertsCount="inProgressExpressCount"
        :loading="!initialLoaded"
        title="Экспресс"
        @click="emit('click', 'inProgress')"
      />
      <TasksPanelButton
        customIconName="menu-burger"
        :alertsCount="inProgressNotUrgentCount"
        :loading="!initialLoaded"
        :iconSize="15"
        title="Не срочные"
        @click="emit('click', 'inProgress')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import TasksPanelButton from './TasksPanelButton.vue'
import { useTaskPanelList } from '@/ContextTab/modules/news/tasks-panel/composables/useTaskPanelList'
import { useStore } from '@/ContextTab/useStore'
import { NEWS_TYPES_BY_NAME } from '@/lib/referencesByName'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'
import type { TaskPanelMenuGroupType } from './types'
import type { AlertsStore } from '@/ContextApp/stores/news/task-panel/alerts'


defineProps<{ active: string }>()

const emit = defineEmits<{
  (e: 'click', tab: TaskPanelMenuGroupType): void
}>()

const { isScreenSmall } = useScreenBreakpoints()

const alertsStore = useStore<AlertsStore>('alerts', { shared: true })

const waitingFlashCount = computed(() => alertsStore.data.waitingFlashCount)
const waitingExpressCount = computed(() => alertsStore.data.waitingExpressCount)
const waitingNotUrgentCount = computed(() => alertsStore.data.waitingNotUrgentCount)
const inProgressFlashCount = computed(() => alertsStore.data.inProgressFlashCount)
const inProgressExpressCount = computed(() => alertsStore.data.inProgressExpressCount)
const inProgressNotUrgentCount = computed(() => alertsStore.data.inProgressNotUrgentCount)

const initialLoaded = computed(() =>
  alertsStore.data.initialLoaded
  && waitingInitialLoaded.value
  && inProgressInitialLoaded.value,
)

const { initialLoaded: waitingInitialLoaded } = useTaskPanelList('waiting')
const { initialLoaded: inProgressInitialLoaded } = useTaskPanelList('inProgress')
</script>

<style lang="postcss" scoped>
.tasks-panel-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: var(--color-black);
  padding: 1rem 0;
  margin-bottom: 0.5rem;

  .tasks-panel-menu__group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
  }

  .tasks-panel-menu__group__title {
    text-align: center;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    color: #dddddd;
  }

  &.sm {
    flex-direction: row;
    padding: 0 0.5rem;
    margin-bottom: 0;
    margin-right: 0.5rem;

    .tasks-panel-menu__group {
      flex-direction: row;
    }

    .tasks-panel-menu__group__title {
      position: absolute;
      left: 0;
      top: -15px;
    }
  }
}
</style>
