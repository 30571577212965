<template>
  <Popover
    v-if="isCollapsed"
    trigger="manual"
    :open="model"
    placement="bottom"
    class="action-popover"
    @clickOutside="hidePopover"
    @clickTrigger="onTriggerClick"
  >
    <slot name="trigger" />
    <template #body>
      <div class="buttons">
        <slot />
      </div>
    </template>
  </Popover>
  <template v-else>
    <slot />
  </template>
</template>

<script setup lang="ts">
import { computed, inject, watch } from 'vue'
import Popover from '@/ContextTab/components/UI/Popover/PsPopover.vue'
import { type SizeBreakpointMark } from '@/ContextTab/composables/types'
import { ContainerBreakpointsKey } from '@/injectionKeys'
import type { useContainerBreakpoints } from '@/ContextTab/composables/useContainerBreakpoints'


const model = defineModel<boolean>({ default: false })

const props = defineProps<{
  collapseOn: SizeBreakpointMark
}>()

const containerBreakpoints = inject<ReturnType<typeof useContainerBreakpoints> | undefined>(
  ContainerBreakpointsKey,
)

if (!containerBreakpoints) {
  console.warn('Collapsible: no breakpoints provided!')
}

const isCollapsed = computed(() =>
  !containerBreakpoints
  || containerBreakpoints.isSmallerOrEqual(props.collapseOn),
)

const hidePopover = () => {
  model.value = false
}

watch(
  () => containerBreakpoints?.currentBreakpointMark.value,
  (next, prev) => {
    if (prev === 'xs') {
      model.value = false
    }
  },
)

const onTriggerClick = () => {
  model.value = !model.value
}
</script>

<style scoped>
.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
}
</style>
