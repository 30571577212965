import NewsInProgressList from '@/ContextTab/modules/news/tasks-panel/in-progress/NewsInProgressList.vue'
import NewsMyList from '@/ContextTab/modules/news/tasks-panel/my/NewsMyList.vue'
import NewsWaitingList from '@/ContextTab/modules/news/tasks-panel/waiting/NewsWaitingList.vue'
import type { TaskPanelTab } from './types'


export const tabs: TaskPanelTab[] = [
  {
    id: 'waiting',
    icon: 'task-panel/move-to-inbox',
    label: 'Входящие',
    menuLabel: 'вход',
    color: 'hsla(212, 100%, 52%, 1)',
    component: NewsWaitingList,
  },
  {
    id: 'inProgress',
    icon: 'task-panel/pending',
    label: 'В работе',
    menuLabel: 'работа',
    color: 'hsla(212, 100%, 52%, 1)',
    component: NewsInProgressList,
  },
  {
    id: 'my',
    icon: 'task-panel/clinical-notes',
    label: 'Мои',
    color: 'hsla(212, 100%, 52%, 1)',
    component: NewsMyList,
  },
]
