<template>
  <div id="application">
    <template v-if="isAppInitialized">
      <ConnectionStatus />
      <AppUpdater />
      <Auth v-if="authStore.data.authorized !== AuthStatus.authorized" />
      <template v-else>
        <SplashScreen v-if="initStore.data.state !== AppInitState.ready" />
        <MainGrid
          v-else
          @logout="logout"
        >
          <template #default="{ isTaskPanelShown }">
            <RouterView v-slot="{ Component }">
              <template v-if="Component">
                <Suspense>
                  <component
                    :is="Component"
                    :isTaskPanelShowed="isTaskPanelShown"
                  />
                </Suspense>
              </template>
            </RouterView>
          </template>
        </MainGrid>

        <ShareModal
          v-if="shareModalVisible"
          :modelValue="shareModalVisible"
          @update:modelValue="toggleShareModal"
        />
      </template>
    </template>

    <div id="tooltips" />
    <div id="popups" />
    <div id="popovers" />
    <div id="message" />
  </div>
</template>

<script setup lang="ts">
import { provide, watch, computed } from 'vue'
import { RouterView } from 'vue-router'
import AppUpdater from '@/ContextTab/components/AppUpdater.vue'
import ConnectionStatus from '@/ContextTab/components/ConnectionStatus.vue'
import SplashScreen from '@/ContextTab/components/SplashScreen.vue'
import MainGrid from '@/ContextTab/layout/grid/MainGrid.vue'
import Auth from '@/ContextTab/modules/auth/components/Auth.vue'
import ShareModal from '@/ContextTab/modules/news/share-modal/components/ShareModal.vue'
import { useShareModal } from '@/ContextTab/modules/news/composables/useShareModal'
import { useStore } from '@/ContextTab/useStore'
import { KeyboardHandlerKey } from '@/injectionKeys'
import { KeyboardHandler } from '@/ContextTab/services/keyboardHandler'
import { tabMetrics } from '@/ContextTab/services/tabMetrics'
import { AuthStatus, type AuthStore } from '@/ContextApp/stores/auth'
import { AppInitState, type InitStore } from '@/ContextApp/stores/init'
import { useTabDescription } from './useTabDescription'
import { type GroupsAndUsersStore } from '@/ContextApp/stores/groupsAndUsers'


const initStore = useStore<InitStore>('init', {
  shared: true,
})

const authStore = useStore<AuthStore>('auth', {
  shared: true,
})

const groupsAndUsersStore = useStore<GroupsAndUsersStore>('groupsAndUsers', {
  shared: true,
})

const { shareModalVisible, hideShareModal } = useShareModal()
const toggleShareModal = (value: boolean) => {
  if (!value) {
    hideShareModal()
  }
}

const isAppInitialized = computed(() =>
  authStore.created.value && ((authStore.data.authorized ?? AuthStatus.initial) > 0),
)

function logout() {
  localStorage.clear()
  authStore.logout?.()
}

watch([() => authStore.data.authorized, groupsAndUsersStore.created],
  ([authState, gauStoreIsCreated]) => {
    if (authState === AuthStatus.authorized && gauStoreIsCreated) {
      groupsAndUsersStore.debouncedFetchData?.()
    }

    if (authState === AuthStatus.authorized) {
      tabMetrics.setUser({
        id: authStore.data.userId ?? '',
        name: authStore.data.login ?? '',
        email: authStore.data.login ?? '',
      })
    }
  })

useTabDescription()

provide(KeyboardHandlerKey, new KeyboardHandler())
</script>

<style scoped>
  #application {
    --workspace-switcher-size: 4.125rem;
    --task-panel-width: 18rem;
    background: url("@/assets/images/bg_default_light.webp") center/cover no-repeat;
    overflow: hidden;
    position: relative;
  }
</style>
