<template>
  <TasksPanelListItem
    class="in-progress-list-item"
    :item="item"
    :isSelected="isSelected"
    :loading="loading"
    @dblclick="emit('open', item.id)"
  >
    <template #expansion-extra>
      <NewsItemComment
        v-if="item.first_comment"
        class="in-progress-list-item__comment"
        :comment="item.first_comment.body"
      />
    </template>
    <template #footer>
      <div
        v-if="isSelected"
        class="in-progress-list-item__footer"
      >
        <PsButton
          :title="
            isLegacyRoute
              ? 'Открыть в отдельной панели'
              : 'Открыть в новой вкладке'
          "
          circle
          :border="false"
          class="action-button"
          @click="emit('open', item.id)"
        >
          <PsIcon
            name="button-icon-show-more"
            :size="24"
          />
        </PsButton>
      </div>
    </template>
  </TasksPanelListItem>
</template>

<script setup lang="ts">
import TasksPanelListItem from '../components/TasksPanelListItem.vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import NewsItemComment from '@/ContextTab/modules/news/tasks-panel/components/NewsItemComment.vue'


withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    isLegacyRoute: boolean
    loading: boolean
  }>(),
  {
    isSelected: false,
  },
)
const emit = defineEmits<{
  (e: 'open', id: number): void
}>()
</script>

<style scoped>
.in-progress-list-item__footer .action-button:not(:disabled, .-disabled):hover {
  --button--color: #bd985c;
}
</style>
