<template>
  <PanelToolbar
    class="published-titlebar"
    background="#0069e4"
    :breakpoints="toolbarBreakpoints"
  >
    <template #left>
      <PsButtonToolbar
        :circle="true"
        :border="false"
        @click="emit('toggleSearch')"
      >
        <PsIcon
          type="font"
          name="search"
          size="20"
          color="#fff"
        />
      </PsButtonToolbar>
      <CalendarButton
        :datesLabel="datesLabel"
        :datesSelected="store.data.isDatesChanged"
        @click="() => calendarModal = !calendarModal"
        @clear="clearDates"
      />
      <RepublicationsButton
        :checked="repFilter"
        @click="toggleRepublication"
      />
      <ColorsButton @click="colorsModal = true" />
      <ColorIndicatorModal v-model="colorsModal" />
      <CalendarModal
        v-if="calendarModal"
        v-model:show="calendarModal"
      />
    </template>
    <template #right>
      <ModeSwitcher />
    </template>
  </PanelToolbar>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CalendarButton from './buttons/Calendar.vue'
import ColorsButton from './buttons/Colors.vue'
import ModeSwitcher from './buttons/ModeSwitcher.vue'
import RepublicationsButton from './buttons/Republications.vue'
import PanelToolbar from '@/ContextTab/components/Toolbar/PanelToolbar.vue'
import PsButtonToolbar from '@/ContextTab/components/UI/Button/PsButtonToolbar.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import ColorIndicatorModal from '@/ContextTab/modules/news/published/components/colorIndication/ColorIndicatorModal.vue'
import CalendarModal from '@/ContextTab/modules/news/published/components/dateTime/CalendarModal.vue'
import { useStore } from '@/ContextTab/useStore'
import { dateToDateTimeArray, dateTimeFormat } from '@/utils/dates'
import type { PublishedLocalFiltersStore } from '@/ContextApp/stores/news/published/localFilters'
import type { SizeBreakpoints } from '@/ContextTab/composables/types'


const emit = defineEmits<{
  (event: 'toggleSearch'): void
}>()

const store = useStore<PublishedLocalFiltersStore>('publishedLocalFilters')

const colorsModal = ref(false)
const calendarModal = ref(false)

const datesLabel = computed(() => {
  const { start, end } = store.data.dates ?? {}

  if (start) {
    const startDateTime = dateTimeFormat(start, { month: '2-digit', day: '2-digit', second: undefined })
    return `${startDateTime} - ${dateToDateTimeArray(end)[1]}`
  }

  return 'Не выбрано'
})

const repFilter = computed(() =>
  store.data.hideRepublications
    ? 'included'
    : 'unchecked',
)

const toolbarBreakpoints: SizeBreakpoints = {
  xs: 0,
  sm: 320,
  md: 380,
  lg: 420,
  xl: 520,
  xxl: 1300,
  xxxl: 1800,
}

const toggleRepublication = () => store.toggleRepublications?.()

const clearDates = () => store.updateDates?.()
</script>

<style scoped>
.published-titlebar {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 11;
}
</style>
