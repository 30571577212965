<template>
  <div
    class="resizer"
    :class="{ resizing:isResizing, [dir]: true }"
  >
    <div
      class="handler"
      @mousedown="onMouseDown"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue'


withDefaults(
  defineProps<{
    dir?: 'vertical' | 'horizontal'
  }>(),
  { dir: 'vertical' },
)

const emit = defineEmits<{
  (event: 'resize', payload: { x: number, y: number }): void
}>()

const isResizing = ref(false)

const onMouseDown = (event: MouseEvent) => {
  event.stopPropagation()
  event.preventDefault()
  isResizing.value = true
}

const onMouseUp = (event: MouseEvent) => {
  if (isResizing.value) {
    event.stopPropagation()
    event.preventDefault()
    isResizing.value = false
  }
}

const onMouseMove = (event: MouseEvent) => {
  if (isResizing.value) {
    event.stopPropagation()
    event.preventDefault()
    emit('resize', {
      x: event.movementX,
      y: event.movementY,
    })
    // notify popover to update position
    document.dispatchEvent(
      new CustomEvent('layoutResize', {
        detail: { movement: { x: event.movementX, y: event.movementY } },
      }),
    )
  }
}

document.addEventListener('mousemove', onMouseMove)
document.addEventListener('mouseup', onMouseUp)

onBeforeUnmount(() => {
  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('mouseup', onMouseUp)
})
</script>

<style scoped>
.resizer {
  box-sizing: border-box;
  background: hsl(0, 0%, 90%);
  position: relative;
  z-index: 1;

  .handler {
    background: hsl(0, 0%, 90%);
    position: absolute;
    z-index: 9;
  }
  &:hover,
  &.resizing {
    .handler {
      background: hsl(0, 0%, 80%);
      overflow: visible;
    }
  }
  &.vertical {
    cursor: col-resize;
    width: 2px;

    .handler {
      top: 0;
      height: 100%;
      width: 2px;
    }
    &:hover,
    &.resizing {
      .handler {
        left: -4px;
        right: -4px;
        width: 10px;
      }
    }
  }
  &.horizontal {
    height: 2px;
    cursor: row-resize;
    width: 100%;

    .handler {
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
    }
    &:hover,
    &.resizing {
      .handler {
        top: -4px;
        bottom: -4px;
        height: 10px;
      }
    }
  }
}
</style>
