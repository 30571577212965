<template>
  <IRadioButtons
    v-model="model"
    :options="options"
    size="sm"
    variant="button-group"
    class="ps-radio-buttons"
  />
</template>

<script setup lang="ts">
import { IRadioButtons } from '@inkline/inkline'


const model = defineModel<any>()

defineProps<{ options: any[] }>()

</script>

<style scoped>
.ps-radio-buttons {
  --checkable-button-group--sm--padding-top: 0;
  --checkable-button-group--sm--padding-right: 0;
  --checkable-button-group--sm--padding-bottom: 0;
  --checkable-button-group--sm--padding-left: 0;

  :deep(.button) {
    --button--border-top-left-radius: 0;
    --button--border-top-right-radius: 0;
    --button--border-bottom-right-radius: 0;
    --button--border-bottom-left-radius: 0;

    --button--padding-left: var(--padding-left-2-3) !important;
    --button--padding-right: var(--padding-right-2-3);
    --button--padding-top: var(--padding-top-1-3);
    --button--padding-bottom: var(--padding-bottom-1-3);
    /* --button--padding: var(--padding-1-2); */

    &:first-child {
      --button--border-left-width: 1px;
      --button--border-top-left-radius: var(--border-top-left-radius);
      --button--border-bottom-left-radius: var(--border-bottom-left-radius);
      --button--border-right-color: transparent;
    }
    &:last-child {
      --button--border-right-width: 1px;
      --button--border-left-width: 1px;
      --button--border-top-right-radius: var(--border-top-right-radius);
      --button--border-bottom-right-radius: var(--border-bottom-right-radius);
    }

    /* & + :is(.button) { */
      /* --button--border-right-width: 0 !important; */
      /* --button--border-right-color: transparent; */
    /* } */

    /* --button--padding: var(--button--padding-top); */
/*     padding-left: var(--button--padding-left);
    padding-right: var(--button--padding-left);
    padding-top: var(--button--padding-top);
    padding-bottom: var(--button--padding-bottom);
 */
    &:is(.-active) {
      --button--border-top-color: var(--color-primary);
      --button--border-right-color: var(--color-primary);
      --button--border-bottom-color: var(--color-primary);
      --button--border-left-color: var(--color-primary);
      --button--color: var(--color-primary);
    }
  }
}
</style>
