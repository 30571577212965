<template>
  <PsModal
    v-model:show="curValue"
    header="История материала"
    size="lg"
  >
    <div class="relations-news-modal">
      <CircularLoader
        v-if="storeRelationsNews.data.isFetching"
        class="relations-news-modal__loader"
      />
      <div
        v-else
        class="relations-news-modal__container"
      >
        <div class="relations-news-modal__col">
          <RelationsEntry
            v-for="(rel, index) in leftItems"
            :key="index"
            type="newsItem"
            :entry="rel.entry"
            :hasHorizontalLine="rel.hasHorizontalLine"
            :isFlash="rel.entry.type_id === FLASH_ID"
            :isExpress="rel.entry.type_id === EXPRESS_ID"
            @click="clickHandler"
          />
        </div>
        <div class="relations-news-modal__col">
          <RelationsEntry
            v-for="(rel, index) in centerItems"
            :key="index"
            :type="rel.type"
            :entry="rel.entry"
            :isCurrent="rel.isCurrent"
            :hasHorizontalLine="rel.hasHorizontalLine"
            :isFlash="rel.entry.type_id === FLASH_ID"
            :isExpress="rel.entry.type_id === EXPRESS_ID"
            @click="clickHandler"
          />
        </div>
        <div class="relations-news-modal__col">
          <RelationsEntry
            v-for="(rel, index) in rightItems"
            :key="index"
            :type="rel.type"
            :entry="rel.entry"
            :hasHorizontalLine="false"
            :isFlash="rel.entry.type_id === FLASH_ID"
            :isExpress="rel.entry.type_id === EXPRESS_ID"
            @click="clickHandler"
          />
        </div>
      </div>
    </div>
  </PsModal>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { computed, watch } from 'vue'
import DOMPurify from 'dompurify'
import CircularLoader from '@/ContextTab/components/Busy/Loader/Circular.vue'
import RelationsEntry from '@/ContextTab/components/RelationsEntry.vue'
import PsModal from '@/ContextTab/components/UI/PsModal.vue'
import { useStore } from '@/ContextTab/useStore'
import { formatUsername } from '@/utils/formatUsername'
import {
  NEWS_LINK_TYPE_BY_NAME,
  NEWS_STATUSES_BY_NAME,
  NEWS_TYPES_BY_NAME,
} from '@/lib/referencesByName'
import type { RelationsNewsStore } from '@/ContextApp/stores/news/relationsNews'
import type { EntryItem, RelationsEntryItem } from './types'


interface Props {
  modelValue: boolean
  item: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (e: 'update:modelValue', val: (typeof props)['modelValue']): void
  (e: 'open', id: number): void
}>()

const curValue = useVModel(props, 'modelValue', emit)

const storeRelationsNews = useStore<RelationsNewsStore>('relationsNews')

watch(
  () => props.modelValue,
  (next) => {
    if (next) {
      storeRelationsNews.loadRelationsNews?.(props.item.id)
    }
  },
)

const FLASH_ID = NEWS_TYPES_BY_NAME['FLASH'].id
const EXPRESS_ID = NEWS_TYPES_BY_NAME['EXPRESS'].id
const REPUBLICATION_ID = NEWS_LINK_TYPE_BY_NAME['REPUBLICATION'].id
const REPUBLICATION_UNEDITED_ID = NEWS_LINK_TYPE_BY_NAME['REPUBLICATION_UNEDITED'].id
const REVOCATION_ID = NEWS_LINK_TYPE_BY_NAME['REVOCATION'].id
const EXTENSION_ID = NEWS_LINK_TYPE_BY_NAME['EXTENSION'].id
const WAITS_FOR_PUBLICATION_ID = NEWS_STATUSES_BY_NAME['WAITS_FOR_PUBLICATION'].id
const PUBLISHED_ID = NEWS_STATUSES_BY_NAME['PUBLISHED'].id

const newsbreak = computed(
  () => storeRelationsNews.data.newsRelations?.newsbreak,
)

const asoiNote = computed(
  () => storeRelationsNews.data.newsRelations?.asoi_note,
)

const outgoingAsoiNotes = computed(
  () => storeRelationsNews.data.newsRelations?.outgoing_asoi_notes || [],
)

const childLinks = computed(
  () => storeRelationsNews.data.newsRelations?.child_links || [],
)

const parentLinks = computed(
  () => storeRelationsNews.data.newsRelations?.parent_links || [],
)

const validLinkTypes = [
  REPUBLICATION_ID,
  REPUBLICATION_UNEDITED_ID,
  REVOCATION_ID,
  EXTENSION_ID,
]

const leftItems = computed(() => {
  const items = []
  const parentNews = parentLinks.value
    .filter((link) => validLinkTypes.includes(link.type_id))
    .map((link) => ({ type: 'newsItem', entry: link.news }))

  // Инфоповод
  if (newsbreak.value) {
    items.push({
      type: 'newsbreak',
      entry: newsbreak.value,
      hasHorizontalLine: !(asoiNote.value || parentNews.length > 0),
    })
  }

  // Заметка АСОИ
  if (asoiNote.value) {
    items.push({ type: 'asoiNote', entry: asoiNote.value })
  }

  Array.prototype.push.apply(items, parentNews)

  return items.map((item) => prepareItem(item))
})

const centerItems = computed(() => {
  const currentNewsItem = prepareItem({
    type: 'newsItem',
    entry: props.item,
    isCurrent: true,
    hasHorizontalLine: rightItems.value.length > 0,
  })

  const items = [currentNewsItem]

  if (leftItems.value.length > 1) {
    items.unshift({ entry: {}, type: 'thumb' })
  }

  return items
})

const rightItems = computed(() => {
  const items = []
  const childNews = childLinks.value
    .filter((link) => validLinkTypes.includes(link.type_id))
    .map((link) => ({ type: 'newsItem', entry: link.news }))

  Array.prototype.push.apply(items, childNews)

  // Исходящая заметка АСОИ
  if (outgoingAsoiNotes.value) {
    const notes = outgoingAsoiNotes.value.map((note) => {
      return { type: 'asoiNote', entry: note }
    })
    Array.prototype.push.apply(items, notes)
  }

  return items.map((item) => prepareItem(item))
})


function prepareItem(item): RelationsEntryItem {
  const entry: EntryItem = {
    id: item.entry.id,
    type: item.type,
    meta: prepareMeta(item),
  }


  switch (item.type) {
    case 'newsItem': {
      entry.type_id = item.entry.version.type_id
      entry.date = item.entry.status_modified_at
      entry.body = prepareNewsItemText(item.entry.version)
      entry.is_revoked = item.entry.is_revoked
      break
    }

    case 'asoiNote':
      entry.type_id = item.entry.type_id
      entry.date = item.entry.created_at
      entry.body = prepareNewsItemText(item.entry) || 'Пустая заметка'
      break

    case 'newsbreak': {
      entry.source = item.entry.lead.source.name
      entry.date = item.entry.created_at
      entry.body = item.entry.lead.clearedText
      break
    }

    default:
      return item
  }

  return {
    type: item.type,
    entry,
    isCurrent: item.isCurrent,
    hasHorizontalLine: item.hasHorizontalLine,
  }
}

function prepareMeta(item) {
  switch (item.type) {
    case 'newsItem': {
      const statusIds = [WAITS_FOR_PUBLICATION_ID, PUBLISHED_ID]
      const authorsList = prepareAuthors(item.entry.version)
      let authorsString = authorsList.join(', ')
      if (
        statusIds.includes(item.entry.version.status_id)
        && item.entry.publisher
      ) {
        authorsString = item.entry.publisher.name + ' / ' + authorsString
      } else {
        authorsString
          = (authorsList.length > 1
            ? 'Авторы: '
            : 'Автор: ') + authorsString
      }

      return authorsString
    }

    case 'asoiNote': {
      if (item.entry.recipient_name) {
        return 'Кому: ' + item.entry.recipient_name
      }

      if (item.entry.sender) {
        return 'Отправитель: ' + item.entry.sender.name
      }
      return ''
    }
  }
}

function prepareNewsItemText(version) {
  if (version.headline) {
    return DOMPurify.sanitize(version.headline, { ALLOWED_TAGS: ['p'] }).slice(0, 140)
  }

  if (version.body) {
    return DOMPurify.sanitize(version.body, { ALLOWED_TAGS: ['p'] }).slice(0, 120)
  }
  return 'Пустая новость'
}

function prepareAuthors(version) {
  return (
    version?.authors?.map(({ user } = {}) => formatUsername(user.name)) ?? []
  )
}

function clickHandler(entry) {
  emit('open', entry.id)
}
</script>

<style scoped>
.relations-news-modal__loader {
  margin: 4rem auto;
  width: 100%;
}

.relations-news-modal__container {
  display: flex;
  padding: 25px 0;
}

.relations-news-modal__col {
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);

  &:first-child {
    padding-left: 15px;
  }
  &:last-child {
    padding-right: 15px;
  }
}
</style>
