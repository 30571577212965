<template>
  <render />
</template>

<script setup lang="ts">
import { h, inject, type VNode } from 'vue'
import PsButton from './PsButton.vue'
import { ContainerBreakpointsKey } from '@/injectionKeys'
import { type useContainerBreakpoints } from '@/ContextTab/composables/useContainerBreakpoints'
import type { Color } from '@/ContextTab/components/UI/types'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'


const props = withDefaults(
  defineProps<{
    size: SizeBreakpointMark
    color?: Color
    tooltip?: string
    buttonComp?: any
    disabled?: boolean
  }>(),
  {
    tooltip: '',
    buttonComp: PsButton,
    disabled: false,
    color: undefined,
  },
)

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
}>()

const slots = defineSlots<
  Partial<Record<
    SizeBreakpointMark
    | 'default'
    | 'prefix'
    | 'suffix',
    () => VNode[]
  >>
>()

const containerBreakpoints = inject<ReturnType<typeof useContainerBreakpoints> | undefined>(
  ContainerBreakpointsKey,
)

if (!containerBreakpoints) {
  console.warn('PsResponsiveButton: no breakpoints provided!')
}

const renderContent = () => {
  if (containerBreakpoints) {
    for (let i = containerBreakpoints.marksLessOrEqual.value.length; i >= 0; i--) {
      const mark = containerBreakpoints.marksLessOrEqual.value[i]
      const slot = slots[mark]
      if (slot) {
        return slot()
      }
    }
  }

  return slots.default
    ? slots.default()
    : null
}

const renderSlot = (name: string) => {
  return slots?.[name]?.()
}

const render = () =>
  h(
    props.buttonComp,
    {
      ...props,
      title: props.tooltip,
      onClick: (event) => emit('click', event),
    },
    {
      default: () => renderContent() ?? null,
      prefix: () => renderSlot('prefix'),
      suffix: () => renderSlot('suffix'),
    },
  )
</script>
