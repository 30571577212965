<template>
  <IModal
    ref="modal"
    v-model="show"
    :name="modalId"
    :size="size"
    :dismissible="dismissible"
    :header="header"
    :hideOnClickOutside="false"
    :closeOnPressEscape="false"
    transition="fade-in-transition"
    class="ps-modal"
    :class="{
      '-no-body-padding': !bodyPadding,
      '-no-footer-padding': !footerPadding,
    }"
    @open="onOpen"
    @close="onClose"
  >
    <template
      v-if="slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <slot />
    <template
      v-if="slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </IModal>
</template>

<script setup lang="ts">
import { IModal } from '@inkline/inkline'
import { inject, isRef, onBeforeUnmount, ref, useSlots, useTemplateRef } from 'vue'
import { OverlayController } from '@inkline/inkline/controllers'
import { v4 as uuid } from 'uuid'
import { KeyboardHandlerKey } from '@/injectionKeys'
import type { KeyboardHandler } from '@/ContextTab/services/keyboardHandler'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'


interface PsModalProps {
  dismissible?: boolean
  hideOnClickOutside?: boolean
  hideOnEscape?: boolean
  header?: string | null
  size?: SizeBreakpointMark
  bodyPadding?: boolean
  footerPadding?: boolean
}

const show = defineModel('show', { type: Boolean, required: true })

const props = withDefaults(defineProps<PsModalProps>(), {
  header: null,
  size: 'md',
  hideOnClickOutside: false,
  hideOnEscape: true,
  dismissible: true,
  bodyPadding: true,
  footerPadding: true,
})

const keyboardHandler = inject(KeyboardHandlerKey) as KeyboardHandler

const slots = useSlots()

const modal = useTemplateRef('modal')

const modalId = ref<string>(uuid())

function onOpen() {
  // преодоление бага https://github.com/inkline/inkline/issues/420 пока он не исправлен
  // TODO: удалить когда баг будет починен
  const ocInstance = OverlayController.instances[modalId.value]
  if (ocInstance) {
    if (isRef(ocInstance.closeOnPressEscape)) {
      ocInstance.closeOnPressEscape = ocInstance.closeOnPressEscape.value
    }
  }

  // сами управляем скрытием по Escape
  if (props.hideOnEscape && modal.value) {
    keyboardHandler.subscribe({
      componentId: modalId.value,
      eventType: 'keydown',
      callback: () => {
        show.value = false
        return 'processed'
      },
      keys: ['Escape'],
    })
  }
}

function onClose() {
  keyboardHandler.unsubscribe(modalId.value)
}

onBeforeUnmount(() => {
  keyboardHandler.unsubscribe(modalId.value)
})

</script>

<style>
.ps-modal {
  --modal--wrapper--background: rgba(0, 0, 0, 0.4);

  &.-no-body-padding {
    --modal--body--padding: 0;
  }

  &.-no-footer-padding {
    --modal--footer--padding: 0;
  }
  .modal {
    & > .modal-header {
      font-weight: var(--font-weight-bold);
      --modal--font-size: 1.5rem;
    }
    & > .modal-body {
      max-height: 80vh;
      overflow-y: auto;
    }
  }
}
</style>
