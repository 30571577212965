<template>
  <component
    :is="gridComponent"
    :isFluidLayout="isFluidLayout"
  >
    <template #left>
      <slot name="left" />
    </template>
    <template #main>
      <slot name="main" />
    </template>
    <template #preview>
      <slot name="preview" />
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Horizontal from './panelGrid/Horizontal.vue'
import Vertical from './panelGrid/Vertical.vue'
import type { PsApiV2 } from 'ps-client'


const props = defineProps<{
  gridType: PsApiV2.PublishedPanelMode
  isFluidLayout: boolean
}>()

const gridComponent = computed(() =>
  props.gridType === 1
    ? Vertical
    : Horizontal,
)
</script>
