import type { Color } from '@/ContextTab/components/UI/types'
import type { SizeBreakpointMark } from '@/ContextTab/composables/types'


type NativeButtonType = 'button' | 'submit' | 'reset'

export interface ButtonProps {
  color?: Color
  size?: SizeBreakpointMark
  active?: boolean
  block?: boolean
  circle?: boolean
  disabled?: boolean
  loading?: boolean
  round?: boolean
  border?: boolean
  showLoadingIcon?: boolean
  padding?: boolean
  type?: NativeButtonType
  outline?: boolean
}

export const buttonPropsDefaults: ButtonProps = {
  color: undefined,
  size: 'md',
  active: false,
  block: false,
  circle: false,
  disabled: false,
  loading: false,
  border: true,
  padding: true,
  type: 'button',
  outline: false,
}
