<template>
  <WithInplaceMessage
    :loading="initialLoading"
    :block="false"
  >
    <template v-if="!initialLoading">
      <template v-if="store.data.error">
        <Error :errors="[store.data.error]" />
      </template>
      <template v-else>
        <PsEmpty
          v-if="!initialLoading && presets?.length === 0"
          title="Пресетов нет"
          size="small"
        />
        <template v-else>
          <div class="preset-list">
            <PresetItem
              v-for="preset in presets"
              :key="preset.id"
              :preset="preset"
              :onRename="rename"
              :onDelete="remove"
              @apply="emit('apply', $event)"
            />
          </div>
        </template>
      </template>
    </template>
  </WithInplaceMessage>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue'
import PresetItem from './PresetItem.vue'
import Error from '@/ContextTab/components/Error/Error.vue'
import WithInplaceMessage from '@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue'
import PsEmpty from '@/ContextTab/components/UI/PsEmpty.vue'
import { useStore } from '@/ContextTab/useStore'
import type { PublishedPresetsStore } from '@/ContextApp/stores/news/published/presets'


const emit = defineEmits<{
  (e: 'apply', value: string): void
  (e: 'resetFilters'): void
}>()

const initialLoading = ref(true)
const store = useStore<PublishedPresetsStore>('publishedPresets')

const presets = computed(() => store.data.presets)

const rename = async ({ id, name }: { id: number | string, name: string }) => {
  const result = await store.renamePreset?.(id, name)
  return result?.payload
}

const remove = async (id: number | string) => {
  const result = await store.deletePreset?.(id)
  emit('resetFilters')
  return result?.payload
}

onMounted(async () => {
  await store.ready
  await store.fetchPresets?.()
  initialLoading.value = false
})
</script>

<style scoped>
.empty {
  justify-self: center;
}
.preset-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  height: 100%;
  justify-content: flex-start;
  width: fit-content;
}
</style>
