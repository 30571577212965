<template>
  <IDropdown
    :visible="visible"
    :events="trigger"
    :arrow="showArrow"
    @click:outside="emit('clickOutside')"
  >
    <slot />
    <template #body>
      <slot name="body">
        <IDropdownItem
          v-for="option in visibleOptions"
          :key="option.key"
          :disabled="option.disabled"
          @click="emit('select', option.key)"
        >
          {{ option.label }}
        </IDropdownItem>
      </slot>
    </template>
  </IDropdown>
</template>

<script setup lang="ts">
import { IDropdown, IDropdownItem } from '@inkline/inkline'
import { computed } from 'vue'


type DropdownTrigger = 'click' | 'hover' | 'focus' | 'manual'

export type DropdownOption = {
  key: string
  label: string
  show?: boolean
  disabled?: boolean
}

interface Props {
  trigger?: DropdownTrigger
  visible?: boolean
  options?: DropdownOption[]
  showArrow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  trigger: 'click',
  options: () => [],
  showArrow: false,
})

const emit = defineEmits<{
  (e: 'select', value: DropdownOption['key']): void
  (e: 'clickOutside'): void
}>()

const visibleOptions = computed(() =>
  props.options.filter(
    (option: DropdownOption) => option.show === undefined || option.show,
  ),
)
</script>
