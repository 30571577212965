<template>
  <PsFilterButton
    :tooltip="datesLabel ?? 'Дата и время'"
    :showButtonIcon="['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']"
    :name="datesLabel"
    role="dates"
    :showClear="datesSelected"
    @click="emit('click', $event)"
    @clear="emit('clear')"
  >
    <template #prefix>
      <PbIcon
        name="calendar"
        size="14"
      />
    </template>
    <template #xs />
    <template #sm>
      Дата и время
    </template>
    <template #md>
      {{ datesLabel }}
    </template>
  </PsFilterButton>
</template>

<script setup lang="ts">
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'


defineProps<{
  datesLabel: string
  datesSelected: boolean | undefined
}>()

const emit = defineEmits<{
  (e: 'click', value: MouseEvent): void
  (e: 'clear'): void
}>()
</script>
