export type PublishedFilterConfiguratorType = 'codes' | 'users' | 'feeds' | 'presets'

export type NewsItemPublished = any

export type NewsAction =
  | 'showNewsbreak'
  | 'deleteNews'
  | 'recall'
  | 'revoke'
  | 'changeLang'
  | 'authors'
  | 'editor'
  | 'showHistory'
  | 'showRelations'
  | 'sendToAsoi'
  | 'copyText'
  | 'forbiddenEntities'
  | 'close'

interface NewsActionItem {
  label: string
  key: NewsAction
}

export const newsAction: Record<NewsAction, NewsActionItem> = {
  revoke: {
    label: 'Аннулировать',
    key: 'revoke',
  },
  showHistory: {
    label: 'Посмотреть историю работы',
    key: 'showHistory',
  },
  showRelations: {
    label: 'Посмотреть связанные материалы',
    key: 'showRelations',
  },
  sendToAsoi: {
    label: 'Отправить в АСОИ',
    key: 'sendToAsoi',
  },
  deleteNews: {
    label: 'Удаление',
    key: 'deleteNews',
  },
  showNewsbreak: {
    label: 'Панель инфоповода',
    key: 'showNewsbreak',
  },
  recall: {
    label: 'Отозвать новость',
    key: 'recall',
  },
  changeLang: {
    label: 'Язык',
    key: 'changeLang',
  },
  authors: {
    label: 'Авторы',
    key: 'authors',
  },
  editor: {
    label: 'Редактор',
    key: 'editor',
  },
  copyText: {
    label: 'Копировать текст новости',
    key: 'copyText',
  },
  forbiddenEntities: {
    label: 'Разметить запрещённые сущности',
    key: 'forbiddenEntities',
  },
  close: {
    label: 'Закрытие панели',
    key: 'close',
  },
} as const

/* TODO: это не точный тип, надо проверить нет ли каких-то еще полей и какие поля опциональны */
export interface IUser {
  id: number
  name: string
  image: string
  code?: string
  is_hidden: boolean
  is_mail: boolean
  track: number[]
  pos: number
  isGroup: boolean
  children: number[]
  is_group: boolean
  is_online?: boolean
  last_seen?: string
}

export type EntryType = 'newsItem' | 'newsbreak' | 'asoiNote' | 'thumb'

export interface EntryItem {
  id: number
  type: EntryType
  meta: string
  type_id?: number
  date?: string
  body?: string
  is_revoked?: boolean
  source?: string
}

export interface RelationsEntryItem {
  type: EntryType
  entry: EntryItem
  isCurrent?: boolean
  hasHorizontalLine?: boolean
}
