import { reactive, watch } from 'vue'
import { BasicWsConnection, EBasicWsState } from '@/ContextApp/services/gateway/wsConnection'
import {
  GatewayActionType,
  GatewayInMessageType,
  ActionErrorCode,
} from '@/ContextApp/services/gateway/constants'
import { trueInterval, trueTimeout } from '@/utils/timers'
import type {
  ErrorGatewayInMessage,
  GatewayActionResult,
  SubscriptionGatewayInMessage,
  SubscriptionParams,
  SuccessGatewayInMessage,
  GatewayInMessage,
  AcknowledgeGatewayOutMessage,
  GatewayOutMessageParams,
} from '@/ContextApp/services/gateway/types'


type PendingSentActions = Record<number,
  | PendingSentWaitingActions
  | PendingSentSuccessActions
  | PendingSentErrorActions>

type PendingSentWaitingActions = {
  status: 'waiting'
}

type PendingSentSuccessActions = {
  status: 'success'
  messageData: GatewayInMessage
}

type PendingSentErrorActions = {
  status: 'error'
  messageData: GatewayInMessage
  errorCode: ActionErrorCode
}

export enum EGwState {
  disconnected = 'disconnected', // initial/failed
  connecting = 'connecting', // connecting/reconnecting
  auth = 'auth', // sending auth/refreshing auth
  connected = 'connected', // ws connected & auth sent
}

const E_ACTION_ACK_TIMEOUT = 'Action acknowledge timeout'
const E_WS_NOT_CONNECTED = 'Connection not established while sending'

export interface IGatewayConnectionConfig {
  url: string
  actionTimeout: number
  pingInterval: number
  lostPingsToReconnect: number
  tokenRefreshInterval: number
  onMessage: (message: SubscriptionGatewayInMessage) => void // only subscription messages
  onStatusChange: (status: EGwState, prevStatus: EGwState) => void
  onTokenUpdateRequest: () => Promise<string | null>
  onRequestRecreateSession: () => void
}

const DEFAULT_CONFIG: IGatewayConnectionConfig = Object.freeze({
  url: '',
  actionTimeout: 15000, // 5 sec seems too small to process answer on client side when system hangs
  pingInterval: 5000,
  lostPingsToReconnect: 2,
  tokenRefreshInterval: 12 * 60 * 1000,
  onMessage: () => {},
  onStatusChange: () => {},
  onTokenUpdateRequest: () => Promise.resolve(null),
  onRequestRecreateSession: () => undefined,
})

// it holds SW connection
// it knows about GW protocol and interfaces
// it doesn't know about messageHub and external events
export class GatewayConnection {
  private wsConnection: BasicWsConnection

  private config: IGatewayConnectionConfig = Object.assign({}, DEFAULT_CONFIG)

  private status: EGwState = EGwState.disconnected

  private accessToken: string | null = null

  private actionId = 0

  private pendingSendActions = reactive<PendingSentActions>({})

  private pingsLost: number = 0

  private tokenRefreshTimer: ReturnType<typeof trueTimeout> | null = null

  private isRefreshingToken: boolean = false

  constructor(config: Partial<IGatewayConnectionConfig>) {
    this.updateConfig(config, true)

    this.wsConnection = new BasicWsConnection({
      onMessage: this.onMessage.bind(this),
      onStatusChange: this.onWsStatusChange.bind(this),
      shouldReconnect: this.shouldReconnect.bind(this),
    })

    this.initPingTimer()
  }

  // timer works all the time (to avoid on/off turmoil), but does nothing when WS not connected
  private initPingTimer() {
    trueInterval(async () => {
      if (this.wsConnection.isConnected) {
        const res = await this.sendMessage(GatewayActionType.PING)
        if (!res.result && ('message' in res.error) && res.error.message === E_ACTION_ACK_TIMEOUT) {
          this.pingsLost++
          if (this.pingsLost >= this.config.lostPingsToReconnect) {
            this.pingsLost = 0
            this.wsConnection.reconnect()
          }
        }
      }
    }, this.config.pingInterval)
  }

  private sendAckToMessage(messageData: GatewayInMessage) {
    // 'in' (instead of 'messageData.in') for type narrowing
    if ('id' in messageData) {
      this.sendMessage(GatewayActionType.ACKNOWLEDGE, {
        message_id: messageData.id,
        ...('subscription_key' in messageData
          ? { subscription_key: messageData.subscription_key }
          : {}
        ),
      } satisfies GatewayOutMessageParams<AcknowledgeGatewayOutMessage>)
        .catch((error) => {
          console.error('Failed to send ack on message', error, messageData)
        })
    }
  }

  private updatePendingActions(messageData: GatewayInMessage) {
    if ([
      GatewayInMessageType.ACTION_SUCCESS,
      GatewayInMessageType.ACTION_ERROR,
    ].includes(messageData.type)) {
      messageData = messageData as SuccessGatewayInMessage | ErrorGatewayInMessage

      const ps = this.pendingSendActions[messageData.action_id]
      if (ps) {
        if (messageData.type === GatewayInMessageType.ACTION_SUCCESS) {
          Object.assign(ps, { status: 'success', messageData })
        } else {
          Object.assign(ps, { status: 'error', messageData, errorCode: messageData.code })
        }
      }
    }
  }

  private async requestUpdateToken() {
    if (this.isRefreshingToken) {
      return
    }

    try {
      this.isRefreshingToken = true
      this.setAccessToken(await this.config.onTokenUpdateRequest())
      await this.sendAuth()
    } finally {
      this.isRefreshingToken = false
    }
  }

  private handleTokenExpiredMessage(messageData: GatewayInMessage) {
    if (messageData.type === GatewayInMessageType.ACCESS_TOKEN_EXPIRED) {
      this.setStatus(EGwState.auth)
      this.requestUpdateToken()
      return true
    }
    return false
  }

  private onMessage(messageData: unknown) {
    const inMessage = messageData as GatewayInMessage

    this.updatePendingActions(inMessage)
    this.sendAckToMessage(inMessage)
    if (this.handleTokenExpiredMessage(inMessage)) {
      return
    }

    // other events are service events
    if (inMessage.type === GatewayInMessageType.SUBSCRIPTION) {
      this.config.onMessage(inMessage)
    }
  }

  private shouldReconnect(wsCloseCode: number | undefined = undefined): boolean {
    if (wsCloseCode && wsCloseCode >= 4000) {
      this.config.onRequestRecreateSession()
      return false
    }
    return !!this.accessToken
  }

  private setStatus(status: EGwState) {
    if (status !== this.status) {
      const prev = this.status
      this.status = status
      this.config.onStatusChange(this.status, prev)
    }
  }

  private onWsStatusChange(status: EBasicWsState) {
    if (status === EBasicWsState.disconnected) {
      this.setStatus(EGwState.disconnected)
    } else if (status === EBasicWsState.connecting) {
      this.setStatus(EGwState.connecting)
    } else if (status === EBasicWsState.connected) {
      this.setStatus(EGwState.auth)
    }

    if (status === EBasicWsState.connected) {
      this.sendAuth()
    }

    if (status !== EBasicWsState.connected) {
      this.stopTokenRefresh()
    }
  }

  private async sendAuth(): Promise<boolean> {
    if (!this.accessToken) {
      console.error('Call sendAuth while no token set / empty')
      this.disconnect()
      return false
    }
    const result = await this.sendMessage(
      GatewayActionType.AUTHORIZE,
      { access_token: this.accessToken },
    )
    if (result.result) {
      this.scheduleTokenRefresh()
      this.setStatus(EGwState.connected)
    } else {
      if (('code' in result.error)
        && [
          ActionErrorCode.UNAUTHORIZED,
          ActionErrorCode.INVALID_ACCESS_TOKEN,
          ActionErrorCode.EXPIRED_ACCESS_TOKEN,
        ].includes(result.error.code)) {
        this.setStatus(EGwState.auth)
        await this.requestUpdateToken()
      } else {
        // no code, or code = 'USER_BLOCKED'
        this.wsConnection.disconnect() // cause this.status = disconnect
      }
    }
    return result.result
  }

  private scheduleTokenRefresh() {
    this.stopTokenRefresh()
    this.tokenRefreshTimer = trueTimeout(async () => {
      await this.requestUpdateToken()
    }, this.config.tokenRefreshInterval)
  }

  private stopTokenRefresh() {
    if (this.tokenRefreshTimer?.value) {
      clearTimeout(this.tokenRefreshTimer.value)
      this.tokenRefreshTimer = null
    }
  }

  // public --------------------------------

  // it doesn't reset current connection!
  updateConfig(config: Partial<IGatewayConnectionConfig>, withReset: boolean = false) {
    if (withReset) {
      Object.assign(this.config, DEFAULT_CONFIG, config)
    } else {
      Object.assign(this.config, config)
    }
  }

  setAccessToken(accessToken: string | null) {
    this.accessToken = accessToken
    if (!accessToken) {
      this.disconnect()
    }
  }

  connect() {
    this.wsConnection.updateConfig({
      url: this.config.url,
    })
    this.wsConnection.connect()
  }

  disconnect() {
    this.wsConnection.disconnect()
    this.stopTokenRefresh()
  }

  // ! not trying to send message while not connected (same behaviour as before rewrite)
  sendMessage(
    action: GatewayActionType,
    params: GatewayOutMessageParams = {},
  ): Promise<GatewayActionResult> {
    const actionId = ++this.actionId

    return new Promise((resolve) => {
      if (!this.wsConnection.sendMessage({
        action_id: actionId,
        action_type: action,
        ...params,
      })) {
        resolve({ result: false, error: { message: E_WS_NOT_CONNECTED } })
      }

      this.pendingSendActions[actionId] = { status: 'waiting' }

      const unwatch = watch(
        () => Object.entries(this.pendingSendActions).map(([key, value]) => [key, value.status]),
        () => {
          const ps = this.pendingSendActions[actionId]
          if (ps && ps.status !== 'waiting') {
            if (ps.status === 'success') {
              resolve({ result: true, message: ps.messageData })
            } else {
              resolve({ result: false, error: { code: ps.errorCode } })
            }
            unwatch()
            clearInterval(ackTimeout.value)
            delete this.pendingSendActions[actionId]
          }
        },
      )

      const ackTimeout = trueTimeout(() => {
        resolve({ result: false, error: { message: E_ACTION_ACK_TIMEOUT } })
        unwatch()
        delete this.pendingSendActions[actionId]
      }, this.config.actionTimeout)
    })
  }

  subscribe(
    params: SubscriptionParams,
  ): Promise<GatewayActionResult> {
    return this.sendMessage(GatewayActionType.SUBSCRIBE, params)
  }

  unsubscribe(subscriptionKey: string): Promise<GatewayActionResult> {
    return this.sendMessage(GatewayActionType.UNSUBSCRIBE, {
      subscription_key: subscriptionKey,
    })
  }
}
