<template>
  <span
    class="quadbox"
    :class="{ [state]: true }"
    @click="onClick"
  >
    <PsIcon
      :color="color"
      :name="iconName"
      :size="16"
    />
  </span>
</template>

<script setup lang="ts">
/* TODO: Возможно стоит удалить данный компонент (для консистентности стилей),
 * так как в PsChecbkox есть возможность ставить значение indeterminate
 * https://www.naiveui.com/en-US/dark/components/checkbox#indeterminate.vue */
import { computed } from 'vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { EQuadboxState } from './types'


const props = defineProps<{
  state: EQuadboxState
}>()

const emit = defineEmits<{
  (e: 'change', value: EQuadboxState): void
}>()

const onClick = () => {
  switch (props.state) {
    case EQuadboxState.unchecked:
    case EQuadboxState.partial:
    case EQuadboxState.partialExcluded:
      emit('change', EQuadboxState.included)
      break

    case EQuadboxState.included:
      emit('change', EQuadboxState.excluded)
      break

    case EQuadboxState.excluded:
      emit('change', EQuadboxState.unchecked)
  }
}

const iconName = computed<string>(() => {
  switch (props.state) {
    case EQuadboxState.unchecked:
      return 'unchecked'

    case EQuadboxState.partial:
    case EQuadboxState.partialExcluded:
      return 'partial'

    case EQuadboxState.included:
      return 'checked'

    case EQuadboxState.excluded:
      return 'excluded'
  }
  return ''
})

const color = computed<string>(() => {
  switch (props.state) {
    case EQuadboxState.unchecked:
      return '#c1c1c1'

    case EQuadboxState.partial:
      return '#246cdc'

    case EQuadboxState.partialExcluded:
      return '#EB5757'

    case EQuadboxState.included:
      return '#246cdc'

    case EQuadboxState.excluded:
      return 'white'
  }
  return 'white'
})
</script>

<style scoped>
.quadbox {
  box-sizing: border-box;
  display: inline-block;
  width: 15px;
  height: 15px;
}

.quadbox.unchecked {
  outline: 1px solid #c1c1c1;
  border-radius: 6px;
}
</style>
