import localForage from 'localforage'


export class AppStorage {
  private _storage: any

  constructor() {
    this._storage = localForage.createInstance({
      name: 'publishing',
      storeName: 'publishing',
      description: 'Database to store publishing data',
    })
  }

  getItem(key: string) {
    return this._storage.getItem(key)
  }

  setItem(key: string, value: any) {
    return this._storage.setItem(key, { data: value, lastModified: Date.now() })
  }

  clear() {
    this._storage.clear()
  }
}

export const storage = new AppStorage()
