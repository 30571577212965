<template>
  <PsModal
    v-model:show="show"
    header="Настройка цветов"
    size="sm"
  >
    <PsTabs
      v-model="currTabId"
      :tabs="tabs"
    >
      <div class="tab-content">
        <ColorTree
          :items="filteredItems"
          :type="codesType"
          :colors="codeColors"
          :coloredOnly="coloredOnly"
          @applyColor="applyColorToCode"
        />
      </div>
      <template #suffix>
        <PsCheckbox
          v-model:checked="coloredOnly"
          label="Только отмеченные"
        />
      </template>
    </PsTabs>
    <template #footer>
      <PsSubmitGroup
        submitLabel="Сохранить"
        @submit="applyChanges"
        @cancel="cancel"
      />
    </template>
  </PsModal>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { computed, ref } from 'vue'
import ColorTree from './СolorTree.vue'
import PsSubmitGroup from '@/ContextTab/components/PsSubmitGroup.vue'
import PsCheckbox from '@/ContextTab/components/UI/PsCheckbox.vue'
import PsModal from '@/ContextTab/components/UI/PsModal.vue'
import PsTabs from '@/ContextTab/components/UI/PsTabs.vue'
import { useColorMap } from '@/ContextTab/modules/colors/composables/useColorMap'
import { useFilterCodes } from '@/ContextTab/modules/news/codes/composables/useFilterCodes'
import { useProfile } from '@/ContextTab/modules/user/composables/useProfile'
import type { SetProfileColorData } from '@/ContextTab/modules/colors/types'
import type { CodeFilterTypes } from '@/ContextTab/modules/news/codes/types'


interface TabItem {
  label: string
  id: CodeFilterTypes
}

const props = withDefaults(
  defineProps<{
    modelValue: boolean
  }>(),
  {},
)

const emit = defineEmits<{

  (e: 'update:modelValue', val: (typeof props)['modelValue']): void
}>()

const tabs: TabItem[] = [
  { label: 'Коды', id: 'non-feeds' as const },
  { label: 'Ленты', id: 'feeds' as const },
]

const show = useVModel(props, 'modelValue', emit)
const currTabId = ref(tabs[0].id)
const coloredOnly = ref(false)
const appliedColors = ref({})

const type = computed(() => {
  // TODO: добавить проверку на тип после реализации добавления цветов по автору
  return 'codes' as const
})

const { getColorsByType } = useProfile({
  colorIndicationType: type,
})

const codesType = computed(() => currTabId.value)

const { setColors } = useColorMap()

const { filteredByUid, filtered } = useFilterCodes(codesType)

const items = computed(() => filteredByUid.value)
const itemsById = computed(() => filtered.value)

const filteredItems = computed(() =>
  coloredOnly.value
    ? itemsFilteredByColor.value
    : items.value,
)

const itemsFilteredByColor = computed(() => {
  const filtered: any = {}
  const coloredIds = Object.keys(codeColors.value)
  if (!coloredIds?.length) {
    return filtered
  }
  coloredIds.forEach((id: string) => {
    const item = itemsById.value[id]
    if (!item) {
      return
    }
    filtered[item.uid] = items.value[item.uid]
    if (item.track.length > 0) {
      item.track.forEach((trackedUid: string) => {
        if (!items.value[trackedUid]) {
          return
        }
        filtered[trackedUid] = items.value[trackedUid]
      })
    }
  })

  return filtered
})

const applyColorToCode = ({ code, color, includeChildren }: {
  color: string | null
  code: number
  includeChildren: boolean
}) => {
  appliedColors.value = {
    ...appliedColors.value,
    [code]: { color, includeChildren },
  }
}

const codeColors = computed(() => {
  const colors = { ...getColorsByType.value, ...appliedColors.value }
  for (const color in colors) {
    if (colors[color].color === null) {
      delete colors[color]
    }
  }
  return colors
})

const applyChanges = async () => {
  const items: SetProfileColorData = []
  for (const key in appliedColors.value) {
    const item = appliedColors.value[key]
    const colorHex = item.color
      ? item.color.replace(/^#/g, '')
      : null
    items.push({
      type: type.value,
      id: Number(key),
      color: colorHex,
      includeChildren: item.includeChildren,
    })
  }

  await setColors(items)
  show.value = false
  appliedColors.value = {}
}

const cancel = () => {
  appliedColors.value = {}
  show.value = false
  currTabId.value = tabs[0].id
}
</script>

<style scoped>
.tab-content {
  height: 450px;
}
</style>
