import { cloneDeep } from 'lodash-es'
import {
  commandStatuses,
  messageTypes,
  type PsHubMessage,
  type PsHubMessageMeta,
  type PsHubMessagePayload,
  type PsHubCommand,
  type PsHubCommandMeta,
  type PsHubCommandResult,
  type PsMessageMetaSource,
  type PsHubCommandResultMeta,
  type PsHubCommandType } from './messages'


export type PsHubMessageListenerMetaFilter =
  | ((meta: PsHubMessageMeta) => boolean)
  | undefined
  | null
export type PsHubMessageListenerCallaback = (
  message: PsHubMessagePayload
) => any

export type PsHubCommandListener = {
  metaFilter?: PsHubMessageListenerMetaFilter
  callback: ((command: any) => any) | ((command: any) => Promise<any>)
}
export type PsHubCommandListenerInternal = PsHubCommandListener & {
  uuid: string
}

export type PsHubMessageListener = {
  metaFilter?: PsHubMessageListenerMetaFilter
  callback: PsHubMessageListenerCallaback
}

export type PsHubMessageListenerInternal = PsHubMessageListener & {
  uuid: string
}

const filterByMeta = (
  meta: PsHubMessageMeta,
  filter: PsHubMessageListenerMetaFilter,
) => {
  if (!filter) {
    return true
  }

  if (typeof filter === 'function') {
    return filter(meta)
  }
}

export class PsHubMessageHubHandler {
  private channel: BroadcastChannel
  private commandResultListeners: {
    [key: string]: ((message: PsHubCommandResult) => any)[]
  }

  private commandListeners: { [key: string]: PsHubCommandListenerInternal[] }
  private messageListeners: PsHubMessageListenerInternal[]
  public contextId: string

  constructor({ contextId }: { contextId: string }) {
    this.contextId = contextId
    this.commandListeners = {}
    this.commandResultListeners = {}
    this.messageListeners = []
    this.channel = new BroadcastChannel('ps-message-hub')
    this.channel.addEventListener('message', this._onMessage.bind(this))
  }

  sendMessage(meta: Partial<PsHubMessageMeta>, payload: any): void {
    this.channel.postMessage({
      meta: {
        ...meta,
        contextId: this.contextId,
        typeId:
          meta.typeId ?? messageTypes.byName(messageTypes.names.notification),
      },
      // Proxy in payload breaks sending
      // Solution from Linus Borg: https://github.com/vuejs/core/issues/5303
      payload: cloneDeep(payload ?? null),
    })
  }

  private _onMessage(message: MessageEvent<PsHubMessage<any>>) {
    if (message?.data?.meta?.contextId === this.contextId) {
      return
    }

    // TODO: Убрать после исправления синхронизации авторизации
    if (!message?.data?.meta?.contextId || !message?.data?.meta?.typeId) {
      console.warn('Wrong message format, ignored', message)
      return
    }

    const messageTypeName = messageTypes.byId(message.data.meta.typeId).name
    switch (messageTypeName) {
      case 'command':
        this._onCommand(message.data as PsHubCommand)
        break

      case 'commandResult':
        this._onCommandResult(message.data as PsHubCommandResult)
        break

      default:
        this.messageListeners.forEach((listener) => {
          if (filterByMeta(message.data.meta, listener.metaFilter)) {
            listener.callback(message)
          }
        })
    }
  }

  private _removeCommandResultListener(commandId: string, listener: any): void {
    if (this.commandResultListeners[commandId]?.length) {
      this.commandResultListeners[commandId] = this.commandResultListeners[
        commandId
      ].filter((existed) => existed !== listener)
      if (this.commandResultListeners[commandId].length === 0) {
        delete this.commandResultListeners[commandId]
      }
    }
  }

  private _onCommandResult(message: PsHubCommandResult): void {
    this.commandResultListeners[message.meta.commandId]?.forEach((listener) => {
      listener(message)
    })
  }

  private _onCommand(message: PsHubCommand): void {
    this.commandListeners[message.meta.commandType]?.forEach(async (listener) => {
      let result
      if (!filterByMeta(message.meta, listener.metaFilter)) {
        return
      }

      try {
        result = await listener.callback(message)
        this._sendCommandResult(
          { ...message.meta, statusId: commandStatuses.byName('success').id },
          result,
        )
      } catch (err) {
        this._sendCommandResult(
          { ...message.meta, statusId: commandStatuses.byName('error').id },
          { message: err.message, stack: err.stack },
        )
      }
    })
  }

  addMessageListener(listener: PsHubMessageListener): string {
    const uuid = globalThis.crypto.randomUUID()
    const internalListener: PsHubMessageListenerInternal = {
      ...listener,
      uuid,
    }
    this.messageListeners.push(internalListener)
    return uuid
  }

  removeMessageListener(uuid: string): void {
    this.messageListeners = this.messageListeners.filter(
      (listener) => uuid !== listener.uuid,
    )
  }

  addCommandListener(
    commandType: PsHubCommandType,
    listener: PsHubCommandListener,
  ): string {
    if (!this.commandListeners[commandType]) {
      this.commandListeners[commandType] = []
    }
    const uuid = globalThis.crypto.randomUUID()
    this.commandListeners[commandType].push({ ...listener, uuid })
    return uuid
  }

  removeCommandListener(commandType: PsHubCommandType, uuid: string): void {
    this.commandListeners[commandType].filter(
      (listener) => listener.uuid !== uuid,
    )
  }

  _sendCommandResult(commandMeta: PsHubCommandResultMeta, result: any): void {
    this.sendMessage(
      { ...commandMeta, typeId: messageTypes.byName('commandResult').id },
      result,
    )
  }

  sendCommand(
    meta: Partial<PsHubCommandMeta>,
    payload: any,
  ): Promise<PsHubCommandResult> {
    return new Promise((resolve, reject) => {
      const commandId = globalThis.crypto.randomUUID()
      if (!this.commandResultListeners[commandId]) {
        this.commandResultListeners[commandId] = []
      }

      const listener = (result: any) => {
        this._removeCommandResultListener(commandId, listener)
        if (result.meta.statusId === commandStatuses.byName('success').id) {
          resolve(result)
        } else {
          reject(result)
        }
      }
      this.commandResultListeners[commandId].push(listener)
      this.sendMessage(
        { ...meta, commandId, typeId: messageTypes.byName('command').id },
        payload,
      )
    })
  }

  sendInit(info: string, source: PsMessageMetaSource): void {
    this.sendMessage(
      {
        typeId: messageTypes.byName('service').id,
        source,
        info,
      },
      null,
    )
  }
}
