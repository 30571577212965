<template>
  <TasksPanelListItem
    class="waiting-list-item"
    :item="item"
    :isSelected="isSelected"
    :showReadStatus="true"
    :loading="loading"
    @dblclick="pickHandler"
  >
    <template #before-text>
      <NewsAssignBadge
        class="waiting-list-item__badge"
        :itemId="item.id"
      />
    </template>
    <template #expansion-extra>
      <NewsItemComment
        v-if="item.first_comment"
        class="waiting-list-item__comment"
        :comment="item.first_comment.body"
      />
    </template>
    <template #footer>
      <div
        v-if="isSelected"
        class="waiting-list-item__footer"
      >
        <PsButton
          title="Взять в работу"
          circle
          :border="false"
          :disabled="loading"
          class="action-button"
          @click="pickHandler"
        >
          <PsIcon
            name="button-icon-add"
            :size="24"
          />
        </PsButton>
        <PsButton
          :title="
            isLegacyRoute
              ? 'Открыть в отдельной панели'
              : 'Открыть в новой вкладке'
          "
          circle
          :border="false"
          class="action-button"
          @click="openHandler"
        >
          <PsIcon
            name="button-icon-show-more"
            :size="24"
          />
        </PsButton>
      </div>
    </template>
  </TasksPanelListItem>
</template>

<script setup lang="ts">
import { toRef, watch } from 'vue'
import TasksPanelListItem from '../components/TasksPanelListItem.vue'
import PsButton from '@/ContextTab/components/UI/Button/PsButton.vue'
import PsIcon from '@/ContextTab/components/UI/PsIcon.vue'
import NewsAssignBadge from '@/ContextTab/modules/news/tasks-panel/components/NewsAssignBadge.vue'
import NewsItemComment from '@/ContextTab/modules/news/tasks-panel/components/NewsItemComment.vue'
import { useNewsItem } from '@/ContextTab/modules/news/composables/useNewsItem'


const props = withDefaults(
  defineProps<{
    item: any
    isSelected?: boolean
    isLegacyRoute: boolean
    loading: boolean
  }>(),
  {
    isSelected: false,
  },
)
const emit = defineEmits<{
  (e: 'open', id: number): void
  (e: 'pick', id: number): void
}>()

const { newsItemIsRead, setNewsItemAsRead } = useNewsItem(
  toRef(() => props.item.id),
)

function openHandler() {
  setNewsItemAsRead()
  emit('open', props.item.id)
}

function pickHandler() {
  setNewsItemAsRead()
  emit('pick', props.item.id)
}

watch(
  () => props.isSelected,
  (next) => {
    if (next && !newsItemIsRead.value) {
      setNewsItemAsRead()
    }
  },
)
</script>

<style lang="postcss" scoped>
.waiting-list-item__footer .action-button:not(:disabled, .-disabled):hover {
  --button--color: #bd985c;
}

.waiting-list-item__badge {
  float: left;
}
</style>
