<template>
  <PsFilterButton
    :color="isScreenExtraSmall ? 'default' : null"
    :disabled="!preset || disabled"
    :tooltip="
      preset
        ? `Сохранить фильтры в пресет ${preset.name}`
        : 'Выберите пресет, чтобы обновить'
    "
    size="sm"
    @click="emit('click')"
  >
    <template #prefix>
      <PbIcon
        name="save"
        size="14"
      />
    </template>
    <template #xs>
      Сохранить
    </template>
    <template #sm />
    <template #xl>
      Сохранить
    </template>
  </PsFilterButton>
</template>

<script setup lang="ts">
import PsFilterButton from '@/ContextTab/components/UI/Button/PsFilterButton.vue'
import PbIcon from '@/ContextTab/components/UI/PsIcon.vue'
import { useScreenBreakpoints } from '@/ContextTab/composables/useScreenBreakpoints'


defineProps<{
  preset: any
  disabled: boolean
}>()

const emit = defineEmits<{
  (event: 'click'): void
}>()
const { isScreenExtraSmall } = useScreenBreakpoints()
</script>
