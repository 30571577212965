import { useForm } from '@inkline/inkline'
import { computed, type Ref } from 'vue'
import type { Form, FormSchema } from '@inkline/inkline'

// in fact, it should be ApiError from ps-client, but it defined as 'any'
type ApiErrorDesc = {
  code?: string
  title?: string
  detail?: string
  source?: {
    pointer: string
  }
}

export function useWiredForm<T extends Form>(
  formSchema: FormSchema<T>,
  error: Ref<{ errors?: ApiErrorDesc[] } | null>,
  submitFunc: (...args: any) => Promise<any>,
) {
  const mappedErrors = computed(() => {
    const mapped: Record<string, ApiErrorDesc> = {}

    error.value?.errors?.forEach((error: any) => {
      if (error?.source?.pointer?.length) {
        const field = error.source?.pointer.split('/').at(-1)
        if (field && formSchema[field]) {
          mapped[field] = {
            code: error.code,
            title: error.title,
            detail: error.detail,
          }
        }
      }
    })

    return mapped
  })

  const unmappedErrors = computed(() => {
    return error.value?.errors?.filter((error) =>
      !error?.source?.pointer
      && error.title
      && error.code,
    ) ?? []
  })

  Object.keys(formSchema).forEach((field) => {
    if (formSchema[field]?.validators?.length) {
      const apiValidator = formSchema[field]?.validators?.find(
        (val: any) => val !== 'string' && val.key === 'api',
      )
      if (apiValidator && typeof apiValidator !== 'string') {
        apiValidator.message = () =>
          mappedErrors.value[field]?.detail
          ?? mappedErrors.value[field]?.title
          ?? apiValidator.fallbackMessage
          ?? null

        apiValidator.validator = () => {
          return iForm.schema.value[field].dirty || !mappedErrors.value[field]
        }
      }
    }
  })

  const iForm = useForm<T>(formSchema)

  const submit = async (...args: unknown[]) => {
    const result = await submitFunc(...args)
    await iForm.validate()
    Object.keys(formSchema).forEach((field) => {
      // stupidly leave only 'invalid' state... 💩
      iForm.schema.value[field].dirty = false
      iForm.schema.value[field].pristine = true
    })
    return result
  }

  return {
    form: iForm.form,
    schema: iForm.schema,
    validate: iForm.validate,
    mappedErrors,
    unmappedErrors,
    submit,
  }
}
